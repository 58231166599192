import changeCase from 'change-case';
import merge from 'lodash/merge';
import moment from 'moment';
import URLSearchParams from 'url-search-params';
import {Validator} from 'vee-validate';
import datatable from './components/datatable';

import BaseFieldMixin from './components/forms/fields/mixins/base';
import {DynamicRouteView, ExtraRoutes} from './components/router';
import {dereactivate} from './utils';
import {http} from 'metro-core';

/**
 * @deprecated
 */
export const Support = {
    App: {
        Components: {
            DynamicRouteView
        }
    },
    Form: {
        Field: {
            BaseFieldMixin
        },
        Validator
    },
    REST: {
        Resource: http.Resource
    },
    Components: {
        DataTable: datatable
    },
    Router: {
        ExtraRoutes
    },
    Utils: {
        merge, URLSearchParams, changeCase, dereactivate
    },

    // expose libraries using their names: import {moment} from 'metro-frontend-framework'
    ...{
        moment,
        axios: http.axios,
        httpClient: http.httpClient,
        defaultHttpClient: http.defaultHttpClient,
        dereactivate, changeCase, URLSearchParams,
    }
};
