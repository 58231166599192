<script>
import Btn from '../../../components/buttons/Button';

/**
 * Default action button.
 *
 * If "href" prop is not null in "config", then the button is rendered
 * as a link pointing to the value of "config.href".
 * Otherwise add a "click" listener and executes "config.onClicked" function.
 * "config.onClicked" receives event and a reference to the current button
 * in arguments.
 */
export default {
    props: {
        config: { type: Object, required: true }
    },
    components: {
        Btn
    },
    created() {
        if (this.config.href === null && this.config.onClicked === null) {
            throw Error(
                `Header action button must define value for either "href" or "onClicked" option.
                Seen in button with label "${this.config.label}".`
            );
        }
    },
    methods: {
        onClicked(event) {
            if (this.config.onClicked) {
                this.config.onClicked.bind(this).call(event, this);
            }
        }
    }
};
</script>

<template>
    <btn
        v-bind:href="config.href"
        v-bind:class="config.cssClasses"
        v-bind:label="config.label"
        v-bind:icon="config.icon"
        v-bind:iconset="config.iconset"
        v-on:click="onClicked"
    ></btn>
</template>
