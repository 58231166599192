/**
 * Navigation bar actions service.
 */
class HeaderActions {
    constructor() {
        this.items = [];
    }

    /**
     * Add a navigation bar item.
     * An item format: {component: VueComponent, order?: number}
     * @param item
     */
    add(item) {
        if (!item.hasOwnProperty('component')) {
            item = {
                component: item.component,
                order: item.order || 10
            };
        }

        this.items.push(item);
    }

    /**
     * Add multiple navbar actions at once.
     * Refer add() docs for item format.
     *
     * This method also accepts a list of Vue component:
     *   addMany([ComponentA, ComponentB]);
     * @param items
     */
    addMany(items) {
        items
            .map(item => {
                // when item is a vue component
                if (!item.hasOwnProperty('component')) {
                    return {
                        component: item,
                        order: 10
                    };
                }
                return item;
            })
            .forEach(item => this.add(item));
    }

    /**
     * Returns an ordered list of navigation bar actions.
     * @returns {any[]}
     */
    all() {
        this.items.sort((a, b) => {
            if (a.order < b.order) {
                return 1;
            }

            if (a.order > b.order) {
                return -1;
            }
            return 0;
        });
        return this.items.map(item => item.component);
    }
}

export default new HeaderActions();
