<script>
import FieldMixin from './mixins/base';

/**
 * A checkbox.
 *
 * Can be a part of <checkbox-group />.
 */
export default {
    name: 'CheckboxField',
    mixins: [FieldMixin],
    props: {
        name: { required: false },
        inline: Boolean,
        label: String,
        value: { required: false, default: false }
    },
    data() {
        return {
            isInline: this.inline
        };
    },
    created() {
        this.useFixedLabel = true;
    },
    computed: {
        /**
         * Determines if this input is a part of checkbox group.
         */
        isMulti() {
            if (this.localValue === null) {
                return false;
            }

            // if model is an array, consider it as a multicheckbox
            return (this.localValue.constructor === Array);
        },

        /**
         * Detects text value from "value" attribute.
         */
        attrValue() {
            if ('value' in this.$attrs) {
                return this.$attrs['value'];
            }
            return this.value + '';
        },

        /**
         * CSS classes.
         */
        cssClasses() {
            return {
                'checkbox': !this.isInline,
                'checkbox-field': true,
                'checkbox-inline': this.isInline
            };
        },

        /**
         * Returns checked status of this input.
         */
        isChecked() {
            if (this.isMulti) {
                return (this.localValue.indexOf(this.attrValue) !== -1);
            }
            return !!this.localValue;
        }
    },
    methods: {
        /**
         * Flag that input must be rendered in inline or vertial layout.
         */
        setInline(flag) {
            this.isInline = !!flag;
        },

        /**
         * Disable "input" event handler from mixin.
         * It causes "input" event to be emitted multiple times
         * because of localValue changes too often here.
         */
        onInput() {
            // disable input
        },

        /**
         * "change" event listener. Fired when underlying checkbox changes it's value.
         * Emits "input" event.
         */
        onChanged(e) {
            // if this checkbox is a part of multicheckbox,
            // then we alter the whole model (shared with other checkboxes)
            if (this.isMulti) {
                if (this.isChecked) {
                    this.localValue.splice(
                        this.localValue.indexOf(this.attrValue), 1
                    );
                } else {
                    this.localValue.push(this.attrValue);
                }
            } else {
                this.localValue = e.target.checked;
            }

            this.$emit('input', this.localValue);
        },
        /**
         * Set local value.
         *
         */
        setValue(value) {
            if (value) {
                this.localValue = value;
            } else {
                // an empty array is a default value for multicheckbox
                if (this.isMulti) {
                    this.localValue = [];
                } else {
                    this.localValue = false;
                }
            }
        }
    },
    watch: {
        inline(flag) {
            this.setInline(flag);
        },
        localValue() {
            // watch localValue
        }
    }
};
</script>

<template>
    <div :class="cssClasses" tabindex="-1">
        <label>
            <input
                type="checkbox"
                ref="field"
                :checked="isChecked"
                :disabled="disabled"
                :name="name"
                :placeholder="placeholder"
                :readonly="readonly"
                :required="required"
                :value="value"
                @change="onChanged"
                v-bind="$attrs"
                />
                {{ label }}
        </label>
    </div>
</template>
