<script>
    export default {
        props: {
            object: {
                type: Object,
                default: f => {
                    return { id: null };
                }
            },
            pages: Array
        }
    };
</script>

<template>
    <tabbar v-if="pages.length > 1">
        <require-permissions :all="page.permissions" :key="page.getRouteName()" v-for="page in pages">
            <tabbar-item :route="{name: page.getRouteName(), params: {id: object.id}}" :disabled="page.isDisabled()">
                {{page.title|trans }}
            </tabbar-item>
        </require-permissions>
    </tabbar>
</template>
