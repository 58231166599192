<script>
import BaseMixin from './mixins/base';

export default {
    mixins: [BaseMixin],
    props: {
        accept: String,
        multiple: Boolean,
        preview: { type: Boolean, default: true },
        maxVisibleFiles: { type: Number, default: 2 },
        text: { type: String, default: 'Choose file' }
    },
    data() {
        return {
            files: null,
            hasValue: !!this.value
        };
    },
    created() {
        this.useFixedLabel = true;
    },
    computed: {
        fileNames() {
            if (this.multiple) {
                const diff = this.files.length - this.maxVisibleFiles;
                let suffix = '';
                if (diff > 0) {
                    suffix = this.$t('and %count% more', { count: diff });
                }
                const names = [];
                for (let i = 0; i < this.files.length; i++) {
                    names.push(this.files[i].name);
                }
                return names.slice(0, 2).join(', ') + ' ' + suffix;
            }
            return this.files.name;
        }
    },
    methods: {
        clear() {
            this.hasValue = false;
            this.files = null;
            this.$emit('input', null);
            this.$emit('clear');
        },
        onClicked() {
            this.$refs.field.click();
        },
        onChanged() {
            if (this.multiple) {
                this.files = this.$refs.field.files;
            } else {
                this.files = this.$refs.field.files[0];
            }

            // IE 10 emits an event when we clears input's value, ignore this
            if (!this.files) {
                return;
            }

            this.$emit('input', this.files);
            // reset field value, so user can pick a file again.
            this.$nextTick(f => {
                this.$refs.field.value = null;
            });
            this.hasValue = true;
        }
    },
    watch: {
        value() {
            this.hasValue = !!this.value;
        }
    }
};
</script>

<template>
<div class="file-field">
    <div v-if="files && preview" class="selected-files">
        <span><slot name="text">{{ fileNames }}</slot></span>
    </div>

    <div>
        <div class="clearfix controls">
            <div class="left">
                <btn @click="onClicked" :disabled="disabled" flat>
                    <slot>{{ text|trans }}</slot>
                </btn>
                <btn link @click="clear" v-if="hasValue">{{ 'Clear'|trans }}</btn>
                <slot name="left-controls"></slot>
            </div>
            <div class="right">
                <slot name="controls"></slot>
            </div>
        </div>
    </div>

    <input
        class="file-input"
        ref="field"
        type="file"
        :name="name"
        :multiple="multiple"
        :accept="accept"
        @change="onChanged"
         />
</div>
</template>

<style lang="scss" scoped>
.file-field {
    padding: 0 0 4px 0;
}

.file-input {
    display: none;
}

.selected-files {
    padding: 4px 0;
}

.controls {
    .left {
        float: left;
    }

    .right {
        float: right;
        text-align: right;
    }
}
</style>

