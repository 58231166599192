<script>
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import readFile from '../../utils/files/file-reader';
import { loadImageBlob } from '../forms/fields/image/loader';
import 'blueimp-canvas-to-blob';

const AspectRatio = {
    RATIO_FREE: 0,
    RATIO_SQUARE: 1,
    RATIO_4x3: 4 / 3,
    RATIO_16x9: 16 / 9
};

export default {
    props: {
        src: String,
        original: String,
        file: [File, Blob],
        mime: {type: String, default: 'image/jpeg'},
        fullscreen: { type: Boolean, default: true }
    },
    data() {
        return {
            mode: 'move',
            aspectRatio: AspectRatio.RATIO_SQUARE,
            cropper: null,
            source: this.src,
            cropping: false,
            originalSrc: null
        };
    },
    computed: {
        cssClasses() {
            const list = ['editor'];
            if (this.fullscreen) {
                list.push('fullscreen');
            }
            return list;
        },
        aspectRatios() {
            return AspectRatio;
        }
    },
    async created() {
        // if original image is set, preload it for faster access
        if (this.original) {
            this.originalSrc = await readFile(
                await loadImageBlob(this.original)
            );
        }
    },
    mounted() {
        this.init();
        this.bindHotkeys();
    },
    destroyed() {
        this.cropper.destroy();
    },
    methods: {
        init() {
            this.cropper = new Cropper(this.$refs.image, {
                dragMode: this.mode,
                viewMode: 1,
                autoCrop: false,
                background: false,
                aspectRatio: this.aspectRatio,
                ready(a) {
                    // ready scope
                }
            });
        },
        setDragMode(mode) {
            this.clear();
            this.cropper.setDragMode(mode);
            this.mode = mode;

            if (mode === 'crop') {
                this.cropper.crop();
            }
        },
        setRatio(ratio) {
            this.aspectRatio = ratio;
            this.cropper.setAspectRatio(ratio);
        },

        /**
         * Apply changes.
         */
        commit() {
            this.cropper.getCroppedCanvas().toBlob(this.done, this.mime);
        },

        /**
         * Finish editing.
         */
        done(blob) {
            this.$emit('edit', blob);
            this.exit();
        },
        clear() {
            this.cropper.clear();
            this.mode = 'move';
        },
        restore() {
            this.clear();
            this.cropper.replace(this.source);
        },
        rotate(dir) {
            this.cropper.clear();
            const angle = dir === 'left' ? -90 : 90;
            this.cropper.rotate(angle);
        },

        flip(dir) {
            this.cropper.clear();
            if (dir === 'horizontal') {
                this.cropper.scaleX(-this.cropper.getData().scaleX || -1);
            } else {
                this.cropper.scaleY(-this.cropper.getData().scaleY || -1);
            }
        },

        /**
         * Do image crop.
         */
        crop() {
            const canvas = this.cropper.getCroppedCanvas();
            this.cropper.replace(canvas.toDataURL(this.file.type));
        },

        bindHotkeys() {
            window.addEventListener('keydown', e => {
                switch (e.key) {
                    case 'Escape':
                        this.onEscapePressed();
                        break;
                    case 'Enter':
                        this.crop();
                        break;
                }
            });
        },

        /**
         * Crop image on double click.
         */
        onDoubleClicked() {
            if (this.mode === 'crop') {
                this.crop();
            }
        },

        /**
         * Reset cropping when ESC pressed.
         */
        onEscapePressed() {
            this.clear();
        },

        /**
         * Close editor.
         */
        exit() {
            this.$emit('exit');
        },

        /**
         * Restore image from original.
         */
        restoreFromOriginal() {
            // try first preloaded image, then load by URL
            this.clear();
            this.cropper.replace(this.originalSrc || this.original);
        }
    }
};
</script>

<template>
<div :class="cssClasses">
    <div class="header-actions">
        <btn-icon
            @click="commit"
            icon="check"
            v-tooltip.bottom="$t('Apply and exit')"
        />
        <btn-icon
            v-if="original"
            @click="restoreFromOriginal"
            icon="refresh"
            v-tooltip.bottom="$t('Restore from original')" />
        <btn-icon
            @click="exit" icon="clear"
            v-tooltip.bottom="$t('Exit')"
        />
    </div>
    <div class="canvas" @on:keydown.esc="clear" @dblclick="onDoubleClicked">
        <img :src="src" ref="image" crossorigin="anonymous" />
    </div>
    <div class="toolbar">
        <div class="btn-group">
            <btn-icon :class="{ active: mode == 'move' }" @click="setDragMode('move')" icon="open-with" v-tooltip="$t('Move')" />
            <btn-icon :class="{ active: mode == 'crop' }" @click="setDragMode('crop')" icon="crop" v-tooltip="$t('Crop')" />
            <btn-icon @click="rotate('right')" icon="redo" v-tooltip="$t('Rotate right')" />
            <btn-icon @click="rotate('left')" icon="undo" v-tooltip="$t('Rotate left')" />
            <btn-icon @click="flip('vertical')" icon="swap-vert" v-tooltip="$t('Flip vertical')" />
            <btn-icon @click="flip('horizontal')" icon="swap-horiz" v-tooltip="$t('Flip horizontal')" />
            <btn-icon @click="restore" icon="refresh" v-tooltip="$t('Restore')" />
        </div>

        <div class="btn-group" v-if="mode === 'crop'" style="margin-left: 16px">
            <btn-icon
                icon="crop-square"
                :class="{ active: aspectRatio == aspectRatios.RATIO_SQUARE }"
                @click="setRatio(aspectRatios.RATIO_SQUARE)"
                v-tooltip="'1:1'" />

            <btn-icon
                icon="crop-5-4"
                :class="{ active: aspectRatio == aspectRatios.RATIO_4x3 }"
                @click="setRatio(aspectRatios.RATIO_4x3)"
                v-tooltip="'4:3'" />

            <btn-icon
                icon="crop-16-9"
                :class="{ active: aspectRatio == aspectRatios.RATIO_16x9 }"
                @click="setRatio(aspectRatios.RATIO_16x9)"
                v-tooltip="'16:9'" />

            <btn-icon
                icon="crop-free"
                :class="{ active: aspectRatio == aspectRatios.RATIO_FREE }"
                @click="setRatio(aspectRatios.RATIO_FREE)"
                v-tooltip="$t('Free')" />
        </div>
    </div>
</div>
</template>

<style scoped lang="scss">
.editor {
    display: flex;
    flex-direction: column;

    &.fullscreen {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 2500;

        .toolbar {
            position: fixed;
            bottom: 24px;
        }
    }

    .header-actions {
        background: #666666;
        flex: 1;
        height: 60px;
        flex-grow: 0;
        padding: 8px 24px;
        text-align: right;

        .btn {
            color: white;
        }
    }

    .canvas {
        align-items: center;
        flex: 1;
        height: 100%;
        justify-content: center;
        background: #333333;

        & > img {
            max-height: 100%;
            max-width: 100%;
            width: auto;
            height: auto;
        }
    }

    .toolbar {
        position: absolute;
        height: 40px;
        text-align: center;
        bottom: 48px;
        left: 0;
        right: 0;

        .btn {
            background: rgba(0, 0, 0, .3);
            color: white;
            border-radius: 0;

            &.active {
                background: #F44336;
            }

            &:hover {
                background: #F44336;
            }
        }
    }
}
</style>
