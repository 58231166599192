export const FieldPermissionPolicy = {
    HIDE: 'hide', DISABLE: 'disable'
};
export const FieldType = { FIELD: 'field', TRANSLATIONS: 'translations' };
/**
 * Describes a field.
 */
export const fieldMapping = {
    name: null, // field name
    type: 'field', // field or group (translations), see FieldType object.
    label: '[no label]', // field label
    translatable: false, // translate value or not
    sortable: false, // clean enough
    default: null, // default value if row value is empty
    component: null, // a vue component, replaces default on list page table when provided
    help: '', // help text for field
    children: [], // if type=group, this contains a list of fieldMapping
    formatters: [], // functions to format the output: (value, {vm: Component, column: fieldMapping, row: object}) => string
    displayFormat: null, // format cell value according to that value
    primaryKey: false, // renders a link to edit page when true

    // not implemented
    field: 'text-field', // input widget
    validations: [], // validation rules, see "vee-validate" package
    fieldComponent: null, // edit page, use this input widget component instead of default
    editPermissions: [], // a set of permissions to check if user can interact with the field
    editPermissionPolicy: FieldPermissionPolicy.DISABLE, // when permission check is negative, this defines the visibility of the field
};
