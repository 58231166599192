/**
 * A Vue plugin. Passes ServiceManager to every component.
 */
import manager from '../services/manager';

export default {
    install(Vue) {
        Vue.prototype.$services = manager;
    }
};
