<script>
/**
 * The DataTable™ component.
 */

import { columnManager } from './columns';
import TableHeader from './Header';
import PageSizeSelect from '../../components/table/PageSizeSelect';
import TableRow from './Row';
import { createAdapter } from './adapters.js';
import mixins from './mixins';

const DEFAULT_TABLE_OPTIONS = {
    searchable: false,
    trackBy: 'id',
    reloadInterval: null,
    rowOptions: {
        cssClass: null,
        styles: null
    },
    adapter: {
        type: 'array',
        options: {
            idField: 'id'
        }
    }
};

export default {
    mixins: mixins,
    props: {
        options: {
            type: Object,
            default() {
                return {};
            }
        },
        columns: {
            type: Array,
            required: true
        },
        models: {
            type: Array,
            required: false,
            default() {
                return [];
            }
        }
    },
    provide() {
        return {
            eventBus: this.eventBus,
            dataTable: this
        };
    },
    data() {
        return {
            cols: this.mapColumns(this.columns),
            opts: Object.assign({}, DEFAULT_TABLE_OPTIONS, this.options),
            rows: this.models,
            adapter: null,
            reloadInterval: null
        };
    },
    components: {
        TableHeader,
        TableRow,
        PageSizeSelect
    },
    created() {
        // construct backend adapter
        this.adapter = createAdapter(this.opts.adapter, {
            current: this.paginator.current,
            pageSize: this.paginator.pageSize
        }, this.models);
    },
    mounted() {
        // load data
        this.load(this.paginator.current);
        if (this.opts.reloadInterval) {
            this.reloadInterval = setInterval(f => {
                this.reload();
                this.$emit('autoreloaded');
            }, this.opts.reloadInterval);
        }
    },
    destroyed() {
        clearInterval(this.reloadInterval);
    },
    deactivated() {
        clearInterval(this.reloadInterval);
    },
    computed: {
        eventBus() {
            return new Vue();
        },
        trackBy() {
            if ('trackBy' in this.options) {
                return this.options.trackBy;
            }
            if (this.cols.length > 0) {
                return this.cols[0].id;
            }
        },
        self() {
            return this;
        }
    },
    methods: {
        async load(page) {
            this.paginator.current = page;
            let resp = await this.$refs.overlay.wrap(
                this.adapter.list(
                    page, this.sorting, this.searchQuery,
                    this.searchableCols
                )
            );

            if (resp.success) {
                this.rows = resp.data.items;
                this.paginator.total = resp.data.total;
                return this.rows;
            } else {
                console.error(resp.error);
            }
        },
        async reload() {
            return this.load(this.paginator.current);
        },
        mapColumns(columns) {
            return columnManager.map(this.columns);
        }
    },
    watch: {
        'paginator.pageSize'(newval) {
            this.adapter.updatePaginationOptions({
                pageSize: newval
            });
            this.load(1);
        },
        columns(newval) {
            this.cols = [];
            this.cols = this.mapColumns(newval);
        }
    }
};
</script>

<template>
    <div v-cloak>
        <div class="clearfix table-top">
            <slot name="top">
                <div class="col-md-6">
                    <slot name="top-left">
                        <search-form v-on:submit="onSearchSubmit" v-if="searchable"></search-form>
                    </slot>
                </div>
                <div class="col-md-6">
                    <slot name="top-right">
                        <header-actions :actions="headerActionsList">
                            <slot name="header-actions"></slot>
                        </header-actions>
                    </slot>
                </div>
            </slot>
        </div>
        <overlay-loader ref="overlay">
            <table ref="table" v-bind:class="cssClasses" v-bind:styles="inlineStyles">
                <table-header v-bind:cols="cols"></table-header>

                <tbody v-if="rows.length > 0">
                    <table-row
                        v-for="row in rows"
                        v-bind:row="row"
                        v-bind:tableOptions="opts"
                        v-bind:cols="cols"
                        v-bind:key="row[trackBy]">
                    </table-row>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td v-bind:colspan="cols.length" class="text-center">
                            {{ 'No data'|trans }}
                        </td>
                    </tr>
                </tbody>

            </table>
        </overlay-loader>
        <div class="clearfix">
            <slot name="bottom">
                <div class="col-md-6">
                    <slot name="bottom-left">
                        <page-size-select
                            v-model="paginator.pageSize"
                            ></page-size-select>
                    </slot>
                </div>
                <div class="col-md-6">
                    <slot name="bottom-right">
                        <pagination
                            ref="pagination"
                            v-bind:total="paginator.total"
                            v-bind:current="paginator.current"
                            v-bind:size="paginator.pageSize"
                            v-bind:visible="paginator.visible"
                            v-on:page-selected="onPageSelected"
                        ></pagination>
                    </slot>
                </div>
            </slot>
        </div>
    </div>
</template>

<style lang="scss" scoped>
[v-cloak] {
    display: none;
}

.hover {
    tbody {
        tr {
            &:hover {
                background: rgba(121, 121, 121, 0.17)!important;
            }
        }
    }
}

.stripped {
    tbody {
        tr {
            &:nth-child(even) {
                background: rgba(121, 121, 121, 0.03);
            }
        }
    }
}
</style>
