<script>
    export default {
        name: 'ListMenuDivider',
    };
</script>

<template>
    <li tabindex="-1" class="list-menu-divider mt-2 pb-2 border-gray-200 border-t">
    </li>
</template>
