<script>
    /**
     * Language switcher.
     * Sends POST request with CSRF token, a language selected and redirect URL to the backend handler.
     * In future, can send AJAX call to the backend to switch the language, commit new language to store
     * and redraw page using new language without page refresh (and unsaved data loss).
     *
     * Usage:
     * <language-switcher :url="" />
     */
    import Icon from './Icon';
    import AppBarButton from './AppBarButton';

    export default {
        components: {AppBarButton, Icon},
        props: {
            url: {type: String, default: '/admin/trans/switch-language/'},
        },
        data() {
            return {
                lang: 'de'
            };
        },
        filters: {
            uppercase(value) {
                return value.toUpperCase();
            }
        },
        computed: {
            available() {
                return this.$store.state.trans.languages.enabled;
            },
            current() {
                return this.$store.state.trans.languages.current;
            },
            currentLocation() {
                return window.location.href;
            }
        },
        created() {
            this.lang = this.$store.state.trans.languages.current;
        },
        methods: {
            switchLanguage(lang) {
                this.lang = lang;
                this.$nextTick(_ => {
                    this.$refs.form.submit();
                });
            }
        }
    };
</script>

<template>
    <app-bar-button>
        <strong class="mr-1">
            {{current|uppercase}}
        </strong>

        <div class="dropdown-menu" slot="content">
            <a
                class="dropdown-item"
                href="#" @click.prevent="switchLanguage(target)"
                :class="{active: target === lang}"
                v-for="target in available"
                :key="target"
            >
                {{ target|uppercase }}
            </a>
        </div>

        <form :action="url" method="post" ref="form" class="hide">
            <input type="hidden" name="language" :value="lang"/>
            <input type="hidden" name="next" :value="currentLocation"/>
            <csrf-token-field></csrf-token-field>
        </form>

    </app-bar-button>
</template>
