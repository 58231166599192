<script>
export default {
    props: {
        src: String
    }
};
</script>

<template>
    <div class="card-image">
        <slot name="content">
            <slot name="image">
                <img crossorigin="anonymous" :src="src" />
            </slot>
            <slot></slot>
        </slot>
    </div>
</template>
