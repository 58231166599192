<script>
    export default {
        inject: ['table']
    };
</script>

<template>
    <tr class="table-empty-state">
        <td :colspan="table.cells.length">
            <slot></slot>
        </td>
    </tr>
</template>

<style lang="scss" scoped>
    .table-empty-state {
        text-align: center;

        td {
            border: 0!important;
        }
    }
</style>
