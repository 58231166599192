<script>
    export default {
        mounted() {
            const onClick = (e) => {
                this.$emit('item-click', e);
            };
            let nodes = this.$el.querySelectorAll('li');
            nodes.forEach(node => {
                node.addEventListener('click', onClick);

                this.$once('hooks:beforeDestroy', () => {
                    node.removeEventListener('click', onClick);
                });
            });
        }
    };
</script>

<template>
    <ul class="list-menu block focus:bg-red-100" tabindex="-1">
        <slot></slot>
    </ul>
</template>
