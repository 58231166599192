<script>
    /**
     * Provides an "earth" icon available in the navigation bar.
     */
    import {notifResource} from '../services/notifications';
    import AppBarButton from './AppBarButton';
    import Icon from './Icon';

    export default {
        components: {AppBarButton, Icon},
        data() {
            return {
                unreadCount: 0,
                loading: false,
                items: []
            };
        },
        created() {
            this.load();
            this.loadUnread();
            this.poll();
        },
        computed: {
            pollInterval() {
                return this.$store.state.messages.pollInterval;
            }
        },

        methods: {
            async load() {
                try {
                    const response = await notifResource.all();
                    this.items = response.data.items;
                } catch(e) {
                    console.error(e);
                }
            },
            async loadUnread() {
                try {
                    const response = await notifResource.getUnread();
                    this.unreadCount = response.data.count;
                } catch(e) {
                    console.error(e);
                }
            },
            poll() {
                setInterval(async f => {
                    this.loadUnread();
                }, this.pollInterval);
            },
            async onItemClicked(item) {
                if(!item.isRead) {
                    await notifResource.markAsRead(item.id);
                }

                this.items.map((listItem) => {
                    if(listItem.id === item.id) {
                        listItem.isRead = true;
                    }
                });
                this.loadUnread();
            }
        },
        watch: {
            unreadCount(newval, oldval) {
                this.load();
            }
        }
    };
</script>

<template>
    <div class="nav-link text-gray-900 dropdown">
        <popover ref="popover" class="notifications">
            <button trigger ref="button">
                <div class="counter" v-if="unreadCount">{{ unreadCount }}</div>
                <icon class="text-gray-800" name="notification" size="xl"></icon>
            </button>

            <popover-content>
                <div class="popover-inner font-regular">
                    <div class="list-group scrollbar py-2">
                        <div class="list-group-item px-2 mb-2" v-for="item in items" :key="item.id"
                             :class="{'unread': !item.isRead}" @click="onItemClicked(item)">
                            <component :is="item.link ? 'a' : 'span'" :href='item.link' target="_blank">
                                <h4 class="list-group-item-heading">{{ item.title }}</h4>
                                <p class="list-group-item-text">{{ item.text }}</p>
                            </component>
                        </div>
                    </div>
                    <div class="see-all pt-2">
                        <a href="/admin/notifications">
                            {{'See all'|trans }}
                        </a>
                    </div>
                </div>

                <div class="hide" ref="popover-loader">
                    <div class="popover-loader">
                        <spinner size="50px"></spinner>
                    </div>
                </div>

            </popover-content>
        </popover>
    </div>
</template>

<style lang="scss" scoped>
    .counter {
        position: absolute;
        background: #f44237;
        color: white;
        height: 14px;
        width: 14px;
        font-size: 10px;
        display: inline-block;
        border-radius: 999999px;
        top: 4px;
        right: 4px;
        /*padding: 0 5px;*/
        z-index: 100;
    }

    .popover-loader {
        padding: 20px;
        text-align: center;
    }

    .see-all {
        text-align: center;
        font-weight: bolder;

        a {
            color: #666;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .popover-inner {
        > .list-group {
            max-height: 400px;
            overflow-y: auto;
        }

        .list-group-item {
            border-left: 0;
            border-right: 0;
            cursor: pointer;

            &.link {
                cursor: pointer;

                &:hover {
                    background: darken(#FFFFFF, 1%);
                }
            }

            &:first-child {
                border-top: 0;
            }

            &:last-child {
                border-bottom: 0;
            }
        }
    }

    .list-group-item:not(.unread), .list-group-item:not(.unread) a {
        @apply text-gray-900;
    }

    .unread, .unread a {
        @apply text-orange-600;
    }
</style>

<style lang="scss">
    .notifications {
        height: 40px;
        .popover {
            width: 400px;
            max-width: 400px;
        }
    }

    .popover-content {
        padding: 0 0 5px 0;
    }
</style>
