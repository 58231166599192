export default {
    name: 'BaseInputField',
    useFixedLabel: false,
    props: {
        name: { type: String, required: false },
        required: Boolean,
        readonly: Boolean,
        disabled: Boolean,
        placeholder: String,
        alias: String,
        rules: String,
        value: {}
    },
    data() {
        return {
            isFocused: false,
            localValue: this.value
        };
    },
    beforeCreate() {
        this.useFixedLabel = false;
        this.isInput = true;
    },
    methods: {
        /**
         * Set current field value.
         *
         * @param {any} value
         */
        setValue(value) {
            this.localValue = value;
            this.$emit('input', this.localValue);
        },

        /**
         * Emitted when field is focused.
         *
         * @param {Event} e
         */
        onFocus(e) {
            this.isFocused = true;
            this.$emit('focus', e);
        },

        /**
         * Emitted when field is blured.
         *
         * @param {Event} e
         */
        onBlur(e) {
            this.isFocused = false;
            this.$emit('blur', e);
        },

        /**
         * Emitted when field's value is changed.
         *
         * @param {Event} e
         */
        onInput(e) {
            if (e) {
                this.localValue = e.target.value;
            }
            this.$emit('input', this.localValue);
        },

        /**
         * Emitted when field's value is changed.
         */
        onChange() {
            this.$emit('change', this.localValue);
        },

        getValue() {
            return this.value;
        }
    },
    watch: {
        /**
         * Watch foreign changes of value.
         *
         * @param {any} newval
         * @param {any} oldval
         */
        value(newval, oldval) {
            if (newval !== oldval) {
                this.localValue = newval;
                this.$emit('value-change', newval);
            }
        }
    }
};
