<script>
    import CellValue from './CellValue';
    import CellMixin from './mixins';

    export default {
        components: {CellValue},
        name: 'StringCell',
        mixins: [CellMixin]
    };
</script>
<template>
    <td>
        <cell-value :row="row" :field="field" :editable="editable" @edit="onEdited" v-bind="$attrs">
            <slot></slot>
        </cell-value>
    </td>
</template>
