<script>
export default {

};
</script>

<template>
    <div class="card-content">
        <p>
            <slot></slot>
        </p>
    </div>
</template>
