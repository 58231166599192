<script>
    /**
     * Renders a popover title.
     *
     * A part of <popover> component.
     */
    export default {};
</script>

<template>
    <h3 class="popover-title">
        <slot></slot>
    </h3>
</template>


