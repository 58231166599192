import PropertyResolver from '../../../mixins/resolvable-property';
import IconPropProvider from '../../../mixins/icon-props';

/**
 * Common props and methods for buttons.
 */
export default {
    mixins: [PropertyResolver, IconPropProvider],
    props: {
        label: { type: [String, Function], default: '' },
        icon: { type: [String, Function], default: '' },
        flat: Boolean,
        outline: Boolean,
        large: Boolean,
        small: Boolean,
        mini: Boolean,
        round: Boolean,
        link: Boolean,

        // styles
        noStyle: Boolean,
        primary: Boolean,
        danger: Boolean,
        warning: Boolean,
        success: Boolean,
        info: Boolean,

        // types
        submit: Boolean
    },
    data() {
        return {
            iconValue: this.resolvePropValue('icon'),
            labelValue: this.resolvePropValue('label')
        };
    },
    computed: {
        cssClasses() {
            return {
                'btn': true, // always,
                'btn-default': !(this.primary || this.danger || this.warning || this.success || this.info),
                'btn-flat': this.flat,
                'btn-border': this.outline,
                'btn-lg': this.large,
                'btn-sm': this.small,
                'btn-xs': this.mini,
                'btn-round': this.round,
                'btn-primary': this.primary,
                'btn-danger': this.danger,
                'btn-warning': this.warning,
                'btn-success': this.success,
                'btn-link': this.link,
                'btn-info': this.info
            };
        },
        type() {
            return this.submit ? 'submit' : 'button';
        }

    },
    methods: {
        onClicked(e) {
            this.$emit('click', e);
        }
    },
    watch: {
        icon(value) {
            this.iconValue = this.resolvePropValue('icon');
        },
        label(value) {
            this.labelValue = this.resolvePropValue('label');
        }
    }
};
