<script>
    import BaseMixin from './mixins/base';

    /* global CKEDITOR */

    const DEFAULT_TOOLBAR = `
        Bold, Italic, Underline, Strike, -, Link, Unlink, -,  NumberedList, BulletedList, -, Image, -,
        Format, Styles, Indent, Outdent, JustifyLeft, JustifyCenter, JustifyRight, JustifyBlock, -, Undo, Redo, -,
        PasteFromWord, Maximize, Source, Preview
    `;

    export default {
        name: 'RichTextField',
        mixins: [BaseMixin],
        props: {
            toolbar: {
                type: String,
                default() {
                    return DEFAULT_TOOLBAR;
                }
            },
            contentsCss: String,
            config: {
                type: Object, default: f => {return {};}
            }
        },
        data() {
            return {
                ready: false,
                editor: null
            };
        },
        created() {
            this.useFixedLabel = true;
        },
        mounted() {
            this.initEditor();
        },
        deactivate() {
            this.editor.focusManager.blur(true);
            this.editor.removeAllListeners();
            this.editor.destroy();
        },
        computed: {
            toolbarButtons() {
                return this.toolbar.split(',').map(b => b.trim());
            },
            mergedConfig() {
                return Object.assign({}, {
                    // allowedContent: true,
                    readonly: this.readonly,
                    contentsCss: this.contentsCss,
                    toolbar: [
                        {name: 'default', items: this.toolbarButtons}
                    ],
                    extraPlugins: 'colorbutton,font,justify,print,pastefromword,liststyle',
                    extraAllowedContent: 'img;table;tr;td{};thead;tbody;tfoot;th',
                    disallowedContent: 'p{text-align}'
                }, this.config);
            }
        },
        methods: {
            initEditor() {
                this.editor = CKEDITOR.replace(this.$refs.field, this.mergedConfig);

                this.editor.setData(this.value);
                this.editor.on('instanceReady', () => {
                    this.editor.setData(this.value);
                });
                this.editor.on('change', this.onChange);
                this.editor.on('blur', this.onBlur);
                this.editor.on('focus', this.onFocus);

                this.ready = true;
                this.$emit('ready', true);
            },
            onChange() {
                this.$emit('input', this.editor.getData());
            }
        },
        watch: {
            readonly(flag) {
                this.editor.setReadOnly(flag);
            },
            disabled(flag) {
                this.editor.setReadOnly(flag);
            },
            value(newval, oldval) {
                if (this.editor.getData() !== newval) {
                    this.editor.setData(newval || '');
                }
            }
        }
    };
</script>

<template>
    <div class="rich-text-field">
        <textarea
                class="form-control"
                ref="field"
                :disabled="disabled"
                :name="name"
                :placeholder="placeholder"
                :readonly="readonly"
                :required="required"
                :value="value"
        ></textarea>
    </div>
</template>
