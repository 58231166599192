import { httpClient } from './client';
import { objectKeysToSnake } from './utils';

export class Resource {
    constructor(endpoint, model = null) {
        this.endpoint = endpoint.replace(/\/$/, '');
        this.model = model;
        this.client = httpClient;
    }

    /**
     * Retrieve an entity by id.
     *
     * @param {number} id
     */
    async get(id, ...args) {
        return this.callAndMap(this.client.get(this.buildURL(id), ...args));
    }

    /**
     * Retrieve all entities.
     */
    async all(...args) {
        return this.callAndMap(this.client.get(this.buildURL(), ...args));
    }

    /**
     * Create a new entity.
     *
     * @param {Object} data
     */
    async create(data, ...args) {
        return this.callAndMap(this.client.post(this.buildURL(), data, ...args));
    }

    /**
     * Update entity by id.
     *
     * @param {number} id
     * @param {Object} data
     */
    async update(id, data, ...args) {
        return this.callAndMap(this.client.put(this.buildURL(id), data, ...args));
    }

    /**
     * Partially update entity by id.
     *
     * @param {number} id
     * @param {Object} data
     */
    async partialUpdate(id, data, ...args) {
        return this.callAndMap(this.client.patch(this.buildURL(id), data, ...args));
    }

    /**
     * Remove entity.
     *
     * @param {number} id
     */
    async delete(id, ...args) {
        return this.callAndMap(this.client.delete(this.buildURL(id), ...args));
    }

    /**
     * Create or update entity.
     * If id is set, then update otherwise - create.
     *
     * @param {Object} data
     * @param {number?} id
     */
    async createOrUpdate(data, id, ...args) {
        if (id) {
            return this.update(id, data, ...args);
        }
        return this.create(data, ...args);
    }

    /**
     * Create or pritially (vie PATCH) update entity.
     * If id is set, then update otherwise - create.
     *
     * @param {Object} data
     * @param {number?} id
     */
    async createOrPartialUpdate(data, id, ...args) {
        if (id) {
            return this.partialUpdate(id, data, ...args);
        }
        return this.create(data, ...args);
    }

    /**
     * Converts params object into FileData instance.
     */
    toFileData(params) {
        params = objectKeysToSnake(params);
        const data = new FormData();
        for (const key in params) {
            let value = params[key];
            if (value === null) {
                value = '';
            }

            if (value.constructor === Array && value.length === 0) {
                value = '';
            }

            data.append(key, value);
        }
        return data;
    }

    /**
     * Assemble URL.
     *
     * @param {number?} id
     */
    buildURL(...args) {
        let parts = [this.endpoint, ...args].filter(p => p !== null);
        return this._stripSlash(parts.join('/')) + '/';
    }

    _mapModel(model, data) {
        for (const key in data) {
            if (model.hasOwnProperty(key)) {
                model[key] = data[key];
            }
        }
        return model;
    }

    _stripSlash(string) {
        return string.replace(/\/$/, '');
    }

    /**
     * Call endpoint and map response to models.
     *
     * @param {Object} result - A data from the API
     * @param {boolean} many
     */
    async callAndMap(promise, many = false) {
        const response = await promise;
        response.models = f => {
            const models = [];
            if (many) {
                for (const row of response.data.items) {
                    // eslint-disable-next-line
                    models.push(this._mapModel(new this.model(), row));
                }
                return models;
            } else {
                // eslint-disable-next-line
                return this._mapModel(new this.model(), response.data);
            }
        };
        return response;
    }
}
