import InputMask from 'inputmask';

/**
 * Input mask directive.
 *
 * Formats input value as user type to a defined format.
 *
 * Example:
 *  <text-field name="phone" v-mask="(99) 999-99-99" />
 *
 * @link https://github.com/RobinHerbots/Inputmask
 */
export default {
    inserted(el, binding) {
        new InputMask(binding.value, { showMaskOnHover: false }).mask(el);
    }
};
