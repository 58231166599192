<script>
    import Icon from '../Icon';

    export default {
        name: 'ListMenuItem',
        components: { Icon },
        props: {
            active: Boolean,
        },
        methods: {
            click(e) {
                this.$emit('click', e);
            }
        }
    };
</script>

<template>
    <li tabindex="-1" class="list-menu-item" :class="{active}" @click="click">
        <div class="flex-grow">
            <slot></slot>
        </div>
        <icon name="check" class="ml-4 opacity-0 w-6 flex-shrink-0" :class="{
            'opacity-100': active,
        }"></icon>
    </li>
</template>

<style scoped>
    .list-menu-item {
        @apply px-3 py-2 bg-white text-sm font-regular cursor-pointer flex items-center select-none text-black;
    }

    .list-menu-item a, .list-menu-item a:hover {
        @apply text-black;
    }

    .list-menu-item:hover {
        @apply bg-gray-100 text-black;
    }

    .list-menu-item.active {
        /*@apply bg-blue-500 text-white;*/
    }

    .list-menu-item.focus {
        @apply bg-gray-100 text-black;
    }

    .list-menu-item.active .icon {
        @apply stroke-current text-red-500;
    }
</style>
