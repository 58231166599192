<script>
    /**
     * A tab bar component.
     *
     * Renders a list of tabs.
     *
     * Usage:
     * <tabbar [autofirst] [@item-clicked] [@item-activated]>
     *     <tabbar [route] [disabled] [active] [icon] [iconset] [@click]>
     *          Tab content. Can be HTML.
     *     </tabbar>
     * </tabbar>
     */
    export default {
        props: {
            // autoactivate first tab if no active defined
            autofirst: {type: Boolean, default: true}
        },
        data() {
            return {
                activeTab: null,
                filterButtonActive: window.location.href.includes('internal_comment'),
            };
        },
        mounted() {
            // sometimes children are not mounted when this hook is executed
            // wait 100ms for them
            setTimeout(this.onInit, 100);
        },
        methods: {
            /**
             * Initialize tab bar.
             */
            onInit() {
                this.$children.filter(c => c.$options.name === 'TabBarItem').map(c => {
                    c.$on('click', this.onItemClicked);
                });

                this.findActiveTab();
                if (!this.activeTab && this.autofirst) {
                    if (this.$children[0]) {
                        this.activate(this.$children[0]);
                    }
                }
            },
            handleFilterClick() {
                this.filterButtonActive = !this.filterButtonActive;
                if (this.filterButtonActive) {
                    if (window.location.href.includes('?')) {
                        window.location.href = window.location.href + '&internal_comment=True';
                    } else {
                        window.location.href = window.location.href + '?internal_comment=True';
                    }
                } else {
                    window.location.href = window.location.href.replace('internal_comment=True', '');
                }
            },

            /**
             * Handles tab click.
             */
            onItemClicked(item) {
                if (item.route) {
                    const prevRoute = this.$route.path;
                    this.$router.push(item.route);

                    // test if navigation has happened as route guard can break it.
                    // select tab only if navigation was confirmed
                    if (prevRoute !== this.$route.path) {
                        this.activate(item);
                        this.$emit('item-clicked', item);
                    }
                } else {
                    this.activate(item);
                    this.$emit('item-clicked', item);
                }
            },

            /**
             * Activate tab.
             */
            activate(tab) {
                if (this.activeTab) {
                    this.activeTab.isActive = false;
                }
                tab.isActive = true;
                this.activeTab = tab;
                this.$emit('item-activated', tab);
            },

            /**
             * Looks for match between current route and child.route property.
             * When true, then this tab is activated.
             */
            findActiveTab() {
                this.$children.map(c => {
                    if (c.route && (c.route.name === this.$route.name)) {
                        const currentPath = this.$route.path.replace(/\/$/, '');
                        if (this.$router.resolve(c.route).route.path === currentPath) {
                            this.activate(c);
                        }
                    }
                });
            }
        },
        watch: {
            // watch route change and activate correct tab
            $route() {
                this.findActiveTab();
            }
        }
    };
</script>

<template>
    <ul class="nav nav-tabs">
        <slot></slot>
        <li v-if="$route.name === 'questionnaire-responses'" class="filter-button">
            <btn v-on:click="handleFilterClick" :class="[filterButtonActive ? 'active-button' : '']">
                <icon class="fa fa-flag" name="iconClass"/>
                {{ 'Display only commented responses'|trans }}
            </btn>
        </li>
    </ul>
</template>

<style scoped lang="scss">
    .nav {
        margin-bottom: 16px;
    }
    .nav-tabs {
        position:relative;
    }
    .filter-button {
        right:0;
        position:absolute
    }
    .active-button {
        border: #f44237;
        border-style: solid;
    }
</style>
