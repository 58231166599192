<script>
    export default {
        provide() {
            return {
                appLayout: this
            };
        }
    };
</script>

<template>
    <main class="app-layout">
        <nav class="navbar navbar-default navbar-fixed-top">
            <div class="container-fluid">
                <slot name="header"></slot>
            </div>
        </nav>
        <slot></slot>
    </main>
</template>

<style lang="scss" scoped>
    .app-layout {
        display: flex;
        height: 100%;
    }

    .relative {
        overflow: hidden;
        padding-left: 0;
    }
</style>
