<script>
    export default {
        name: 'AppMenu',
    };
</script>

<template>
    <ul class="navbar-nav flex">
        <slot></slot>
    </ul>
</template>
