<script>
    export default {
        provide() {
            return {
                flashMessenger: this
            };
        },
        data() {
            return {
                messages: null,
                messagesStyle: null,
                snackbarVisible: false
            };
        },
        computed: {
            icon() {
                return this.$store.state.frontendFramework.currentPage.icon;
            },
            title() {
                return this.$store.state.frontendFramework.currentPage.title;
            },
            lead() {
                return this.$store.state.frontendFramework.currentPage.lead;
            },
            hasBackRoute() {
                const knownRoutes = [];
                this.$route.matched
                    .filter(r => r.name !== '' && !!r.name)
                    .map(r => {
                        knownRoutes.push(r.name);
                        return r;
                    })
                    .filter(r => !knownRoutes.includes(r.name));

                if (this.$route.meta.parentRoute) {
                    knownRoutes.push(this.$route.meta.parentRoute);
                }

                // admin sets "parentRoute" meta property, it always has two default routes: "index"  and "list".
                // we have to hide back button on list pages, this is why this weird check is here
                return knownRoutes.length > 2;
            }
        },
        methods: {
            /**
             * Show positive messages.
             */
            showPositiveMessage(text, header = null, ...args) {
                this._showMessage('success', text);
            },

            /**
             * Show negative messages.
             */
            showNegativeMessage(text, header = null, ...args) {
                this._showMessage('error', text);
            },

            /**
             * Returns a reference to Alert component.
             * @deprecated
             */
            getAlert() {
                return this.$refs.alert;
            },
            _showMessage(type, messages) {
                if (messages.constructor === Array) {
                    messages = messages.join('. ');
                }
                this.messages = messages;
                this.messagesStyle = type;
                this.snackbarVisible = true;
                setTimeout(_ => {
                    this.clearMessages();
                }, 4000);
            },

            /**
             * Remove messages.
             */
            clearMessages() {
                this.snackbarVisible = false;
                setTimeout(_ => {
                    this.messages = null;
                    this.messagesStyle = null;
                }, 400);
            },
            goBack() {
                if (this.hasBackRoute) {
                    if (this.$route.meta.parentRoute) {
                        this.$router.push({
                            name: this.$route.meta.parentRoute,
                            params: this.$route.params
                        });
                    } else {
                        this.$router.go(-1);
                    }
                }
            }
        }
    };
</script>

<template>
    <section class="content-layout">
        <div class="page-header">
            <slot name="page-header">
                <h1 v-if="title">
                    <slot name="heading">
                        <btn-icon icon="arrow-back" @click="goBack" iconset="md" v-if="hasBackRoute"></btn-icon>
                        {{ title|trans }}
                    </slot>
                </h1>
                <p class="lead" v-if="lead">
                    <slot name="lead" v-html="lead|trans"></slot>
                </p>
            </slot>
        </div>
        <div>
            <slot name="messages">
                <alert ref="alert" closeable autohide></alert>
            </slot>
            <slot></slot>
        </div>
        <snackbar :type="messagesStyle" :active.sync="snackbarVisible">
            {{messages}}
            <slot name="snackbar-action"></slot>
        </snackbar>
    </section>
</template>
