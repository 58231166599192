import * as alerts from './alerts';
import * as buttons from './buttons';
import * as cards from './cards';
import * as code from './code';
import { datatable } from './datatable';
import * as dialogs from './dialogs';
import * as dropdowns from './dropdowns';
import * as forms from './forms';
import * as icons from './icons';
import * as image from './image';
import * as layouts from './layouts';
import * as lists from './lists';
import * as loaders from './loaders';
import * as menus from './menus';
import * as nav from './nav';
import * as pagination from './pagination';
import * as popover from './popover';
import { router } from './router';
import * as snackbar from './snackbar';
import * as table from './table';
import * as tabs from './tabs';

export const componentGroups = {
    alerts, buttons, cards, code, datatable, dialogs, dropdowns, forms, icons, image, layouts, lists,
    loaders, menus, nav, pagination, popover, router, snackbar, table, tabs
};

export const components = {
    ...alerts, ...buttons, ...cards, ...code, ...datatable, ...dialogs, ...dropdowns,
    ...forms, ...icons, ...image, ...layouts, ...lists, ...loaders, ...menus,
    ...nav, ...pagination, ...popover, ...router, ...snackbar, ...table, ...tabs
};
