<script>
    /**
     * Renders a hidden field which contains django's CSRF token.
     * Useful when you do a POST form submission from client.
     *
     * Usage:
     *  <csrf-token />
     */
    import {getCookie} from 'tiny-cookie';

    export default {
        data() {
            return {
                token: null
            };
        },
        created() {
            this.token = getCookie('csrftoken');
        }
    };
</script>

<template>
    <input type="hidden" name="csrfmiddlewaretoken" v-model="token">
</template>
