<script>
    export default {
        name: 'AppMenuItem',
        props: {
            href: String
        },
        computed: {
            isActive() {
                return false;
            }

        }
    };
</script>

<template>
    <li class="nav-item" :class="{active: isActive}">
        <a class="nav-link font-semibold" :href="href">
            <slot></slot>
        </a>
    </li>
</template>
