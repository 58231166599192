<script>
export default {
    props: {
        dir: {
            required: false,
            default: null
        }
    },
    computed: {
        direction() {
            if (this.dir === 'desc') {
                return 'fa-sort-desc';
            }

            if (this.dir === 'asc') {
                return 'fa-sort-asc';
            }

            return 'fa-sort text-muted';
        }
    }
};
</script>

<template>
<span>
    <i class="fa" v-bind:class="direction"></i>
</span>
</template>
