<script>
    /**
     * Renders user info and menu in the header.
     */
    import AppBarButton from './AppBarButton';
    import Icon from './Icon';

    export default {
        components: {AppBarButton, Icon},
        computed: {
            user() {
                return this.$store.getters['auth/user'];
            },
            displayName() {
                if(this.user.firstName || this.user.lastName) {
                    return `${this.user.firstName} ${this.user.lastName}`;
                }
                return this.user.email;
            }
        },
        methods: {
            async logout() {
                this.$refs.form.submit();
            }
        }
    };
</script>

<template>
    <require-permissions all="users.is_authenticated">
        <app-bar-button>
            <strong>{{ displayName }}</strong>
            <div class="dropdown-menu" slot="content">
                <slot></slot>
                <a class="dropdown-item" href="#" @click.prevent="logout">
                    {{ 'Logout'|trans }}
                </a>
            </div>
            <form hidden class="hidden" action="/admin/logout/" method="post" ref="form">
                <csrf-token-field />
            </form>
        </app-bar-button>
    </require-permissions>
</template>
