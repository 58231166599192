import { REST } from 'metro-frontend-framework';

export const notifResource = new class extends REST.Resource {
    async markAllAsRead() {
        return this.client.post(
            this.buildURL('mark-all-read')
        );
    }

    async getUnread() {
        return this.client.get(
            this.buildURL('unread')
        );
    }

    async markAsRead(id) {
        return this.client.patch(this.buildURL('mark-read', id));
    }
}('/api/messages/notifications');
