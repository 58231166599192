<script>
    import {formatNumber} from 'libphonenumber-js';
    import BaseMixin from './mixins/base';

    export default {
        name: 'PhoneField',
        mixins: [BaseMixin],
        props: {
            format: {type: String, default: ''}
        },
        data() {
            return {
                shouldFormat: true
            };
        },
        methods: {
            onInput(e) {
                this.$emit('input', this.$el.value.replace(/[^+^\d]/g, ''));
            },
            onChange(e) {
                this.shouldFormat = true;
                this.$emit('change', e);
            },
            formatterValue() {
                if (!this.shouldFormat) {
                    return this.value;
                }

                this.shouldFormat = false;
                return formatNumber(this.value || '', 'International');
            }
        }
    };
</script>

<template>
    <input
            class="form-control"
            ref="field"
            type="tel"
            :disabled="disabled"
            :name="name"
            :placeholder="placeholder"
            :readonly="readonly"
            :required="required"
            :value="formatterValue()"
            autocomplete="tel-national"
            @focus="onFocus"
            @change="onChange"
            @input="onInput"
    />
</template>
