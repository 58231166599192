import Table from './Table';

function processChildren(children, createElement) {
    const slotNames = ['m-table-header-actions', 'm-table-empty-state'];
    let nodes = Array.from(children);
    let namedSlots = [];

    nodes.forEach((node, index) => {
        if (node && node.tag) {
            const tag = node.componentOptions && node.componentOptions.tag;

            if (tag && slotNames.includes(tag)) {
                node.data.slot = tag;
                node.data.attrs = node.data.attrs || {};
                namedSlots[tag] = () => node;
                nodes.splice(index, 1);
            }
        }
    });

    return {
        childNodes: nodes,
        slots: namedSlots
    };
}

export default {
    functional: true,
    render(createElement, {data, props, children}) {
        let slotChildren = [];
        let scopedSlots = data.scopedSlots;

        if (children) {
            const { childNodes, slots } = processChildren(children, createElement);
            slotChildren = childNodes;
            scopedSlots = {
                ...scopedSlots, ...slots
            };
        }

        return createElement(Table, {...data, props, scopedSlots}, scopedSlots, [slotChildren]);
    }
};
