/**
 * A main menu service.
 *
 * Keeps track about menu items.
 */
export class Menu {
    constructor() {
        this._items = [];
    }

    all() {
        return this._items;
    }

    addItem(label, route, icon = null, permissions = null, order = null) {
        if (route.constructor === String) {
            route = {name: route};
        }

        const item = {label, route, icon, permissions, order};

        item.permissions = item.permissions || [];
        item.order = item.order || 10;

        this._items.push(item);
    }

    addMany(items) {
        items.forEach(item => this.addItem(item.label, item.route, item.icon, item.permissions, item.order));
    }
}

export default new Menu();
