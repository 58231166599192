<script>
    /**
     * A flayout menu.
     *
     * Usage:
     *  <popover [position='bottom']>
     *      <btn trigger>Click on me triggers popover</btn>
     *
     *      <popover-header>Hey, I am an optional title</popover-header>
     *      <popover-content>
     *          Popover content goes here.
     *      </popover-content>
     *  </popover>
     *
     */
    import Popper from 'popper.js';

    export default {
        props: {
            position: {type: String, default: 'bottom'}
        },
        data() {
            return {
                popper: null,
                isOpen: false,
                trigger: null
            };
        },
        provide() {
            return {
                popover: this
            };
        },
        computed: {
            cssClasses() {
                const list = ['popover', this.position, 'fade', 'in'];
                if (this.isOpen) {
                    list.push('active');
                }
                return list;
            }
        },
        methods: {
            toggle() {
                this.isOpen = !this.isOpen;
            },
            open() {
                this.isOpen = true;
                if (this.trigger) {
                    this.trigger.classList.add('active');
                    this.popper = new Popper(this.trigger, this.$refs.popover, {
                        modifiers: {
                            placement: this.placement
                        }
                    });
                }
                this.$emit('open');
            },
            close() {
                this.isOpen = false;
                this.trigger.classList.remove('active');
                if (this.popper) {
                    this.popper.destroy();
                    this.popper = null;
                    this.$emit('close');
                }
            }
        },
        mounted() {
            this.$nextTick(_ => {
                this.trigger = this.$el.querySelector('[trigger]');
                if (this.trigger) {
                    this.trigger.addEventListener('click', this.toggle);
                }
            });
        },
        watch: {
            isOpen(flag) {
                if (flag) {
                    this.open();
                } else {
                    this.close();
                }
            }
        }
    };
</script>

<template>
    <div class="popover-wrapper" v-click-outside="close">
        <slot></slot>
        <div :class="cssClasses" ref="popover">
            <div class="arrow"></div>
            <slot name="popover-header"></slot>
            <slot name="popover-content"></slot>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .popover-wrapper {
        display: inline-block;

        .popover {
            min-width: 280px;
            display: block;

            &:not(.active) {
                left: -9999em !important;
                top: -9999em !important;
            }
        }
    }
</style>
