<script>
import IconProps from '../../mixins/icon-props.js';

export default {
    mixins: [IconProps],
    name: 'TabBarItem',
    props: {
        // a route object, compatible with vue-router route definition
        route: Object,
        disabled: Boolean,
        active: Boolean
    },
    data() {
        return {
            isActive: this.active
        };
    },
    computed: {
        cssClasses() {
            return {
                disabled: this.disabled,
                active: this.isActive
            };
        }
    },
    methods: {
        onClicked() {
            if (!this.disabled) {
                this.$emit('click', this);
            }
        }
    }
};
</script>

<template>
    <li :class="cssClasses">
        <a @click="onClicked" href="javascript: void(0)">
            <icon v-if="icon" :name="icon" :iconset="iconset" />
            <slot></slot>
        </a>
    </li>
</template>


<style scoped lang="scss">
li {
    &.disabled {
        a {
            &:hover {
                color: #bbb;
                text-decoration: none;
                cursor: not-allowed;
                box-shadow: none;
            }

            .ripple-wrapper {
                display: none!important;
            }
        }
    }
}
</style>
