<script>
    import HeaderCell from './cells/HeaderCell';

    export default {
        inject: ['table'],
        components: {
            HeaderCell
        }
    };
</script>
<template>
    <thead>
    <tr>
        <require-permissions :all="cell.permissions" v-for="cell in table.cells" :key="cell.label">
            <header-cell :cell="cell">{{ cell.label }}</header-cell>
        </require-permissions>
    </tr>
    </thead>
</template>
