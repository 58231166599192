/**
 * The project's entrypoint.
 *
 * This file setups frontend environment and dependencies.
 * Once done, asynchronously bootstraps each app and starts frontend application.
 */

import merge from 'lodash/merge';
import { AdminGenerator } from './admin';
import { appRegistry } from './apps';
import { headerActions, menu, services } from './services';

const context = { services, appRegistry };

/**
 * Asynchronous entrypoint. The "main" function.
 * Waits for promise resolve returned by app's hooks.
 */
export async function bootstrap(router, store, settings) {
    context.router = router;
    context.store = store;
    context.settings = settings;

    // let app configure itself and environment
    await appRegistry.callHook('onInit', context);

    // once apps are initialized -- let them set defaults
    await appRegistry.callHook('onPostInit', context);

    // backup existing state and remove reactivity from it
    const oldState = JSON.parse(JSON.stringify(store.state));

    // scan apps for routes and stores
    const generator = new AdminGenerator();
    appRegistry.all().forEach(app => {
        let { routes, stores } = generator.build(app, context);
        routes = [...routes, ...app.getRoutes(context.store)];
        stores = merge(stores, app.getStoreModule());
        store.registerModule(app.name, stores);
        router.addRoutes(routes);
        menu.addMany(app.getMenuItems());
        headerActions.addMany(app.getNavbarActions());
    });

    // merge prefilled state (by backend) with configured one
    store.replaceState(
        merge(JSON.parse(JSON.stringify(store.state)), oldState)
    );

    // once store and routes configured, let apps to do extra configuration using them
    await appRegistry.callHook('onBootstrap', context);
};
