<script>
    export default {};
</script>

<template>
    <li class="list-menu-item" v-bind="$attrs" v-on="$listeners">
        <a href="#">
            <slot></slot>
        </a>
    </li>
</template>

<style lang="scss" scoped>
.list-menu-item {
    a {
        white-space: normal;
    }
}
</style>
