import {BaseApp} from './apps';
import './components';
import {headerActions, menu} from './services/';


export class App extends BaseApp {
    onInit({ services }) {
        services.add('header_actions', headerActions);
        services.add('main_menu', menu);
    }
}
