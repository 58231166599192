<script>
    import readFile from '../../../utils/files/file-reader';
    import {loadImageBlob} from './image/loader';
    import Preview from './image/Preview';
    import BaseMixin from './mixins/base';

    const PLACEHOLDER = '/static/metro_frontend_framework/img/image-placeholder.png';

    export default {
        mixins: [BaseMixin],
        props: {
            // image mime types allowed for upload
            accept: {type: String, default: 'image/*'},

            // show\hide preview image
            preview: Boolean,

            // show\hide names of selected files
            textPreview: Boolean,

            // enable\disable editor
            editor: Boolean,

            // URL to original image
            original: {type: [String, Blob], default: null},

            // an URL of image placeholder. set to null to disable.
            placeholder: String
        },
        data() {
            return {
                // image URL or dataurl of current image.
                imageSrc: null,

                // Blob for current image
                imageBlob: null,

                // image URL or dataurl. used to generate a preview
                previewSrc: null,

                // determines if editor is active and open
                editorOpen: false,
            };
        },
        components: {
            Preview
        },
        async created() {
            this.useFixedLabel = true;
        },
        async mounted() {
            await this.loadImage();
        },
        computed: {
            filename() {
                if (this.value) {
                    if (typeof this.value === 'string') {
                        return this.value.split('/').pop();
                    }

                    if (this.value instanceof Blob) {
                        return this.value.name;
                    }
                }

                if (this.imageBlob) {
                    return this.imageBlob.name;
                }
            },
            imagePlaceholder() {
                if (this.placeholder === null) {
                    return null;
                }

                if (!this.placeholder) {
                    return PLACEHOLDER;
                }

                return this.placeholder;
            }
        },
        methods: {
            async loadImage() {
                if (this.value) {
                    if (this.value instanceof Blob) {
                        this.imageBlob = this.value;
                    } else {
                        const blob = await this.$refs.loader.handleAsync(loadImageBlob(this.value));
                        try {
                            this.imageBlob = new File(blob, this.filename);
                        } catch (e) {
                            this.imageBlob = blob;
                        }
                    }
                    this.imageSrc = await readFile(this.imageBlob);
                    this.previewSrc = this.imageSrc;
                }
            },
            readImageSources(blob) {
                this.imageSrc = null;
                if (blob) {
                    this.imageSrc = URL.createObjectURL(blob);
                    this.previewSrc = this.imageSrc;
                }
            },

            /**
             * Called when a new image has been picked.
             */
            onFileChanged(value) {
                this.imageBlob = value;
                this.readImageSources(value);
                this.$emit('input', value);
                this.$emit('select', value);
            },

            /**
             * Called when image edited in the editor.
             */
            onImageEdited(file) {
                this.readImageSources(file);
                this.$emit('input', file);
                this.$emit('edit', file);
            },

            /**
             * Called when file input has cleared it's value.
             */
            onClear() {
                this.previewSrc = null;
                this.$emit('input', null);
                this.$emit('clear');
            },

            /**
             * Show image editor.
             */
            openEditor() {
                this.editorOpen = true;
            },

            /**
             * Hide image editor.
             */
            closeEditor() {
                this.editorOpen = false;
            }
        },
        watch: {
            async value() {
                await this.loadImage();
            }
        }
    };
</script>

<template>
    <div class="image-field">
        <overlay-loader ref="loader" :delay="100" :class="{hide: !preview}">
            <preview :src="previewSrc" :placeholder="imagePlaceholder"></preview>
        </overlay-loader>
        <image-editor
                :src="imageSrc"
                :file="imageBlob"
                :original="original"
                v-if="editorOpen"
                @edit="onImageEdited"
                @exit="closeEditor"
        ></image-editor>

        <file-field @input="onFileChanged" @clear="onClear" :accept="accept" :preview="textPreview" :value="localValue">
        <span slot="left-controls">
            <btn flat link v-if="editor" :disabled="!imageSrc" icon="create" @click="openEditor">{{ 'Edit'|trans }}</btn>
        </span>
        </file-field>
    </div>
</template>

<style lang="scss" scoped>
    .image-field {
        margin-bottom: 8px;
    }
</style>

