<script>
export default {
    props: {
        menu: {type: Array, default: () => []}
    },
    computed: {
        menuItems() {
            return this.menu;
        },
        tenant() {
            return this.$store.getters['auth/tenant'];
        },
    },
    methods: {
        path(r) {
            // keep this until we are ready for history based routing mode
            r.query = r.query || {};
            r.query.tenant = this.tenant.id;
            return this.$router.resolve(r).href.replace(/^#/, '');
        }
    }
};
</script>

<template>
    <section class="app-list">
        <require-permissions :all="item.permissions" v-for="item in menuItems" :key="item.route.name">
            <a :href="path(item.route)">
                <div class="card">
                    <div class="card-content">
                        <div class="icon-holder">
                            <i :class="item.icon"></i><br>
                            {{ item.label | trans }}
                        </div>
                    </div>
                </div>
            </a>
        </require-permissions>
    </section>
</template>

<style lang="scss" scoped>
.app-list {
    .card {
        display: inline-block;
        width: 15rem;
        height: 15rem;
        margin: 1rem;
    }

    .card-content {
        color: #797979;
        text-align: center;
    }

    .card:hover {
        transform: scale(1.05);
        transition: all .1s;
    }

    .card-content i {
        font-size: 50px;
    }
}
</style>
