import Vue from 'vue';
import AppLayout from './AppLayout';
import AppList from './AppList';
import Content from './Content';
import Header from './Header';
import HeaderAction from './HeaderAction';
import HeaderActions from './HeaderActions';
import Logo from './Logo';
import MainArea from './MainArea';

Vue.component('app-content', Content);
Vue.component('app-main-area', MainArea);
Vue.component('app-logo', Logo);
Vue.component('app-list', AppList);
Vue.component('app-layout', AppLayout);
Vue.component('app-header', Header);
Vue.component('app-header-actions', HeaderActions);
Vue.component('app-header-action', HeaderAction);
