import autosize from 'autosize';

/**
 * Autosize directive.
 *
 * Applied to texareas.
 * The textarea will automatically adjust it's size depending on the content size.
 *
 * Example:
 *  <textarea v-autosize></textarea>
 */
export default {
    inserted(el) {
        autosize(el);
    }
};

