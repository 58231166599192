import {apps} from 'metro-core';
import {default as _store} from './state';
import middleware from './middleware';

export {default as directives} from './directives';
export {default as plugins} from './plugins';

export class App extends apps.BaseApp {
    onInit({router, store}) {
        middleware.forEach(mw => mw(router, store));
    }

    getStoreModule() {
        return _store;
    }
}
