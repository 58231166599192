<script>
import ButtonMixin from './mixins/button';

/**
 * Renders a link which looks like a button.
 */
export default {
    mixins: [ButtonMixin],
    props: {
        href: { type: [String, Function], default: '' }
    },
    data() {
        return {
            hrefValue: this.resolvePropValue('href')
        };
    },
    watch: {
        href(value) {
            this.hrefValue = this.resolvePropValue('href');
        }
    }
};
</script>

<template>
    <btn link :href="hrefValue" :icon="icon" :iconset="iconset" v-bind="$attrs" v-on="$listeners">
        <slot></slot>
    </btn>
</template>
