/**
 * Tooltip directive.
 *
 * Example:
 *  <div name="phone" v-mask="(99) 999-99-99" ></div>
 *
 * @link https://getbootstrap.com/docs/3.3/javascript/#tooltips
 */
export default (el, binding) => {
    let placement = 'top';
    for (const mod in binding.modifiers) {
        if (['top', 'right', 'bottom', 'left'].indexOf(mod) !== -1) {
            placement = mod;
        }
    }
    $(el).tooltip({
        placement: placement,
        title: binding.value
    });
};
