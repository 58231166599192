<script>
import BaseMixin from "./mixins/base";

export default {
    name: "Select2",
    mixins: [BaseMixin],
    props: {
        clearable: Boolean,
        multiple: Boolean,
        ignoreWatchers: {
            type: Boolean,
            default: false
        },
    },
    created() {
        this.useFixedLabel = true;
    },
    mounted() {
        const vm = this;
        $(this.$refs.field)
            .select2({
                placeholder: this.placeholder,
                disabled: this.disabled,
                allowClear: this.clearable,
                multiple: this.multiple,
                width: "100%",
            })
            .val(this.localValue)
            .trigger("change")
            .on("change", function () {
                vm.$emit("input", $(this).val());
            })
            .on("select2:open", function () {
                vm.$emit("open");
            });
    },
    destroyed() {
        $(this.$el).off().select2("destroy");
    },
    methods: {
        setValue(value) {
            this.localValue = value;
            $(this.$refs.field).val(value).trigger("change");
        },
        _parseValue(value, oldValue) {
            if (JSON.stringify(value) !== JSON.stringify(oldValue)) {
                $(this.$el).val(value).trigger("change");
            }
        },
    },
    watch: {
        value(value, oldValue) {
            if (this.ignoreWatchers) return;
            // this prevents endless input->watch loop
            this._parseValue(value, oldValue);
        },
        localValue(value, oldValue) {
            if (this.ignoreWatchers) return;
            // this prevents endless input->watch loop
            this._parseValue(value, oldValue);
        }
    }
};
</script>

<template>
    <select
        class="form-control"
        ref="field"
        :name="name"
        :required="required"
        :disabled="disabled"
    >
        <slot></slot>
    </select>
</template>

<style lang="scss" scoped>
.form-control {
    width: 100%;
}
</style>
