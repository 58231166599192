<script>
    import CellMixin from './mixins';

    export default {
        mixins: [CellMixin],
        name: 'ActionsCell'
    };
</script>
<template>
    <td class="row-actions">
        <slot></slot>
    </td>
</template>

<style lang="scss" scoped>
    .row-actions {
        text-align: right;
    }
</style>
