<script>
export default {
    props: {
        title: { type: String, default: '' }
    },
    data() {
        return {
            isVisible: false
        };
    },
    methods: {
        show() {
            this.isVisible = true;
            this.$nextTick(() => {
                $(this.$refs.modal)
                    .modal('show')
                    .on('hidden.bs.modal', f => {
                        this.$emit('hidden');
                    })
                ;
                this.$emit('shown');
            });
        },
        hide() {
            this.isVisible = false;
            $(this.$refs.modal)
                .modal('hide')
                .on('hidden.bs.modal', f => {
                    this.$emit('hidden');
                })
            ;
        }
    }
};
</script>

<template>
    <div class="modal" v-if="isVisible" ref="modal">
        <div class="modal-dialog">
            <div class="modal-content">
                <slot name="content">
                    <slot name="header">
                        <div class="modal-header">
                            <slot name="header-actions">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </slot>
                            <slot name="header-title">
                                <h4 class="modal-title">
                                    <slot name="header-text">
                                        {{ title|trans }}
                                    </slot>
                                </h4>
                            </slot>
                        </div>
                    </slot>
                    <div class="modal-body">
                        <slot></slot>
                    </div>
                    <slot name="footer">
                        <div class="modal-footer">
                            <slot name="footer-actions"></slot>
                        </div>
                    </slot>
                </slot>
            </div>
        </div>
    </div>
</template>
