<script>
import CloseIcon from './CloseIcon';

export default {
    name: 'TagsFieldTag',
    components: {
        CloseIcon
    },
    inject: ['tagsInput'],
    computed: {
        deletable() {
            return !this.tagsInput.disabled && !this.tagsInput.readonly;
        }
    },
    methods: {
        onRemove() {
            this.$emit('removed', this);
        }
    }
};
</script>

<template>
    <div class="chip" :class="{disabled: tagsInput.disabled}">
        <div><slot></slot></div>
        <close-icon @click.native="onRemove" v-if="deletable" />
    </div>
</template>

<style lang="scss" scoped>
$color: rgba(0,0,0,0.87);
$backround: rgba(224, 224, 224, 1);

$color-accent: white;
$background-accent: #9f9e9f;

.chip {
    display: flex;
    height: 32px;
    font-size: 13px;
    font-weight: 500;
    color: $color;
    line-height: 32px;
    padding: 0 12px;
    border-radius: 16px;
    background-color: $backround;
    margin-right: 4px;
    margin-bottom: 4px;
    transition: .15s;
    cursor: pointer;

    &:not(.disabled):hover {
        background: $background-accent;
        color: $color-accent;

        > .remove {
            background: white;
            color: #9f9e9f;
        }
    }

    &.disabled {
        cursor: not-allowed;
        color: rgba($color, .5);
        background: rgba($backround, .5);
    }

    > div {
        display: inline-block;
    }
}
</style>
