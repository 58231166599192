<script>
import AppBarButton from '@/apps/theme_sqq/components/AppBarButton';
import AppHeader from './AppHeader';
import AppMenu from './AppMenu';
import AppMenuItem from './AppMenuItem';
import AppMenuAction from './AppMenuAction';
import TenantSelector from './TenantSelector';
import UserMenu from './UserMenu';
import LanguageSelector from './LanguageSelector';
import NotificationIcon from './NotificationIcon';
import menu from '@/menu';
import {PortalTarget} from 'portal-vue';

const VISIBLE_ITEMS = 4;
export default {
    components: {
        AppBarButton,
        AppHeader, AppMenu, AppMenuItem, AppMenuAction, TenantSelector,
        UserMenu, LanguageSelector, NotificationIcon, PortalTarget
    },
    props: {
        logo: String,
    },
    data() {
        return {
            headerDisabled: false,
        };
    },
    provide() {
        return {
            pageLayout: {
                disablePageHeader: this.disableHeader,
            }
        };
    },
    computed: {
        products() {
            return ['dashboard', 'analytics', 'benchmark', 'crawlers'];
        },
        tenant() {
            return this.$store.getters['auth/tenant'];
        },
        menuItems() {
            return menu.sort((a, b) => {
                if (a.order > b.order) {
                    return 1;
                }
                if (a.order < b.order) {
                    return -1;
                }
                return 0;
            });
        },
        visibleMenuItems() {
            return this.menuItems.slice(0, VISIBLE_ITEMS);
        },
        hiddenMenuItems() {
            return this.menuItems.slice(VISIBLE_ITEMS);
        },
        actions() {
            return this.$services.get('header_actions').all();
        },
        hasSidebar() {
            return this.$route.matched.find(m => Object.keys(m.components).length > 1);
        },
        pageTitle() {
            return this.$store.state.frontendFramework.currentPage.title;
        },
        hasBackRoute() {
            const knownRoutes = [];
            this.$route.matched
                .filter(r => r.name !== '' && !!r.name)
                .filter(r => !r.redirect)
                .map(r => {
                    knownRoutes.push(r.name);
                    return r;
                })
                .filter(r => !knownRoutes.includes(r.name));

            if (this.$route.meta.parentRoute) {
                knownRoutes.push(this.$route.meta.parentRoute);
                return true;
            }

            // admin sets "parentRoute" meta property, it always has two default routes: "index"  and "list".
            // we have to hide back button on list pages, this is why this weird check is here
            return knownRoutes.length > 2;
        }
    },
    methods: {
        disableHeader() {
            this.headerDisabled = true;
        },
        path(r) {
            // keep this until we are ready for history based routing mode
            r.query = r.query || {};
            r.query.tenant = this.tenant.id;
            return this.$router.resolve(r).href;
        },
        isProductEnabled(product) {
            let productMap = {
                'sqq-dashboard': 'dashboard',
                'crawlers-list': 'crawlers',
            };
            let name = productMap[product] || product;
            if (!this.products.includes(name)) {
                return true;
            }
            return this.tenant.enabledProducts.includes(name);
        },
        goBack() {
            if (this.hasBackRoute) {
                if (this.$route.meta.parentRoute) {
                    this.$router.push({
                        name: this.$route.meta.parentRoute,
                        params: this.$route.params
                    });
                } else {
                    this.$router.go(-1);
                }
            }
        }
    }
};
</script>

<template>
    <div>
        <app-header homepage="/admin">
            <img alt="SQQ" :src="logo" slot="logo" class="h-10">
            <app-menu>
                <require-permissions
                    :all="item.permissions"
                    v-for="item in visibleMenuItems"
                    :key="item.route.name"
                >
                    <app-menu-item :href="path(item.route)">
                        {{ $t(item.label) }}
                        <i class="fa fa-lock ml-2" v-if="!isProductEnabled(item.route.name)"></i>
                    </app-menu-item>
                </require-permissions>
                <app-menu-action>
                    <app-bar-button>
                        <span>{{ 'More'|trans }}</span>
                        <div class="dropdown-menu" slot="content">
                            <require-permissions
                                :all="item.permissions"
                                v-for="item in hiddenMenuItems"
                                :key="item.route.name"
                            >
                                <a
                                    class="dropdown-item"
                                    :href="path(item.route)"
                                >
                                    {{ $t(item.label) }}
                                    <i class="fa fa-lock ml-2" v-if="!isProductEnabled(item.route.name)"></i>
                                </a>
                            </require-permissions>
                        </div>
                    </app-bar-button>
                </app-menu-action>
            </app-menu>

            <div class="flex" slot="actions">
                <app-menu-action>
                    <notification-icon></notification-icon>
                </app-menu-action>
                <app-menu-action>
                    <language-selector></language-selector>
                </app-menu-action>
                <app-menu-action>
                    <tenant-selector></tenant-selector>
                </app-menu-action>
                <app-menu-action>
                    <user-menu>

                    </user-menu>
                </app-menu-action>
            </div>
        </app-header>

        <slot>
            <div class="container mx-auto">
                <div class="flex mt-5">
                    <div class="w-48 mr-5 flex-shrink-0 pt-16" v-if="hasSidebar">
                        <router-view name="sidebar"></router-view>
                    </div>

                    <div class="flex-auto">
                        <div class="mt-5 mb-5" v-if="!headerDisabled">
                            <h3 class="flex items-center">
                                <btn-icon
                                    icon="arrow-back"
                                    class="mr-5"
                                    @click="goBack"
                                    iconset="md"
                                    v-if="hasBackRoute"
                                ></btn-icon>
                                <div>
                                    {{ pageTitle|trans }}
                                </div>
                            </h3>
                        </div>
                        <router-view></router-view>
                    </div>
                </div>
            </div>
        </slot>
        <portal-target name="modals"></portal-target>
        <portal-target name="dropdowns" multiple></portal-target>
    </div>
</template>
