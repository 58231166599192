<script>
    import BaseMixin from './mixins/base';

    export default {
        mixins: [BaseMixin],
        props: {
            multiple: {type: [Boolean, String], default: false}
        },
        provide() {
            return {
                selectField: this
            };
        },
        mounted() {
            this.checkOptions();
        },
        updated() {
            this.checkOptions();
        },
        methods: {
            shouldBeActive() {
                return true;
            },
            onChange(e) {
                if (this.multiple === false) {
                    this.$emit('input', this.$el.value);
                    return;
                }

                const selected = [];
                Array.from(this.$el.options).filter(o => o.selected).forEach(option => selected.push(option.value));
                this.$emit('input', selected);
            },
            checkOptions() {
                if (!this.value) {
                    if (this.$refs.placeholder) {
                        this.$refs.placeholder.selected = true;
                    }
                    return;
                }

                let values = this.value;
                if (this.value.constructor !== Array) {
                    values = [values];
                }

                const stringValues = values.map(v => '' + v);
                Array.from(this.$el.options)
                    .filter(option => stringValues.includes(option.value))
                    .forEach(option => {
                        option.selected = true;
                    });
            }
        }
    };
</script>

<template>
    <select
            class="form-control"
            ref="field"
            :multiple="multiple"
            :disabled="disabled"
            :name="name"
            :readonly="readonly"
            :required="required"
            @blur="onBlur"
            @focus="onFocus"
            @change="onChange">
        <option v-if="placeholder" :disabled="required" value ref="placeholder">{{ placeholder }}</option>
        <slot></slot>
    </select>
</template>
