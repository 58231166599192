<script>
/**
 * Link cell.
 *
 * Renders a link.
 *
 * See ColumnMixin for more docs.
 */

import ColumnMixin from './ColumnMixin';

export default {
    mixins: [ColumnMixin],
    name: 'LinkCell',
    computed: {
        url() {
            let url = '';
            if (this.column.typeOptions.href.constructor === Function) {
                url = this.column.typeOptions.href(this.row);
            } else {
                url = this.column.typeOptions.href.replace(':id', this.row.id);
            }
            return url;
        }
    }
};
</script>

<template>
    <a :href="url">{{ formatted }}</a>
</template>
