<script>
/**
 * Checkbox group.
 */
export default {
    name: 'CheckboxGroup',
    props: {
        inline: Boolean,
        value: { default: f => [] }
    },
    data() {
        return {
            localValue: [],
            fieldCache: {}
        };
    },
    created() {
        // set outer value into local one
        this.isInput = true;
        this.localValue = this.value;
        this.useFixedLabel = true;
    },
    mounted() {
        // look for radio inputs in children and subscribe to their input event
        this.$children
            .filter(child => child.$options.name === 'CheckboxField')
            .map(child => {
                child.$on('input', this.onInput);
                child.setInline(this.inline);
            });

        this.updateChildrensValues();
    },
    methods: {
        /**
         * "input" event handler.
         */
        onInput(value) {
            this.localValue = value;
        },

        /**
         * Pass current localValue to child checkboxes.
         */
        updateChildrensValues() {
            this.$children.map(child => {
                child.localValue = this.localValue;
            });
        },

        /**
         * Overwrite local value with new one.
         */
        setValue(value) {
            this.localValue = value || [];
            this.updateChildrensValues();
        }
    },
    watch: {
        value(value) {
            this.localValue = value;
        },
        localValue() {
            this.$emit('input', this.localValue);
        }
    }
};
</script>

<template>
    <div>
        <slot></slot>
    </div>
</template>

<style lang="scss" scoped>
.checkbox-inline {
    &:first-child {
        padding-left: 0;
    }
}
</style>
