<script>
    /**
     * Tenant selection menu.
     *
     * The components reads tenant list from store at node "permissions.tenants".
     *
     * Usage:
     *  <tenant-select-menu [:switch-url] />
     *      switch-url: is an URL where to make a POST request in order to change current tenant.
     */
    import AppBarButton from './AppBarButton';

    export default {
        components: {AppBarButton},
        props: {
            switchUrl: {
                type: String,
                default: '/admin/tenants/select/'
            }
        },
        data() {
            return {
                filter: '',
                selectedTenant: null,
                isOpen: false
            };
        },
        computed: {
            url() {
                return window.location.href;
            },
            currentTenant() {
                return this.$store.getters['auth/tenant'];
            },
            tenants() {
                return this.$store.getters.assignedTenants;
            },
            filteredTenants() {
                return this.tenants.filter(t => {
                    return t.name.toLowerCase().indexOf(this.filter.toLowerCase()) !== -1;
                });
            }
        },
        methods: {
            onTenantSelected(tenant) {
                this.selectedTenant = tenant.id;
                this.$nextTick(_ => {
                    console.log(
                        this.$refs.form.action
                    );
                    this.$refs.form.submit();
                });
            },
            onPopoverOpened() {
                this.$refs.filterField.$el.focus();
            },
            onPopoverClosed() {
                this.filter = '';
            },
            toggleMenu() {
                this.$refs.popover.toggle();
            },
            closeMenu() {
                this.$refs.popover.close();
            }
        }
    };
</script>

<template>
    <div class="nav-link tenant-switcher" @shortkey="toggleMenu" v-if="currentTenant">
        <form method="post" :action="switchUrl" ref="form">
            <csrf-token-field></csrf-token-field>
            <input type="hidden" name="tenant" :value="selectedTenant">
            <input type="hidden" name="next" :value="url">
        </form>

        <popover @open="onPopoverOpened" @close="onPopoverClosed" ref="popover">
            <button trigger>
                <strong>{{ currentTenant.name }}</strong>
                <span class="caret"></span>
            </button>

            <popover-content>
                <div class="select-popover">
                    <div class="filter">
                        <text-field
                            v-model="filter"
                            :placeholder="$t('Type to search...')"
                            @keyup.esc.native="closeMenu"
                            ref="filterField"
                        ></text-field>
                    </div>
                    <scroll-layout>
                        <div class="list-menu">
                            <a class="list-menu-item"
                                :class="{active: tenant.id == currentTenant.id}"
                                @click="onTenantSelected(tenant)"
                                v-for="tenant in filteredTenants" :key="tenant.id">
                                {{ tenant.name }}
                            </a>
                            <a v-if="!filteredTenants.length" class="text-center disabled list-menu-header">
                                <a href="#" class="text-muted">
                                    {{ 'No tenants found.'|trans }}
                                </a>
                            </a>
                        </div>
                    </scroll-layout>
                </div>
            </popover-content>
        </popover>
    </div>
</template>

<style lang="scss" scoped>
    .tenant-switcher {
        .select-popover {
            display: flex;
            flex-direction: column;
        }
    }
</style>
