<script>
export default {
    props: {
        headers: { type: Array, default: f => ['Name', 'Type', 'Description'] }
    }
};
</script>

<template>
    <table class="table table-full table-full-small">
        <thead>
            <tr>
                <th v-for="h in headers" :key="h" scope="col">{{ h }}</th>
            </tr>
        </thead>
        <tbody>
            <slot>
                <tr>
                    <td :colspan="headers.length">
                        <div class="text-muted text-center">no data</div>
                    </td>
                </tr>
            </slot>
        </tbody>
    </table>
</template>
