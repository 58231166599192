<script>
    /**
     * QR-code renderer.
     *
     * Usage:
     *     <qr-code :text [width] [download-width] [filename] @input />
     *
     *  Props:
     *      text: string - a text to code in image
     *      downloadable: boolean - allow image download. default: false
     *      width: string|number - a image height and width. default: 180px
     *      download-width: string|number - a image size used in downloaded image. default: to value of "width"
     *      filename: string - a filename to send to browser, default: qr.png
     *
     *  Events:
     *      input: fired when image was rendered. the first argument is a rendered image encoded into data-url
     *
     *  API:
     *      download(): trigger image download dialog
     */
    import QRCode from 'qrcode';

    export default {
        props: {
            width: { type: [Number, String], default: 180 },
            downloadWidth: { type: [Number, String], default: null },
            downloadable: Boolean,
            filename: { type: String, default: 'qr.png' },
            text: { type: [String], required: true },
        },
        mounted() {
            this.makeCode(this.text);
        },
        computed: {
            saveWidth() {
                return this.downloadWidth || this.width;
            }
        },
        methods: {
            makeCode(text) {
                if (!text) {
                    return null;
                }

                QRCode.toCanvas(this.$refs.canvas, text, { width: this.width });
                this.$emit('input', this.$refs.canvas.toDataURL());
            },
            async onClicked() {
                if (this.downloadable) {
                    const code = await QRCode.toDataURL(this.text, {
                        width: this.saveWidth
                    });

                    const link = document.createElement('a');
                    link.href = code;
                    link.download = this.filename;
                    link.click();
                }
            },
            download() {
                this.onClicked();
            },
        },
        watch: {
            text(text) {
                this.makeCode(text);
            }
        }
    };
</script>

<template>
    <div class="qr-code">
        <canvas ref="canvas" :class="{downloadable: downloadable}" @click="onClicked"></canvas>
    </div>
</template>

<style lang="scss">
    .qr-code {
        canvas.downloadable {
            cursor: pointer;
        }
    }
</style>
