import './cells';
import './helpers';
import datasources from './datasource';
import Table from './Container';
import DataTable from './DataTable';
import RowTemplate from './RowTemplate';
import HeaderActions from './HeaderActions';
import EmptyState from './EmptyState';

export default {
    ...datasources
};

export const datatable = {
    'mTable': Table,
    'mTadaTable': DataTable,
    'mTableRowTemplate': RowTemplate,
    'mTableHeaderActions': HeaderActions,
    'mTableEmptyState': EmptyState
};
