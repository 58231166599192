import Vue from 'vue';
import {getObjectId} from './utils';

export const createStoreModule = (store, admin) => {
    const mutations = {};
    const actions = {};
    const state = {};
    const getters = {};

    const spec = [
        {
            name: 'get',
            action: async ({commit}, id) => {
                const response = await admin.resource.get(id);
                commit(admin.getMutationName('set-object'), response.data);
                return JSON.parse(JSON.stringify(response.data));
            }
        },
        {
            name: 'list',
            mutation: (_state, data) => {
                _state[admin.name].list = data;
            },
            action: async ({ commit }, payload) => {
                try {
                    commit(admin.getMutationName('set-loading-state'), true);
                    const response = await admin.resource.all({
                        params: payload
                    });
                    commit(admin.getMutationName('list'), response.data.items);
                    return response;
                } catch (e) {
                    throw e;
                } finally {
                    commit(admin.getMutationName('set-loading-state'), false);
                }
            }
        },
        {
            name: 'create',
            mutation: (_state, data) => {
                _state[admin.name].list.push(data);
            },
            action: async ({commit}, data) => {
                const response = await admin.resource.create(data);
                commit(admin.getMutationName('create'), response.data);
                return JSON.parse(JSON.stringify(response.data));
            }
        },
        {
            name: 'delete',
            mutation: (_state, data) => {
                _state[admin.name].list = _state[admin.name].list.filter(item => {
                    return (Number(item.id) !== Number(data.id));
                });
            },
            action: async ({commit}, data) => {
                await admin.resource.delete(data.id);
                commit(admin.getMutationName('delete'), data);
            }
        },
        {
            name: 'update',
            mutation: (_state, data) => {
                _state[admin.name].list = _state[admin.name].list.map(item => {
                    if (Number(item.id) === Number(data.id)) {
                        return data;
                    }
                    return item;
                });

                if (_state[admin.name].object.id === data.id) {
                    _state[admin.name].object = data;
                }
            },
            action: async ({commit}, data) => {
                const response = await admin.resource.update(getObjectId(data), data);
                commit(admin.getMutationName('update'), response.data);
                return JSON.parse(JSON.stringify(response.data));
            }
        },
        {
            name: 'partial-update',
            mutation: (_state, data) => {
                _state[admin.name].list.forEach(item => {
                    if (Number(item.id) === Number(data.id)) {
                        for (const name in data) {
                            Vue.set(item, name, data[name]);
                        }
                    }
                });

                if (_state[admin.name].object.id === data.id) {
                    for (const name in data) {
                        Vue.set(_state[admin.name].object, name, data[name]);
                    }
                }
            },
            action: async ({commit}, data) => {
                const response = await admin.resource.partialUpdate(getObjectId(data), data);
                commit(admin.getMutationName('partial-update'), response.data);
                return JSON.parse(JSON.stringify(response.data));
            }
        },
        {
            name: 'save',
            action: async ({commit, dispatch}, data) => {
                if (getObjectId(data)) {
                    return dispatch(admin.getActionName('update'), data);
                }
                return dispatch(admin.getActionName('create'), data);
            }
        },
        {
            name: 'set-object',
            mutation: (_state, data) => {
                _state[admin.name].object = data;
            },
            action: ({commit}, data) => {
                commit(admin.getMutationName('set-object'), data);
            }
        },
        {
            name: 'set-loading-state',
            mutation: (_state, flag) => {
                _state[admin.name].isListLoading = flag;
            }
        }
    ];

    spec.filter(s => {
        if (!admin.allowEdit && ['update', 'partial-update'].includes(s.name)) {
            return false;
        }

        if (!admin.allowCreate && ['create'].includes(s.name)) {
            return false;
        }

        if (!admin.allowDelete && ['delete'].includes(s.name)) {
            return false;
        }
        return true;
    }).forEach(item => {
        if (item.mutation) {
            const mutationName = admin.getMutationName(item.name);
            if (!Object.keys(store._mutations).includes(mutationName)) {
                mutations[mutationName] = item.mutation;
            }
        }

        if (item.action) {
            const actionName = admin.getActionName(item.name);
            if (!Object.keys(store._mutations).includes(actionName)) {
                actions[actionName] = item.action;
            }
        }
    });

    getters[admin.getGetterName('List')] = (_state) => {
        return _state[admin.name].list;
    };

    getters[admin.getGetterName('Current')] = (_state) => {
        return _state[admin.name].object;
    };

    state[admin.name] = {isListLoading: false, list: [], object: {}};

    return {
        state, mutations, actions, getters
    };
};
