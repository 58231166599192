<script>
    export default {
        name: 'AppHeader',
        props: {
            homepage: String
        },
        mounted() {
            let height = this.$el.querySelector('.header').getBoundingClientRect().height;
            this.$el.style.height = `${height}px`;
        }
    };
</script>

<template>
    <div>
        <div class="header fixed top-0 left-0 right-0 z-10">
            <div class="container mx-auto">
                <nav class="navbar navbar-expand">
                    <a :href="homepage" class="navbar-brand flex-shrink-0">
                        <slot name="logo"></slot>
                    </a>
                    <div class="navbar-menu">
                        <slot></slot>
                    </div>
                    <div class="navbar-actions">
                        <slot name="actions"></slot>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</template>

<style scoped>
    @media print {
        .header.fixed {
            position: static;
        }
    }
</style>
