<script>
export default {
    data() {
        return {
            collapsed: true
        };
    },
    inject: ['listOptions'],
    computed: {
        cssClasses() {
            let classes = [];
            if (this.collapsed === false) {
                classes.push('expanded');
            }
            return classes.join(' ');
        },
        chevronIcon() {
            return (this.collapsed ? 'fa-chevron-down' : 'fa-chevron-up');
        },
        isExpandable() {
            return this.listOptions.expandable;
        }
    },
    methods: {
        onHeaderClicked() {
            if (this.isExpandable) {
                this.collapsed = !this.collapsed;
            }
        }
    }
};
</script>

<template>
    <div class="m-list-item" :class="cssClasses">
        <div class="header" @click="onHeaderClicked">
            <slot name="icon"></slot>

            <slot></slot>
            <span class="status-icon" v-if="isExpandable">
                <i class="fa" :class="chevronIcon"></i>
            </span>
        </div>
        <div class="content" v-show="!collapsed" v-if="isExpandable">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<style lang="scss" scoped>
$padding: 8px;

.m-list-item {
    cursor: pointer;

    &.expanded {
        background: #fafafa;
        border-bottom: 1px solid #ddd;
    }

    &:last-child {
        > .header {
            border-bottom: none;
        }
    }

    > .header {
        padding: $padding;
        border-bottom: 1px solid #eee;

        > .status-icon {
            display: block;
            float: right;
            font-size: 12px;
            padding-top: 2px;
        }
    }

    > .content {
        padding-left: 24px;
        transition: 1s;
    }

}
</style>
