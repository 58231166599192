<script>
    export default {
        computed: {
            actions() {
                return this.$services.get('header_actions').all();
            }
        }
    };
</script>

<template>
    <ul class="nav navbar-nav navbar-right navbar-right-no-collapse">
        <app-header-action v-for="comp in actions" :key="comp.cid">
            <component :is="comp"></component>
        </app-header-action>
        <slot></slot>
    </ul>
</template>
