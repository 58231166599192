<script>
    import BaseMixin from './mixins/base';

    export default {
        name: 'TextField',
        mixins: [BaseMixin],
        props: {
            multiline: Boolean
        },
        mounted() {
            if (this.multiline) {
                this.$nextTick(() => {
                    const event = new Event('input', {bubbles: true});
                    this.$refs.field.dispatchEvent(event);
                });
            }
        }
    };
</script>

<template>
    <textarea
            v-if="multiline"
            class="form-control multiline"
            ref="field"
            :disabled="disabled"
            :name="name"
            :placeholder="placeholder"
            :readonly="readonly"
            :required="required"
            :value="value"
            @blur="onBlur"
            @focus="onFocus"
            @change="onChange"
            @input="onInput"
            v-bind="$attrs"
            v-autosize
    ></textarea>

    <input
            v-else
            class="form-control"
            ref="field"
            type="text"
            :disabled="disabled"
            :name="name"
            :placeholder="placeholder"
            :readonly="readonly"
            :required="required"
            :value="value"
            v-bind="$attrs"
            @blur="onBlur"
            @focus="onFocus"
            @change="onChange"
            @input="onInput"
    />
</template>

<style lang="scss">
    .multiline {
        max-height: 500px;
        overflow-y: auto;
    }
</style>
