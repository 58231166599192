<script>
    /**
     * Renders a popover content.
     *
     * A part of <popover> component.
     */
    export default {};
</script>

<template>
    <div class="popover-content">
        <slot></slot>
    </div>
</template>


