<script>
import {FieldType} from '../constants';
import {includesFiles, objToFormData} from './utils';

// a list of known fields with support of file uploads
const knownUploadFields = ['file-field', 'image-field'];

export default {
    props: {
        app: {required: true, type: Object},
        admin: {required: true, type: Object},
        object: {type: Object, required: true}
    },
    data() {
        return {
            formData: JSON.parse(JSON.stringify(this.object)),
            isFormChanged: false,
            uploadFields: []
        };
    },
    created() {
        // disable "unsaved" notice as it is not ready yet
        this.admin.getEditPage().setConfirmRouteLeave(false);

        this.setPageTitle();
        this.buildModel();
        this.formData.id = this.object.id || null;

        if (this.admin.getEditPage().confirmRouteLeave) {
            window.addEventListener('beforeunload', this.onBeforeUnload);
        }
    },
    mounted() {
        this.uploadFields = this.getUploadFields();
    },
    destroyed() {
        window.removeEventListener('beforeunload', this.onBeforeUnload);
    },
    computed: {
        editPermissions() {
            return this.admin.getEditPage().getPermissions();
        },
        formComponent() {
            return this.admin.getEditPage().getFormComponent();
        },
        editPage() {
            return this.admin.getEditPage();
        },
        hasUpload() {
            return includesFiles(this.formData);
        }
    },

    /**
     * Rescan uploaded fields when children changed.
     */
    updated() {
        this.uploadFields = this.getUploadFields();
    },

    /**
     * When form model is modified and unsaved, this should prevent navigation.
     */
    beforeRouteLeave(to, from, next) {
        if (this.admin.getEditPage().confirmRouteLeave) {
            if (this.confirmNavigation(next)) {
                next();
            }
        } else {
            next();
        }
    },
    methods: {
        /**
         * Set current page title.
         * Displayed in browser's tab title and at the top of the page.
         */
        setPageTitle() {
            let title = this.editPage.createItemPageTitle;
            if (this.object.id) {
                title = this.editPage.editItemPageTitle;
            }
            this.$store.dispatch('frontend.current-page.set-title', title);
        },
        getModelDefinitionValue(field, definition) {
            let value = this.object[field];
            if (value === null || value === undefined) {
                if (definition.default !== null || definition.default !== undefined) {
                    value = definition.default;
                } else {
                    value = null;
                }
            }
            return value;
        },
        /**
         * Builds a form model using provided mapping.
         */
        _getValue(field, lang, fieldName) {
            let _value = null;
            try {
                _value = JSON.parse(JSON.stringify(this.object[field][lang][fieldName] || ''));
            } catch (e) {
                if (!(e instanceof TypeError)) {
                    throw e;
                }
                _value = def.default;
            }
            return _value;
        },
        buildModel() {
            const langs = this.$store.state.trans.languages.enabled;
            const model = {};

            Object.keys(this.admin.mapping).forEach(field => {
                /** @type {Admin.Mapping|Admin.TranslationMapping} */
                const definition = this.admin.mapping[field];

                if (definition.type === FieldType.TRANSLATIONS) {
                    if (!model.hasOwnProperty(field)) {
                        model[field] = {};
                    }

                    langs.forEach(lang => {
                        if (!model[field].hasOwnProperty(lang)) {
                            model[field][lang] = {};
                        }

                        Object.keys(definition.children).forEach(key => {
                            /** @type {Admin.Mapping} */
                            const def = definition.children[key];
                            const fieldName = def.name.split('__').pop();
                            model[field][lang][fieldName] = this._getValue();
                        });
                    });
                    return;
                }
                model[field] = this.getModelDefinitionValue(field, definition);
            });

            this.$set(this, 'formData', Object.assign({}, this.formData, model));
        },

        /**
         * Called by router when current route changes.
         * This prevents user from loosing unsaved data.
         */
        confirmNavigation() {
            // allow navigation if form data is not changed
            if (!this.isFormChanged) {
                return true;
            }

            return confirm(this.admin.getEditPage().routeLeaveConfirmation);
        },

        /**
         * Called by browser when user navigates away from page.
         * This prevents user from loosing unsaved data.
         */
        onBeforeUnload(e) {
            if (this.isFormChanged) {
                e.returnValue = this.admin.getEditPage().routeLeaveConfirmation;
                return this.admin.getEditPage().routeLeaveConfirmation;
            }
        },

        /**
         * Returns names of file input fields.
         */
        getUploadFields() {
            return this.$refs.form.formGroups
                .filter(fg => fg.field)
                .filter(fg => {
                    if (fg.field.$vnode) {
                        return knownUploadFields.includes(
                            fg.field.$vnode.componentOptions.tag
                        );
                    } else {
                        return false;
                    }
                })
                .filter(fg => {
                    if (!fg.field.name) {
                        console.error('[admin] found upload field without name', fg.field.$el);
                        return false;
                    }
                    return true;
                })
                .map(fg => fg.field.name);
        },

        /**
         * Handles form submission.
         * @returns {Promise<void>}
         */
        async onSubmit() {
            const form = this.$refs.form;
            const isValid = await form.validate();

            if (!isValid) {
                form.setNegativeMessage(this.$t('Form data is invalid.'));
                return;
            }

            try {
                let data = Object.assign({}, this.formData);

                // filter out file field values containing strings
                // these strings usually are urls and means that user did not interact with such fields
                this.uploadFields.forEach(name => {
                    // file field value can be of type Blob|File or null, others invalid
                    if (data[name] !== null && !(data[name] instanceof Blob)) {
                        console.debug('[admin] field %s has non-uploadable value.', name);
                        delete data[name];
                    }
                });

                let uploads = {};
                Object.keys(data).forEach(k => {
                    if (data[k] instanceof Blob) {
                        uploads[k] = data[k];
                        delete data[k];
                    }
                });

                const oldId = this.formData.id || null;

                // send main data as JSON to backend
                this.formData = await this.$refs.btn.handleAsync(
                    this.admin.saveObject(data)
                );

                // upload any pending files in the second request
                if (Object.keys(uploads).length > 0) {
                    uploads['id'] = this.formData.id;
                    this.formData = await this.$refs.btn.handleAsync(
                        this.admin.partialUpdateObject(objToFormData(uploads))
                    );
                }

                // put updated object to the store
                await this.admin.setObject(this.formData);

                // where these values are not equal, then a new object has been created.
                // in this case rewrite route to "edit" one: /objects/edit/ -> /objects/edit/:id
                if (oldId !== this.formData.id) {
                    this.$router.replace({
                        name: this.admin.getEditPage().getRouteName(),
                        params: {id: this.formData.id}
                    });
                }
                form.setPositiveMessage(this.$t('Item has been saved.'));
                this.isFormChanged = false;
            } catch (e) {
                if ('response' in e) {
                    form.handleResponse(e.response);
                } else {
                    console.error(e);
                    form.setNegativeMessage(this.$t('An error has occured.'));
                }
            }
        }
    },
    watch: {
        'object.id'(val, oldval) {
            if (!oldval) {
                this.setPageTitle();
            }
        },
        formData: {
            handler(val, oldval) {
                const newVal = JSON.stringify(val);
                const oldVal = JSON.stringify(oldval);
                this.isFormChanged = (newVal === oldVal);
            },
            deep: true
        }
    }
};
</script>
<template>
    <m-form @submit="onSubmit" ref="form">
        <component :is="formComponent" :object="object" :form-data.sync="formData"></component>
        <form-actions>
            <component :object="object" :formData.sync="formData" :is="fa" :key="fa.cid"
                       v-for="fa in editPage.formActions"></component>
            <action-button primary submit ref="btn">{{ 'Save'|trans }}</action-button>
        </form-actions>
    </m-form>
</template>
