<script>
export default {
    props: {
        wrap: Boolean,
        equalHeight: Boolean,
        row: Boolean,
        rowReverse: Boolean,
        column: Boolean,
        columnReverse: Boolean
    },
    computed: {
        cssClasses() {
            return {
                'wrap': this.wrap,
                'equal-height': this.equalHeight,
                'dir-row': this.row,
                'dir-row-reverse': this.rowReverse,
                'dir-column': this.column,
                'dir-column-reverse': this.columnReverse
            };
        }
    }
};
</script>

<template>
    <div class="flex" :class="cssClasses">
        <slot></slot>
    </div>
</template>

<style lang="scss" scoped>
.flex {
    display: flex;

    &.wrap {
        flex-wrap: wrap;
    }

    &.equal-height {
        > .col-* {
            display: flex;
        }
    }

    &.dir-row {
        flex-direction: row;
    }

    &.dir-row-reverse {
        flex-direction: row-reverse;
    }

    &.dir-column {
        flex-direction: column;
    }

    &.dir-column-reverse {
        flex-direction: column-reverse;
    }


}
</style>
