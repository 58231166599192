<script>
import ButtonMixin from './mixins/button';

export default {
    mixins: [ButtonMixin],
    props: {
        iconClass: String
    }
};
</script>

<template>
    <btn class="btn-link btn-icon" @click="onClicked">
        <icon :name="iconValue" :iconset="iconset" :class="iconClass" />
    </btn>
</template>
