/**
 * Returns an array length.
 * @param {Array} list
 */
import moment from 'moment';

/**
 * Renders a date: 1.03.2019
 * @param value
 * @param format
 * @returns {string}
 */
export function date(value, format = 'DD.MM.YYYY') {
    return moment(value).format(format);
}

/**
 * Renders a datetime: 1.03.2019 12:30
 * @param value
 * @param format
 * @returns {string}
 */
export function datetime(value, format = 'DD.MM.YYYY HH.mm') {
    return moment(value).format(format);
}

/**
 * Calendar time displays time relative to now.
 *
 * Last week    Last Monday at 2:30 AM
 * The day before    Yesterday at 2:30 AM
 * The same day    Today at 2:30 AM
 * The next day    Tomorrow at 2:30 AM
 * The next week    Sunday at 2:30 AM
 * Everything else    7/10/2011
 *
 * @link https://momentjs.com/docs/#/displaying/calendar-time/
 * @param value
 * @returns {*}
 */
export function calendarDate(value, def = '') {
    if (!value) {
        return def;
    }
    return moment(value).calendar();
}

export default { date, datetime, calendarDate };
