/**
 * This module contains router middleware.
 *
 * @link https://router.vuejs.org/en/advanced/navigation-guards.html
 */

import NProgress from 'nprogress';

/**
 * Renders a progress bar above each page while it is loading.
 * @param router
 * @param store
 */
function pageProgressMiddleware(router, store) {
    let progressTimer = null;

    router.beforeEach(async(to, from, next) => {
        progressTimer = setTimeout(f => {
            NProgress.start();
        }, 200);
        next();
    });

    router.afterEach(() => {
        clearTimeout(progressTimer);
        NProgress.done();
    });
}

/**
 * Sets document and page titles if "pageTitle" of route.meta object is set.
 * @param router
 * @param store
 */
function pageTitleMiddleware(router, store) {
    router.beforeEach(async(to, from, next) => {
        if (to.meta.pageTitle) {
            store.dispatch('frontend.current-page.set-title', to.meta.pageTitle);
        }
        next();
    });
}

/**
 * When router cannot reverse current URL it must redirect to dashboard.
 * @param router
 * @param store
 */
function redirectToHomepage(router, store) {
    router.onReady(route => {
        let nextRoute = 'homepage';
        if (!route.name) {
            if (store.state.frontendFramework.currentPage.route) {
                nextRoute = store.state.frontendFramework.currentPage.route;
            }
            router.replace({ name: nextRoute });
            console.warn('No route for %s. Fallback to "%s".', location.href, nextRoute);
        }
    });
}

// export all middlewares as an array. CO.
export default [
    pageProgressMiddleware, pageTitleMiddleware, redirectToHomepage
];
