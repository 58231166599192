<script>
export default {
    props: {
        expandable: { type: Boolean, default: false }
    },
    provide() {
        return {
            listOptions: {
                expandable: this.expandable
            }
        };
    }
};
</script>

<template>
    <div class="m-list">
        <slot></slot>
    </div>
</template>

<style lang="scss" scoped>
.m-list {
    font-size: 16px;
    padding: 8px;
}
</style>
