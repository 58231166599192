<script>
    /**
     * Renders a default page layouts.
     * Should be a root component of every apps's page.
     *
     * Provides itself as a "page" service to children.
     *
     * Usage:
     *  <page-layout>
     *      // content
     *  </page-layout>
     */
    export default {};
</script>

<template>
    <div>
        <card>
            <card-content>
                <slot></slot>
            </card-content>
        </card>
    </div>
</template>
