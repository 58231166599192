import HeaderActions from '../../../components/table/header/HeaderActions';

/**
 * This mixin adds "header actions" feature.
 */
export default {
    props: {
        headerActions: { type: [String, Array], default: () => [] }
    },
    components: {
        HeaderActions
    },
    computed: {
        /**
         * Merges all header actions from props with default action options.
         */
        headerActionsList() {
            return this.headerActions;
        }
    }
};
