<script>
export default {
    props: {
        name: String,
        iconset: { type: String, default: 'md' }
    },
    computed: {
        cssClasses() {
            return `${this.iconset} ${this.iconset}-${this.name}`;
        }
    }
};
</script>

<template>
    <i :class="cssClasses"></i>
</template>
