import { objectKeysToSnake } from './utils';

/**
 * @deprecated
 */
export class HttpParams {
    constructor(params = null, exclude = []) {
        console.log('HttpParams is deprecated. import {defaultHttpClient} from metro-frontend-framework.');
        this.hasUpload = false;
        this.params = {};

        if (params) {
            this.setAll(params, exclude);
        }
    }

    set(name, value) {
        this.params[name] = value;
    }

    setAll(params, exclude = []) {
        for (const key in params) {
            if (exclude.indexOf(key) !== -1) {
                continue;
            }

            if (params[key] !== undefined) {
                this.set(key, params[key]);
            }
        }
    }

    delete(names) {
        if (names.constructor !== Array) {
            names = [names];
        }
        names.map(n => delete this.params[n]);
    }

    setUpload(name, blob) {
        this.hasUpload = true;
        this.set(name, blob);
    }

    toObject() {
        if (this.hasUpload) {
            const data = new FormData();
            const params = objectKeysToSnake(this.params);
            for (const key in params) {
                let value = params[key];
                if (value === null || value === undefined) {
                    value = '';
                }

                if (value.constructor === Array && value.length === 0) {
                    value = [];
                }
                data.append(key, value);
            }
            return data;
        }
        return this.params;
    }
}
