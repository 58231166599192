import toastr from 'toastr';
import 'toastr/toastr.scss';

export default {
    install: function(Vue) {
        toastr.options.closeButton = true;
        toastr.options.positionClass = 'toast-bottom-left';

        Vue.prototype.$toast = toastr;
        Vue.toast = toastr;
    }
};
