<script>
    import CellMixin from './mixins';

    export default {
        mixins: [CellMixin]
    };
</script>
<template>
    <require-permissions :all="permissions">
        <td>
            <slot></slot>
        </td>
    </require-permissions>
</template>
