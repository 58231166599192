/**
 * Truncate string to length.
 *
 * @param {string} text
 * @param {number} length
 * @param {string} ending
 */
export default function truncate(text, length, ending = '...') {
    if (text.length <= length) {
        return text;
    }
    let shoudCheckSpaces = true;
    if (text[length] === ' ') {
        shoudCheckSpaces = false;
    }
    text = text.substr(0, length);
    if (shoudCheckSpaces) {
        text = text.substr(0, text.lastIndexOf(' '));
    }
    return text + ending;
}
