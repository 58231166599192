function processChildren(children, createElement, slotNames) {
    let nodes = Array.from(children);
    let namedSlots = [];

    nodes.forEach((node, index) => {
        if (node && node.tag) {
            const tag = node.componentOptions && node.componentOptions.tag;

            if (tag && slotNames.includes(tag)) {
                node.data.slot = tag;
                node.data.attrs = node.data.attrs || {};
                namedSlots[tag] = () => node;
                nodes.splice(index, 1);
            }
        }
    });

    return {
        childNodes: nodes,
        slots: namedSlots
    };
}

/**
 * Creates a component from `baseComponent` and inserts it's children into name slots.
 *
 * @param baseComponent
 * @param slotNames
 * @returns {*}
 */
export function createScopedComponent(baseComponent, slotNames) {
    return {
        functional: true,
        render(createElement, {data, children, ...rest}) {
            let slotChildren = [];
            let scopedSlots = data.scopedSlots;

            if (children) {
                const {childNodes, slots} = processChildren(children, createElement, slotNames);
                slotChildren = childNodes;
                scopedSlots = {
                    ...scopedSlots, ...slots
                };
            }

            return createElement(baseComponent, {...data, ...rest, scopedSlots}, slotChildren);
        }
    };
}
