<script>
    export default {
        props: {
            color: {
                type: String,
                default: '#F44336'
            },
            size: {
                type: String,
                default: '24px'
            }
        }
    };
</script>
<template>
<div v-bind:style="{ height: size, width: size }" class="spinner">
    <svg class="circular" viewBox="25 25 50 50">
        <circle
                v-bind:style="{ stroke: color }"
                class="path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                stroke-width="2"
                stroke-miterlimit="10"/>
    </svg>
</div>
</template>
<style lang="scss" scoped>
    .spinner {
        display: inline-block;
    }

    .circular {
        animation: rotate 2s linear infinite;
        height: 100%;
        transform-origin: center center;
        width: 100%;
    }

    .path {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
        animation: dash 1.5s ease-in-out infinite;
        stroke-linecap: round;
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes dash {
        0% {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -35px;
        }
        100% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -124px;
        }
    }
</style>
