<script>
    export default {
        name: 'AppMenuAction',
    };
</script>

<template>
    <li class="nav-item nav-item-action">
        <slot></slot>
    </li>
</template>
