<script>
export default {
    computed: {
        cssClasses() {
            const list = ['flex-column'];
            return list;
        }
    }
};
</script>
<template>
<div :class="cssClasses">
    <slot></slot>
</div>
</template>

<style lang="scss" scoped>
.flex-column {
    flex: 1;
}
</style>
