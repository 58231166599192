<script>
export default {
    props: {
        divider: Boolean,
        header: Boolean,
        disabled: Boolean
    },
    computed: {
        cssClasses() {
            return {
                'disabled': this.disabled,
                'divider': this.divider,
                'dropdown-header': this.header
            };
        }
    }
};
</script>

<template>
    <li :class="cssClasses">
        <a v-if="!divider && !header">
            <slot></slot>
        </a>
        <span v-else>
            <slot></slot>
        </span>
    </li>
</template>
