function saveHandler(id, value, row) {
    if (this.inlineEditableOptions.partial) {
        const delta = {};
        delta[id] = value;
        return this.adapter.partialUpdate(row, delta);
    } else {
        return this.adapter.update(row);
    }
}

const DEFAULT_OPTIONS = {
    partial: true,
    handler: saveHandler
};

function getDefaultOptions(current) {
    return Object.assign({}, DEFAULT_OPTIONS, current);
}

export default {
    props: {
        inlineEditable: { type: Object }
    },
    data() {
        return {
            inlineEditableOptions: getDefaultOptions(this.inlineEditable)
        };
    },
    created() {
        this.eventBus.$on('cell.edited', this.onCellEdited);
    },
    methods: {
        async onCellEdited(id, value, row) {
            console.log('cell edited');
            row[id] = value;
            try {
                await this.inlineEditableOptions
                    .handler
                    .bind(this)
                    .call(this, id, value, row);
            } catch (e) {
                console.error(e);
            }
        }
    }
};
