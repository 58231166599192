<script>
import BaseMixin from './mixins/base';

export default {
    mixins: [BaseMixin]
};
</script>

<template>
    <input
        class="form-control"
        ref="field"
        type="email"
        :disabled="disabled"
        :name="name"
        :placeholder="placeholder"
        :readonly="readonly"
        :required="required"
        :value="value"
        autocomplete="email"
        @blur="onBlur"
        @focus="onFocus"
        @change="onChange"
        @input="onInput"
        />
</template>
