<script>
    /**
     * A component to render a main content area of every page.
     *
     * A content component must be set via router.
     *
     * Deprecated behavior:
     *  a). component from prerendered html
     */
    export default {
        computed: {
            contentComponent() {
                if (this.$store.state.frontendFramework) {
                    return this.$store.state.frontendFramework.currentPage.pageComponent;
                }
            },
            hasCurrentRoute() {
                return this.$route.name !== null;
            },
            pageRoute() {
                if (this.$store.state.frontendFramework) {
                    return this.$store.state.frontendFramework.currentPage.route;
                }
            },
            useSPAContent() {
                return (this.contentComponent || this.pageRoute);
            }
        }
    };
</script>

<template>
    <div class="app-content">
        <div class="app-content-inner">
            <div class="container-fluid">
                <slot>
                    <content-layout>
                        <router-view></router-view>
                    </content-layout>
                </slot>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .app-content {
        flex: 1 1 auto;
        overflow-y: auto;

        .app-content-inner {
            margin: 40px;

            @media(max-width: 768px) {
                margin: 0;
                margin-top: 24px;
            }
        }
    }
</style>
