<script>
/**
 * Renders table row.
 *
 * The reason of having this component is to improve table
 * outline in chrome devtools.
 *
 * NOTE: This component disables pointer cursor style which was initially added
 * by ".table-full tbody > tr >td" rule of materialism.css.
 */

import CellWrapper from './CellWrapper';

export default {
    props: {
        cols: {
            type: Array,
            required: true
        },
        row: {
            type: Object,
            required: true
        },
        tableOptions: {
            type: Object,
            required: true
        }
    },
    components: {
        CellWrapper
    },
    computed: {
        rowClass() {
            return this.resolveCssClass();
        }
    },
    methods: {
        resolveCssClass() {
            if (typeof this.tableOptions.rowOptions.cssClass === 'function') {
                return this.tableOptions.rowOptions.cssClass(this.row);
            }
            return this.tableOptions.rowOptions.cssClass;
        }
    }
};
</script>

<template>
    <tr v-bind:class="rowClass">
        <td
            v-for="col in cols"
            v-bind:key="col.id"
            v-bind:class="col.cssClasses"
            v-bind:styles="col.inlineStyles"
            v-bind:column="col.id"
            >
            <cell-wrapper v-bind:row="row" v-bind:col="col"></cell-wrapper>
        </td>
    </tr>
</template>

<style lang="scss" scoped>
tr > td {
    cursor: default !important;
    padding: 3px;
}
</style>
