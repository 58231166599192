export const ReadFileMode = {
    DATA_URL: 'dataurl',
    ARRAY_BUFFER: 'arraybuffer',
    BINARY_STRING: 'binarystring',
    TEXT: 'text'
};

/**
 * Reads a file provided by input[type=file] into data url string.
 *
 * @link https://developer.mozilla.org/en-US/docs/Web/API/File
 */
export default (file, mode = ReadFileMode.DATA_URL) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = e => {
            resolve(e.target.result);
        };
        reader.onerror = reject;
        switch (mode) {
            case ReadFileMode.DATA_URL:
                reader.readAsDataURL(file);
                break;
            case ReadFileMode.ARRAY_BUFFER:
                reader.readAsArrayBuffer(file);
                break;
            case ReadFileMode.BINARY_STRING:
                reader.readAsBinaryString(file);
                break;
            case ReadFileMode.TEXT:
                reader.readAsText(file);
                break;
            default:
                throw new Error('Unknown read mode: ' + mode);
        }
    });
};
