<script>
    export default {
        name: 'Icon',
        props: {
            size: {type: String, default: 'md'},
            name: {type: String, required: true},
            rotate: {type: [Number, String], default: 0},
        },
        computed: {
            cssClasses() {
                if(!this.size) return;
                return `icon-${this.size}`;
            },
            styles() {
                let styles = {};
                if(this.rotate) {
                    styles['transform'] = `rotate(${this.rotate}deg)`;
                }
                return styles;
            },
            svgSrc() {
                return require('!!raw-loader!./../icons/' + this.name + '.svg').default;
            },
        },
        methods: {
            onClicked(e) {
                this.$emit('click', e);
            }
        }
    };
</script>

<template>
    <div class="icon align-center flex justify-center items-center"
         :class="cssClasses" :style="styles" v-html="svgSrc" @click="onClicked">
    </div>
</template>

<style lang="scss">
    .icon {
        transition: transform .2s;
    }

    .icon-auto {
        min-height: 8px;
        min-width: 8px;
    }

    .icon-xl {
        // 40px
        @apply h-10 w-10;
    }

    .icon-lg {
        // 32px
        @apply h-8 w-8;
    }

    .icon-md {
        // 24px
        @apply h-6 w-6;
    }

    .icon-sm {
        // 16px
        @apply h-4 w-4;
    }
</style>
