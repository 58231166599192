import Vue from 'vue';

Vue.component('theme-layout', require('./Layout').default);
Vue.component('app-header', require('./AppHeader').default);
Vue.component('app-menu', require('./AppMenu').default);
Vue.component('app-menu-item', require('./AppMenuItem').default);
Vue.component('progress-bar', require('./ProgressBar').default);

import * as menus from './menu';


Object
    .entries(menus)
    .forEach(([name, component]) => Vue.component(name, component));
