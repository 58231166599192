<script>
    /**
     * A scroll layout.
     *
     * The component will render scroll bar if content's height is larger that [max-height].
     *
     * Usage:
     *  <scroll-layout max-height="500px">
     *      <div>total height of children must be larger than 500px to trigger scrollbars.</div>
     *  </scroll-layout>
     */

    import PerfectScrollbar from 'perfect-scrollbar';
    import 'perfect-scrollbar/css/perfect-scrollbar.css';

    export default {
        props: {
            maxHeight: {
                type: String, default: '500px'
            }
        },
        data() {
            return {
                ps: null
            };
        },
        updated() {
            this.ps.update();
        },
        mounted() {
            this.$nextTick(f => {
                this.ps = new PerfectScrollbar(this.$el);
            });
        },
        destroyed() {
            this.ps.destroy();
            this.ps = null; // force GC
        }
    };
</script>

<template>
    <div ref="area" class="scroll-area" :style="{'max-height': maxHeight}">
        <slot></slot>
    </div>
</template>

<style scoped lang="scss">
    .scroll-area {
        overflow: hidden;
        position: relative;

        .dropdown-menu {
            top: 0;
        }
    }
</style>
