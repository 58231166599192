<script>
    export default {
        props: {
            href: String,
            image: String
        }
    };
</script>

<template>
    <div class="brand-logo pull-left">
        <a :href="href">
            <img :src="image">
        </a>
    </div>
</template>


<style lang="scss" scoped>
    .brand-logo {
        @media(max-width: 768px) {
            display: none;
        }
    }
</style>
