<script>
    import FieldValue from './TranslatedFieldValue';

    export default {
        props: {
            app: { required: true, type: Object },
            admin: { required: true, type: Object },
        },
        provide() {
            return {
                crudListView: this
            };
        },
        components: {
            FieldValue
        },
        computed: {
            dataSource() {
                return this.admin.getListPage().getDataSource();
            },
            columns() {
                return this.listPage.getTableMapping();
            },
            headerActions() {
                return this.admin.getListPage().getHeaderActions();
            },
            rowActions() {
                return this.admin.getListPage().getRowActions();
            },
            createButtonLabel() {
                return this.admin.getListPage().getCreateButtonLabel();
            },
            editPermissions() {
                return this.admin.getEditPage().getPermissions();
            },
            deletePermissions() {
                return this.admin.getDeletePage().getPermissions();
            },
            listPage() {
                return this.admin.getListPage();
            },
            editPage() {
                return this.admin.getEditPage();
            },
            deletePage() {
                return this.admin.getDeletePage();
            }
        },
        created() {
            this.$store.dispatch('frontend.current-page.set-title', this.admin.getListPage().getTitle());
        },
        methods: {
            editRoute(row = null) {
                const route = {
                    name: this.admin.getEditPage().getRouteName(row)
                };
                if (row) {
                    route['params'] = { id: row.id };
                }
                return route;
            },
            onDeleteError(err) {
                if ('response' in err) {
                    const messages = err.response.data.messages.map(msg => this.$t(msg));
                    this.$alertify.success(messages);
                } else {
                    console.error('ERROR', err);
                    this.$alertify.error(err);
                }
            }
        }
    };
</script>
<template>
    <m-table :source="dataSource" :searchable="listPage.isSearchable" ref="table" @delete-error="onDeleteError">

        <m-table-header-actions>
            <component :is="ha" :key="ha.cid" v-for="ha in headerActions"></component>

            <template v-if="admin.allowEdit && admin.allowCreate">
                <require-permissions :all="editPermissions">
                    <component :is="listPage.createButtonComponent" :route="editRoute()" exact>
                        {{ createButtonLabel|trans }}
                    </component>
                </require-permissions>
            </template>
        </m-table-header-actions>

        <m-table-row-template slot="row" slot-scope="{ row }">
            <m-table-cell
                    v-for="col in columns"
                    :key="col.name"
                    :sortable="col.sortable"
                    :label="$t(col.label)"
                    :field="col.name"
                    :row="row"
            >

                <component v-if="col.component" :admin="admin" :is="col.component" :column="col" :row="row"></component>
                <field-value v-else :admin="admin" :row="row" :col="col"></field-value>

            </m-table-cell>

            <m-table-cell-actions>
                <component :is="ra" :key="ra.cid" v-for="ra in rowActions" :row="row"></component>

                <template v-if="admin.allowEdit">
                    <require-permissions :all="editPermissions">
                        <component :row="row" :is="listPage.editButtonComponent" :route="editRoute(row)"/>
                    </require-permissions>
                </template>

                <require-permissions :all="deletePermissions" v-if="admin.allowDelete">
                    <component :is="listPage.deleteButtonComponent" :row="row"/>
                </require-permissions>
            </m-table-cell-actions>

        </m-table-row-template>
    </m-table>
</template>
