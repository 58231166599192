<script>
    /**
     * Renders a drop area.
     * The area accepts file drops and let choose file.
     */
    export default {
        props: {
            decorated: Boolean
        },
        data() {
            return {
                isActive: false
            };
        },
        computed: {
            cssClasses() {
                return {
                    droparea: true,
                    decorated: this.decorated,
                    active: this.isActive
                };
            }
        },
        methods: {
            onDragEnd() {
                this.isActive = false;
            },
            onDragOver(e) {
                if (e.dataTransfer.types.indexOf('Files') !== -1) {
                    this.isActive = true;
                }
            },
            onDrop(e) {
                this.onDragEnd();
                const transfer = e.dataTransfer;
                if (transfer.items) {
                    for (const item of transfer.items) {
                        if (item.kind === 'file') {
                            this.onFileSelected(item.getAsFile());
                        }
                    }
                } else {
                    for (const item of transfer.files) {
                        this.onFileSelected(item);
                    }
                }
            },
            onFileSelected(files) {
                const _files = [];
                if (files instanceof FileList) {
                    for (const item of files) {
                        _files.push(item);
                    }
                }

                if (files instanceof File) {
                    _files.push(files);
                }
                this.$emit('drop', _files);
            }
        }
    };
</script>

<template>
    <div :class="cssClasses"
         @dragover.prevent="onDragOver"
         @dragend.prevent="onDragEnd"
    >
        <slot>
            {{ 'Drop files here to upload or'|trans }}
            <file-field @input="onFileSelected"></file-field>
        </slot>
        <div class="overlay" @dragleave="onDragEnd" @drop.prevent.stop="onDrop">
            {{ 'Drop files to upload'|trans }}
        </div>
    </div>
</template>

<style scoped lang="scss">
    .droparea {
        border: 2px solid white;
        position: relative;

        .overlay {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            display: none;
            background: white;
            text-align: center;
            font-size: 22px;
            color: #555;
        }

        &.active {
            border-style: dashed;
            border-color: red;

            .overlay {
                display: flex;
                align-items: center;
                justify-content: center;
                background: rgba(255, 255, 255, .8);
            }
        }

        &.decorated {
            border-style: dashed;
            border-color: #909090;
            padding: 16px;

            &.active {
                border-color: blue;
            }
        }
    }
</style>
