<script>
import IconProps from '../../mixins/icon-props';

export default {
    mixins: [IconProps],
    name: 'tab',
    props: {
        label: String,
        active: Boolean,
        disabled: Boolean,
        route: { type: Object, required: false, default: null }
    },
    inject: ['tabs'],
    data() {
        return {
            uid: null,
            isActive: this.active
        };
    },
    created() {
        this.uid = `tab_${Math.random()}`;
    },
    mounted() {
        this.tabs.addTab(this);
    },
    destroyed() {
        this.tabs.removeTab(this);
    },
    computed: {
        cssClasses() {
            return {
                'tab-pane': true,
                active: this.isActive
            };
        }
    },
    methods: {
        getId() {
            return this.uid;
        },
        activate() {
            this.isActive = true;
            if (this.route) {
                this.$router.push(this.route);
            }
        },
        deactivate() {
            this.isActive = false;
        }
    }
};
</script>
<template>
    <div :class="cssClasses" v-if="isActive">
        <slot></slot>
    </div>
</template>

<style lang="scss" scoped>
.tab-pane {
    padding-top: 24px;
}
</style>
