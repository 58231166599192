<script>
/**
 * Wraps a cell and abstracts edit functionality.
 */

export default {
    props: {
        col: { type: Object, required: true },
        row: { type: Object, required: true }
    },
    data() {
        return {
            editMode: false
        };
    },
    inject: ['eventBus'],
    computed: {
        value() {
            return this.col.getValue(this.row);
        }
    },
    methods: {
        onClicked(e) {
            if (this.col.editable) {
                this.editMode = true;
            }
        },
        onCellValueChanged(id, value, row) {
            this.editMode = false;

            // pass event to the root table component
            this.eventBus.$emit('cell.edited', id, value, row);
        },
        onCellReset(col, row) {
            this.editMode = false;

            // pass event to the root table component
            this.eventBus.$emit('cell.edit-cancelled', col, row);
        },
        onKeyUp(e) {
            if (e.keyCode === 27) { // ESC
                this.editMode = false;
            }
        }
    }
};
</script>
<template>
<div v-on:click="onClicked" v-on:keyup="onKeyUp" v-bind:class="{editable: col.editable}">
    <component
        ref="child"
        v-on:cell-edit-save="onCellValueChanged"
        v-on:cell-edit-cancel="onCellReset"
        v-bind:is="col.component"
        v-bind:edit-mode="editMode"
        v-bind:row="row"
        v-bind:column="col"
        v-bind:options="col.typeOptions"
        v-bind:event-bus="eventBus"
        v-bind:value="value"
        >
    </component>
</div>
</template>

<style lang="scss" scoped>
.editable {
    cursor: pointer;
}
</style>
