<script>
export default {
    methods: {
        onClicked() {
            this.$emit('click');
        }
    }
};
</script>

<template>
    <button type="button" class="close" @click="onClicked">
        <span>&times;</span>
    </button>
</template>

<style scoped>
.close {
    cursor: pointer;
}
</style>

