import CellValue from './CellValue';

export const CellMixin = {
    name: 'GenericCell',
    isCell: true,
    inject: ['table'],
    props: {
        label: String,
        sortable: Boolean,
        editable: Boolean,
        permissions: [Array, String],
        field: String,
        row: Object
    },
    components: {CellValue},
    created() {
        if (this.editable || this.sortable) {
            if (!this.field || !this.row) {
                throw new Error('A cell with "editable" or "sortable" property requires "field" and "row" props to be set.');
            }
        }
    },
    methods: {
        onEdited(...args) {
            this.table.onCellEdited(...args);
        }
    }
};

export default CellMixin;
