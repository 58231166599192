<script>
    import MenuItem from './MenuItem';

    export default {
        components: { MenuItem },
        computed: {
            tenant() {
                return this.$store.getters['auth/tenant'];
            },
            menuItems() {
                return this.$services.get('main_menu').all();
            },
            isVisible() {
                return this.tenant !== null && this.$route.name !== 'homepage';
            }
        },
        methods: {
            path(r) {
                // keep this until we are ready for history based routing mode
                r.query = r.query || {};
                r.query.tenant = this.tenant.id;
                return this.$router.resolve(r).href.replace(/^#/, '');
            }
        }
    };
</script>
<template>
    <div class="app-menu" v-if="isVisible">
        <require-permissions :all="item.permissions" v-for="item in menuItems" :key="item.route.name">
            <menu-item
                    :href="path(item.route)"
                    :icon="item.icon"
                    :title="$t(item.label)">
            </menu-item>
        </require-permissions>
    </div>
</template>

<style lang="scss" scoped>
    .app-menu {
        flex: 1;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 64px;
        overflow-y: auto;
        padding-top: 24px;
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
        border-right: 1px solid rgba(100, 100, 100, 0.1);

        > div {
            border-bottom: 1px solid rgba(100, 100, 100, 0.1);

            &:last-child {
                border-bottom: none;
            }
        }

        @media(max-width: 768px) {
            display: none;
        }
    }
</style>

<style lang="scss">
    .app-menu {
        background: white;
    }
</style>

