<script>
/**
 * Radio group.
 */
export default {
    name: 'RadioGroup',
    props: {
        inline: Boolean,
        value: { default: null }
    },
    data() {
        return {
            localValue: this.value
        };
    },
    created() {
        this.useFixedLabel = true;
        this.isInput = true;
    },
    mounted() {
        // look for radio inputs in children and subscribe to their input event
        this.$children
            .filter(c => (c.$options.name === 'RadioField'))
            .map(child => {
                child.$on('input', this.onInput);
                child.setInline(this.inline);
            });
    },
    methods: {
        /**
         * Handle "input" event from children.
         */
        onInput(value) {
            this.localValue = value;
        },

        /**
         * Overwrite local value with new one.
         */
        setValue(value) {
            this.localValue = value;
        }
    },
    watch: {
        value(value) {
            this.localValue = value;
        },
        localValue() {
            this.$emit('input', this.localValue);
        }
    }
};
</script>

<template>
    <div>
        <slot></slot>
    </div>
</template>
