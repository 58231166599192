<script>
    import PageNavigation from './PageNavigation';

    export default {
        props: {
            app: {required: true, type: Object},
            admin: {required: true, type: Object},
        },
        components: {PageNavigation},
        provide() {
            return {
                adminListLayoutView: this
            };
        },
        computed: {
            pages() {
                return [this.admin.getListPage(), ...this.admin.getListPage().getCustomPages()];
            }
        }
    };
</script>
<template>
    <page-layout>
        <page-navigation :pages="pages"></page-navigation>
        <router-view :key="$route.fullPath"></router-view>
    </page-layout>
</template>
