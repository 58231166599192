/**
 * A base app config class.
 * Must not be used directly -- subclass it.
 */
export class BaseApp {
    constructor(name, order = 10) {
        this.name = name;
        this.order = order;
        this.admins = {};
        this.adminModifiers = [];
        this.navbarActions = [];
        this.menu = [];
    }

    /**
     * Called first to let app initialize itself.
     * @param context
     */
    onInit(context) {
        // base
    }

    /**
     * Called right after onInit.
     * At this moment all apps are initialized and can access each other's data.
     * Do here a default configuration (routes, store, etc)
     * @param context
     */
    onPostInit(context) {
        // base
    }

    /**
     * Called first per app.
     * @param context
     */
    onBootstrap(context) {
        // base
    }

    /**
     * Called when root view is created but not yet mounted.
     * @param context
     */
    onViewCreated(context) {
        // base
    }

    /**
     * Called when root view mounted.
     * @param context
     */
    onViewMounted(context) {
        // base
    }

    /**
     * Returns a store module as a JSON object.
     *
     * To be overridden by child app config.
     */
    getStoreModule() {
        return {};
    }

    /**
     * Returns a route config for app.
     *
     * To be overridden by child app config.
     */
    getRoutes() {
        return [];
    }

    /**
     * Returns an array of menu items.
     * The format of a menu item is:
     *
     *   label: String -- an item text
     *   icon: String -- an item icon (eg. fa fa-user)
     *   route: String|Object -- a route name of route location object
     *   permissions: Array -- a list of persmissions required to show the item
     *   order: number -- default: 10, a position in the menu
     * @returns {Array}
     */
    getMenuItems() {
        return this.menu;
    }

    /**
     * Returns a list of navigation bar items.
     * The format of an item is: {component: VueComponent, order: number}
     * @returns {Array}
     */
    getNavbarActions() {
        return this.navbarActions;
    }

    registerAdmin(admin) {
        this.admins[admin.name] = admin;
    }

    /**
     * Returns particular admin by its name.
     * @param name
     * @returns {*}
     */
    getAdmin(name) {
        return this.admins[name] || null;
    }

    /**
     * Returns a list of registered admins.
     * @returns {Array}
     */
    getAdmins() {
        return this.admins;
    }

    getAdminsArray() {
        return Object.values(this.getAdmins());
    }

    /**
     * Returns a list of registered admin modifiers.
     * @returns {Array}
     */
    getAdminModifiers() {
        return this.adminModifiers;
    }
}
