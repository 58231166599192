<script>
/**
 * DateTime cell.
 *
 * Renders a string.
 * Renders a <datepicker /> when in edit mode.
 *
 * See ColumnMixin for more docs.
 */

import ColumnMixin from './ColumnMixin';

export default {
    mixins: [ColumnMixin],
    name: 'DateTimeCell',
    data() {
        return {
            dpOptions: {
                format: this.column.typeOptions.outputFormat
            }
        };
    },
    computed: {
        model: {
            get() {
                return this.formatted;
            },
            set(val) {
                this.val = val;
            }
        }
    }
};
</script>

<template>
    <div v-if="editing">
        <date-picker v-bind:config="dpOptions" v-model="model" ref="input"></date-picker>

        <component
            v-bind:is="editableToolbar"
            v-bind:config="editableToolbarConfig"
            v-on:accepted="submit"
            v-on:rejected="reset"
            >
        </component>

    </div>
    <span v-else>{{ formatted }}</span>
</template>
