import {AbstractDataSource} from './AbstractDataSource';

/**
 * A resource datasource.
 *
 * Works with REST.Resource object.
 */
export class ResourceDataSource extends AbstractDataSource {
    constructor(resource, ...args) {
        super(...args);
        this.resource = resource;
    }
    async edit(row, delta) {
        return this.resource.partialUpdate(row.id, delta);
    }

    async insert(row) {
        return this.resource.create(row);
    }

    async delete(row) {
        return this.resource.delete(row.id);
    }

    async doFetch() {
        let page = Math.max(this.page, 0);

        let ordering = '';
        if (this.sortField && this.sortOrder) {
            ordering = (this.sortOrder === 'desc' ? '-' : '') + this.sortField;
        }

        const { data } = await this.resource.all({
            params: {
                search: this.searchQuery,
                ordering: ordering,
                page: page,
                size: this.pageSize
            }
        });

        this.rows = data.items;
        this.totalRows = data.total;
        return this.notify();
    }
}
