<script>
    /**
     * A route link for menu.
     * Renders a link which accepts a route object.
     *
     * Usage:
     *
     * <nav-menu>
     *      <nav-route-item :route [:disabled] [:replace]>Link</nav-route-item>
     * </nav-menu>
     */
    export default {
        props: {
            // a route object
            route: Object,

            // when activated, current page will be replaced with new route
            replace: Boolean,

            // do not act on click
            disabled: Boolean
        },
        computed: {
            cssClasses() {
                const list = ['waves-effect'];
                if (this.disabled) {
                    list.push('disabled');
                }
                return list;
            }
        },
        methods: {
            onClicked(e) {
                if (!this.disabled) {
                    if (this.replace) {
                        this.$router.replace(this.route);
                    } else {
                        this.$router.push(this.route);
                    }
                }
            }
        }
    };
</script>

<template>
    <router-link :class="cssClasses" :to="route" @click.native.prevent="onClicked" event="never">
        <slot></slot>
    </router-link>
</template>

<style lang="scss" scoped>
    .disabled {
        color: #bbb;

        &:hover {
            background: 0;
            cursor: not-allowed;
        }
    }
</style>
