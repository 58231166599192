<script>
    import Icon from '../Icon';

    export default {
        name: 'ListMenuLink',
        components: {Icon},
        props: {
            active: Boolean,
            to: {type: Object, required: true},
        },
    };
</script>

<template>
    <li tabindex="-1" class="list-menu-item list-menu-link" :class="{active}">
        <div class="flex-grow">
            <router-link :to="to" class="flex-1 block">
                <slot></slot>
            </router-link>
        </div>
    </li>
</template>

<style scoped lang="scss">
    .list-menu-item {
        @apply bg-white text-sm font-regular cursor-pointer flex items-center select-none;

        a {
            @apply px-3 py-2 text-black;
        }
    }

    .list-menu-item:hover {
        @apply bg-gray-100;
    }

    .list-menu-item.active {
        @apply bg-blue-500 text-white;
    }
</style>
