import { makeTitle } from '../utils';

export class Page {
    constructor(admin, name, component, title = null, permissions = []) {
        this.admin = admin;
        this.name = name;
        this.title = title || makeTitle(name);
        this.component = component;
        this.customPages = [];
        this.permissions = permissions;
        this.parent = null;
        this.disabled = false;
    }

    get app() {
        return this.admin.app;
    }

    setComponent(component) {
        this.component = component;
        return this;
    }

    getComponent(component) {
        return this.component;
    }

    setTitle(title) {
        this.title = title;
        return this;
    }

    getTitle() {
        return this.title;
    }

    setPermissions(permissions) {
        this.permissions = permissions;
        return this;
    }

    getPermissions() {
        return this.permissions;
    }

    addCustomPage(page) {
        if (page.constructor !== Page) {
            page.admin = this.admin;
            page = Page.fromObject(page);
        }
        page.setParent(this);
        this.customPages.push(page);
        return this;
    }

    getCustomPages() {
        return this.customPages;
    }

    setParent(parent) {
        this.parent = parent;
    }

    setDisabled(flag) {
        this.disabled = flag;
    }

    isDisabled(context) {
        return this.disabled;
    }

    getRouteName() {
        const parts = [this.admin.app.name, this.admin.name];
        if (this.parent) {
            const parentRouteName = this.parent.getRouteName().split('-').filter(p => !parts.includes(p));
            parts.push(...parentRouteName);
        }
        parts.push(this.name);
        return this.admin.generateRouteName(...parts);
    }

    getRoutePath() {
        return this.name;
    }

    getRoutes(parent = null) {
        if (!parent) {
            parent = this.parent;
        }
        return {
            name: this.getRouteName(),
            path: this.getRoutePath(),
            component: this.getComponent(),
            props: {
                admin: this.admin,
                app: this.admin.app,
                page: this,
                parent: this.parent,
            },
            meta: {
                pageTitle: this.getTitle(),
                parentRoute: parent ? parent.getRouteName() : null,
            }
        };
    }

    /**
     * Create page object from JS object template.
     * @param obj
     * @returns {Page}
     */
    static fromObject(obj) {
        const page = new Page(obj.admin, obj.name, obj.component, obj.title || '', obj.permissions || []);
        page.disabledCheck = obj.disabledCheck || (() => false);
        return page;
    }
}

export default Page;
