<script>
export default {
    props: {
        cols: { type: Array, default: f => [] }
    }
};
</script>

<template>
    <tr>
        <td v-for="c in cols" :key="c"> {{ c }} </td>
    </tr>
</template>
