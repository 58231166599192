<script>
    export default {
        inject: ['appLayout'],
        computed: {
            hasSidebar() {
                return (
                    this.$route.matched[0] &&
                    this.$route.matched[0].components &&
                    this.$route.matched[0].components.sidebar
                );
            },
            cssClasses() {
                return {
                    'app-sidebar': true
                };
            }
        }
    };
</script>

<template>
    <aside :class="cssClasses" v-if="hasSidebar">
        <router-view name="sidebar"></router-view>
    </aside>
</template>

<style lang="scss" scoped>
    .app-sidebar {
        flex: 1;
        flex-basis: 260px;
        flex-grow: 0;
        flex-shrink: 0;
        padding: 8px;
        padding-top: 24px;
        padding-bottom: 24px;
        background: white;
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
        border-right: 1px solid rgba(100, 100, 100, 0.1);
        overflow: auto;

        @media(max-width: 768px) {
            display: none;
        }
    }
</style>
