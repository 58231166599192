<script>
export default {
    props: {
        src: String,

        placeholder: [String, Boolean]
    },
    computed: {
        source() {
            if (!this.src) {
                return this.placeholder;
            }
            return this.src;
        }
    }
};
</script>

<template>
<div class="preview" v-if="source">
    <img ref="image" :src="source" crossorigin="anonymous" alt="anonymous" />
</div>
</template>

<style scoped lang="scss">
.preview {
    margin: 4px 0;
    text-align: center;
    background: #F1EFF0;

    img {
        max-width: 100%;
        max-height: 426px;
        width: auto;
        object-fit: contain;
    }
}
</style>
