import { AbstractDataSource } from './AbstractDataSource';

/**
 * A store datasource.
 *
 * Reads and manipulates data from Vuex store.
 *
 * Usage:
 * define store actions:
 * - <module>.update
 * - <module>.create
 * - <module>.delete
 *
 * where module is a string prefix of action name.
 * for example, with given module "tasks" then actions will be: tasks.update, tasks.create, .etc
 */
export class StoreDataSource extends AbstractDataSource {
    constructor(store, module, ...args) {
        super(...args);
        this.store = store;
        this.module = module;
    }

    async edit(row, delta) {
        const action = this.module + '.' + 'update';
        await this.store.dispatch(action, { row, delta });
        return this.update();
    }

    async insert(row) {
        const action = this.module + '.' + 'create';
        await this.store.dispatch(action, row);
        return this.update();
    }

    async delete(row) {
        const action = this.module + '.' + 'delete';
        await this.store.dispatch(action, row);
        return this.update();
    }

    async doFetch() {
        let page = Math.max(this.page, 0);

        let ordering = '';
        if (this.sortField && this.sortOrder) {
            ordering = (this.sortOrder === 'desc' ? '-' : '') + this.sortField;
        }

        const action = this.module + '.' + 'list';
        const {data} = await this.store.dispatch(action, {
            search: this.searchQuery,
            ordering: ordering,
            page: page,
            size: this.pageSize
        });

        this.rows = data.items;
        this.totalRows = data.total;
        return this.notify();
    }

    async filter(search) {
        this.searchQuery = search;
        this.update();
    }
}

export default StoreDataSource;
