import EditView from '../components/EditView';
import EditLayoutView from '../components/EditLayoutView';
import { loadObject } from '../guards';
import { Page } from './page';

/**
 * Manages edit page.
 */
export class EditPage extends Page {
    constructor(...args) {
        super(...args);

        // the page component (excludes tabbed navigation)
        this.component = EditView;

        // a list of extra buttons next to "Save" button
        this.formActions = [];

        // a component to render the form.
        // this component receives "object" and reactively synced "formData" props.
        this.formComponent = null;

        // a page title to set when used edits unsaved document
        this.createItemPageTitle = 'Create an item';

        // a page title to set when used edits existing document
        this.editItemPageTitle = 'Edit an item';

        // when true, user will be asked to confirm navigation to other route if form data is not saved.
        this.confirmRouteLeave = true;

        // a message for "confirmRouteLeave"
        this.routeLeaveConfirmation = 'You have unsaved changes, continue?';

        // unused. stubs for future form generator
        this.fieldSets = [];
        this.formColumns = [{ name: 'main', weight: 12 }];
    }

    setFormComponent(component) {
        this.formComponent = component;
        return this;
    }

    getFormComponent() {
        if (this.formComponent) {
            return this.formComponent;
        }
    }

    setFormColumns(formColumns) {
        this.formColumns = formColumns;
        return this;
    }

    setFieldSets(fieldSets) {
        this.fieldSets = fieldSets;
        return this;
    }

    addFormAction(action) {
        this.formActions.push(action);
        return this;
    }

    setConfirmRouteLeave(flag = true, message = null) {
        this.confirmRouteLeave = flag;
        if (message) {
            this.routeLeaveConfirmation = message;
        }
        return this;
    }

    setPageTitles(editTitle = null, createTitle = null) {
        if (editTitle) {
            this.editItemPageTitle = editTitle;
        }

        if (createTitle) {
            this.createItemPageTitle = createTitle;
        }
        return this;
    }

    /**
     * Overrides super method.
     *
     * This methods sets default "disabledCheck" to page if it has not defined it.
     * The default check validates object's id to be a number and be greater than zero.
     * If check fails, the page tab is disabled.
     *
     * @param page
     * @returns {*}
     */
    addCustomPage(page) {
        if (!page.disabledCheck) {
            page.disabledCheck = ({ object }) => {
                return isNaN(parseInt(object.id));
            };
        }
        return super.addCustomPage(page);
    }

    getRouteName() {
        return this.admin.generateRouteName(this.admin.app.name, this.admin.name, this.name);
    }

    getRoutePath() {
        return this.admin.generateRoutePath('edit/:id?');
    }

    getRoutes({ store }) {
        const parent = this.admin.getListPage();
        const props = route => {
            return {
                id: route.params.id,
                admin: this.admin,
                app: this.admin.app,
                page: this,
                parent: parent,
            };
        };

        return {
            path: this.getRoutePath(),
            component: EditLayoutView,
            props: props,
            beforeEnter: loadObject(this.admin, store),
            children: [
                {
                    name: this.getRouteName(),
                    path: '',
                    component: this.getComponent(),
                    props: props,
                    meta: {
                        parentRoute: this.admin.getListPage().getRouteName(),
                    }
                },
                ...this.getCustomPages().map(page => page.getRoutes(parent))
            ]
        };
    }
}
