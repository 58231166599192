<script>
/**
 * A header cell.
 */

import SortIcon from './SortIcon';

export default {
    props: {
        col: { required: true }
    },
    inject: ['eventBus'],
    data() {
        return {
            sortDir: null
        };
    },
    components: {
        SortIcon
    },
    created() {
        this.eventBus.$on('sort-set-rule', this.onSetRuleByOther);
    },
    methods: {
        onSetRuleByOther(col, dir) {
            if (col.id !== this.col.id) {
                this.deselect();
            }
        },
        switchDir() {
            if (!this.sortDir) {
                this.sortDir = 'desc';
            }
            return this.sortDir === 'asc' ? 'desc' : 'asc';
        },
        deselect() {
            this.sortDir = null;
            this.eventBus.$emit('sort-deselect', this.col);
        },
        onCellClicked(e) {
            if (this.col.sortable) {
                if (e.ctrlKey) {
                    this.deselect();
                } else {
                    this.sortDir = this.switchDir();
                    this.eventBus.$emit('sort-set-rule', this.col, this.sortDir);
                }
            }
        }
    }

};
</script>

<template>
    <th v-bind:column="col.id" scope="col">
        <span v-bind:class="{sortable: col.isSortable}" v-on:click="onCellClicked">
            {{ col.label }}
            <sort-icon v-bind:dir="sortDir" v-if="col.isSortable"></sort-icon>
        </span>
    </th>
</template>

<style lang="scss" scoped>
th {
    white-space: nowrap;

    span {
        user-select: none;
    }
}
.sortable {
    cursor: pointer;
}
</style>
