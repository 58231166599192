<script>
export default {
    props: {
        file: String
    },
    computed: {
        source() {
            const txt = require('!!raw-loader!' + this.file + '.vue');
            return txt;
        }
    }
};
</script>

<template>
    <card>
        <card-content>
            <pre v-highlightjs="source"><code class="html"></code></pre>
        </card-content>
    </card>
</template>
