import {defaultHttpClient, httpClient} from '../http/client';

/**
 * Intergrate Vue with default http client.
 */
export default {
    install: (Vue) => {
        Vue.http = httpClient;
        Vue.prototype.$http = httpClient;
        Vue.prototype.$httpClient = defaultHttpClient;
    }
};
