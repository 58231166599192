
export default {
    created() {
        this.eventBus.$on('row.delete', this.onRowDeleteRequested);
        this.eventBus.$on('row.update', this.onRowUpdateRequested);
    },
    methods: {
        async onRowDeleteRequested(row) {
            let response = await this.deleteRow(row);
            if (!response.success) {
                this.$alertify.error(response.getFlattenedMessages());
                return;
            }
            await this.reload();
        },
        async deleteRow(row) {
            return this.adapter.delete(row);
        },
        async onRowUpdateRequested(row) {
            const response = await this.updateRow(row);
            if (!response.success) {
                this.$alertify.error(response.getFlattenedMessages());
            }
        },
        async updateRow(row) {
            return this.adapter.update(row);
        }
    }
};
