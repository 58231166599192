<script>
    export default {
        props: {
            active: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                isActive: false
            };
        },
        computed: {
            isDisabled() {
                return this.isActive || this.disabled;
            }
        },
        methods: {
            /**
             * Forwards click event.
             * @param e
             */
            onClick(e) {
                this.$emit('click', e);
            },

            /**
             * @deprecated use handleAsync() instead.
             */
            async wrap(promise) {
                console.warn('deprecated: <action-button> do not use "wrap". call handleAsync instead.');
                return this.handleAsync(promise);
            },

            /**
             * Async wrapper for promises.
             * Activates or deactivates button using promise state.
             *
             * @param promise
             * @returns {Promise.<*>}
             */
            async handleAsync(promise) {
                return new Promise((resolve, reject) => {
                    this.isActive = true;

                    // delay promise call, so user can still see that there is some action in background
                    // even on API calls which finish fast.
                    setTimeout(async _ => {
                        try {
                            this.isActive = true;
                            resolve(await promise);
                        } catch (e) {
                            reject(e);
                        } finally {
                            this.isActive = false;
                        }
                    }, 500);
                });
            }
        },
        watch: {
            active(val) {
                this.isActive = val;
            }
        }
    };
</script>

<template>
    <btn @click="onClick" :disabled="isDisabled" v-bind="$attrs" type="submit">
        <slot name="content">
            <div class="content">
                <slot></slot>
            </div>
        </slot>
    </btn>
</template>

<style lang="scss" scoped>
    .spinner {
        margin-right: 4px;
    }

    .spinner,
    .content {
        display: block;
        float: left;
    }
</style>
