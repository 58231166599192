export default [
    {
        label: 'Dashboard',
        route: { name: 'sqq-dashboard' },
        icon: 'md md-dashboard',
        order: 1,
    },
    {
        label: 'Analytics',
        route: { name: 'analytics' },
        icon: 'md md-insert-chart',
        order: 2,
    },
    {
        label: 'Questionnaires',
        route: { name: 'questionnaires-list' },
        icon: 'md md-question-answer',
        permissions: [
            '~tenants.is_founder',
            'metro_questionnaires.view_questionnaire',
        ],
        order: 3,
    },
    {
        label: 'Landing',
        route: { name: 'landing-list' },
        icon: 'md md-public',
        order: 4,
        permissions: ['~tenants.is_founder']
    },
    {
        label: 'Seal',
        route: { name: 'seals-list' },
        order: 5,
        icon: 'md md-star-half',
        permissions: ['~tenants.is_founder']
    },
    {
        label: 'Translations',
        route: { name: 'trans' },
        icon: 'md md-translate',
        permissions: ['tenants.is_founder']
    },
    {
        label: 'Settings',
        route: { name: 'settings-main' },
        icon: 'md md-settings',
        permissions: ['tenants.is_founder']
    },
    {
        label: 'Crawlers',
        route: { name: 'crawlers-list' },
        icon: 'md md-dashboard',
        permissions: ['~tenants.is_founder']
    },
    {
        label: 'Users',
        route: { name: 'permissions-users-list' },
        icon: 'md md-account-box',
        permissions: ['tenants.is_founder']
    },
    {
        label: 'Tenants',
        route: { name: 'permissions-tenants-list' },
        icon: 'md md-domain',
        permissions: ['tenants.is_founder']
    },
    {
        label: 'Groups',
        route: { name: 'permissions-groups-list' },
        icon: 'md md-group',
        permissions: ['tenants.is_founder']
    },
    {
        label: 'Messaging',
        route: { name: 'messages-triggers-list' },
        icon: 'md md-message',
        permissions: ['tenants.is_founder']
    },
    {
        label: 'Labels',
        route: { name: 'tags-fob-list' },
        icon: 'md md-view-module',
        permissions: ['tenants.is_founder']
    },
    {
        label: 'Templates',
        route: { name: 'questionnaire-templates-section' },
        icon: 'md md-question-answer',
        permissions: ['tenants.is_founder']
    },
    {
        label: 'Seal',
        route: { name: 'seals-styles' },
        icon: 'md md-star-half',
        permissions: ['tenants.is_founder']
    },
];
