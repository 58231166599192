/**
 * Global exports.
 *
 * These imports are available for other apps before the Vue is initialized.
 */
import Vue from 'vue';
import 'babel-polyfill';
import 'bootstrap-validator';
import 'bootstrap/dist/js/bootstrap.js';
import 'hammerjs';
import 'jquery-hammerjs';
import 'nprogress/nprogress.css';

import { components } from './components';
import directives from './directives';
import plugins from './plugins';
import './support';


export function installComponents() {
    for (const name in components) {
        Vue.component(name, components[name]);
    }

    for (const name in directives) {
        Vue.directive(name, directives[name]);
    }

    plugins.forEach(plugin => Vue.use(plugin));
}
