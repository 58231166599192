<script>
/**
 * DateField component.
 *
 * Integrates third-party bootstrap datepicker plugin.
 *
 * See more at http://eonasdan.github.io/bootstrap-datetimepicker/
 */

import 'eonasdan-bootstrap-datetimepicker';
import 'eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css';
import BaseMixin from './mixins/base';

export default {
    mixins: [BaseMixin],
    props: {
        minDate: [String, Object],
        maxDate: [String, Object],
        disabledDates: Array,
        enabledDates: Array,
        viewMode: String,
        format: { type: String, default: 'DD.MM.YYYY' },
        config: Object
    },
    data() {
        return {
            instance: null
        };
    },
    mounted() {
        this.instance = $(this.$refs.field)
            .datetimepicker(this.conf)
            .on('dp.change', this.onDateChanged)
        ;
    },
    computed: {
        conf() {
            return (this.config || this.dpConfig);
        },
        dpConfig() {
            return {
                format: this.format,
                minDate: this.minDate,
                maxDate: this.maxDate,
                disabledDates: this.disabledDates,
                enabledDates: this.enabledDates,
                viewMode: this.viewMode
            };
        }
    },
    methods: {
        focus() {
            this.instance.data('DateTimePicker').show();
        },
        getDatePicker() {
            return this.instance;
        },
        onDateChanged(e) {
            this.localValue = e.date.format(this.conf.format);
            this.$emit('input', this.localValue);
        }
    }
};
</script>

<template>
    <input
        type="text"
        class="form-control"
        ref="field"
        @blur="onBlur"
        @focus="onFocus"
        @input="onInput"
        :name="name"
        :value="value"
    />
</template>
