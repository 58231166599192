<script>
import PropertyResolver from '../../mixins/resolvable-property';
import ButtonMixin from './mixins/button';

/**
 * Button component.
 *
 * Renders icon and label if set.
 */
export default {
    mixins: [ButtonMixin, PropertyResolver],
    props: {
        href: { type: [String, Function], default: '' },
        route: {}
    },
    computed: {
        hrefValue() {
            return this.resolvePropValue('href');
        },
        component() {
            if (this.href) {
                return 'a';
            }

            if (this.route) {
                return 'router-link';
            }
            return 'button';
        }
    }
};
</script>

<template>
    <component :type="type" :is="component" :href="hrefValue" :class="cssClasses" @click="onClicked" :to="route" ref="btn">
        <icon v-if="icon" :name="iconValue" :iconset="iconset" />
        <span v-if="label" v-text="labelValue"></span>
        <slot></slot>
    </component>
</template>
