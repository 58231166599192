<script>
    import Spinner from './Spinner';

    export default {
        props: {
            delay: {type: Number, default: 300},
            spinnerSize: {type: String, default: '60px'},
            spinnerColor: {type: String, default: '#F44336'},
            active: Boolean
        },
        data() {
            return {
                isLoading: this.active,
                timer: null
            };
        },
        components: {
            loader: Spinner
        },
        methods: {
            async handleAsync(promise) {
                this.timer = setTimeout(() => {
                    this.isLoading = true;
                }, this.delay);
                await promise;
                clearTimeout(this.timer);
                this.isLoading = false;
                return promise;
            },
            async wrap(promise) {
                console.warn('OverlayLoader: wrap() is deprecated, use OverlayLoader.handleAsync instead.');
                return this.handleAsync(promise);
            }
        },
        watch: {
            active(isActive) {
                this.isLoading = isActive;
            }
        }
    };
</script>

<template>
    <div class="overlay">
        <slot></slot>

        <div v-if="isLoading">
            <div class="loading"></div>
            <div class="indicator">
                <loader :size="spinnerSize" :color="spinnerColor"></loader>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .overlay {
        position: relative;
        min-height: 150px;
    }

    .loading {
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        background: white;
        opacity: .8;
        z-index: 900;
    }

    .indicator {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 901;
    }
</style>
