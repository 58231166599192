<script>
const TYPE_ALIAS = {
    error: 'danger',
    danger: 'danger',
    info: 'info',
    warning: 'warning',
    warn: 'warning',
    success: 'success',
    ok: 'success'
};

const AUTOHIDE_INTERVAL = 5000;
const DEFAULT_TYPE = 'info';

function getCssClassName(type) {
    if (TYPE_ALIAS.hasOwnProperty(type)) {
        return TYPE_ALIAS[type];
    }
    return 'info';
}

export default {
    name: 'Alert',
    props: {
        closeable: Boolean,
        autohide: Boolean,
        autofocus: Boolean,
        timeout: { type: Number, default: AUTOHIDE_INTERVAL },
        type: { type: String, default: DEFAULT_TYPE },
        message: String,
        title: String
    },
    data() {
        return {
            isVisible: false,
            text: this.message,
            header: this.title,
            timer: null,
            style: getCssClassName(this.type)
        };
    },
    created() {
        if (this.message) {
            this.show(this.message, this.title);
        }
    },
    mounted() {
        let message = this.$refs.message.innerText;
        if (message) {
            this.show(message, this.title);
        }
    },
    methods: {
        /**
         * Set an alert type.
         * Available types are the same as Bootstraps ones: success, danger, etc.
         *
         * Provides fluid interface.
         */
        setType(type) {
            this.style = getCssClassName(type);
            return this;
        },

        /**
         * Show alert message.
         *
         * @param {string} text
         * @param {string?} header - An alert's header text
         * @param {number?} timeout - A delay before the alert autohides.
         *  Works only if "autohide" property is true.
         *
         * @returns {this}
         */
        show(text, header = null, timeout = null) {
            if (timeout === null) {
                timeout = this.timeout;
            }
            if (text.constructor === Array) {
                text = text.join('<br />');
            }
            this.text = text;
            this.header = header;
            this.isVisible = true;
            if (this.autohide) {
                this.timer = setTimeout(this.hide, timeout);
            }

            if (this.autofocus) {
                this.$nextTick(() => {
                    this.focus();
                });
            }
            return this;
        },

        /**
         * Hide the alert.
         */
        hide() {
            this.isVisible = false;
            this.text = this.header = null;
            this.style = getCssClassName(this.type);
            clearTimeout(this.timer);
            return this;
        },

        /**
         * Focus alert message in user's viewport.
         * Scrolls window if alert is out of viewport.
         */
        focus() {
            $(window).animate({ scrollTop: this.$el.offsetTop }, 200);
            return this;
        },

        /**
         * Shows message of "success" type.
         *
         * A shortcut for alert.setType('success').show()
         *
         * @param {string} text
         * @param {string?} header - An alert's header text
         * @param {number?} timeout - A delay before the alert autohides.
         */
        showPositiveMessage(text, header = null, timeout = null) {
            return this.setType(TYPE_ALIAS.success).show(text, header, timeout);
        },

        /**
         * Shows message of "danger" type.
         *
         * A shortcut for alert.setType('error').show()
         *
         * @param {string} text
         * @param {string?} header - An alert's header text
         * @param {number?} timeout - A delay before the alert autohides.
         */
        showNegativeMessage(text, header = null, timeout = null) {
            return this.setType(TYPE_ALIAS.success).show(text, header, timeout);
        }
    },
    computed: {
        className() {
            let parts = [`alert-${this.style}`];
            if (!this.isVisible) {
                parts.push('hidden');
            }
            return parts.join(' ');
        }
    }
};
</script>

<template>
    <div class="alert" v-bind:class="className">
        <button type="button" class="close" v-if="closeable" v-on:click.prevent="hide">
            <span>&times;</span>
        </button>
        <div v-if="header">
            <strong>{{ header }}</strong>
        </div>
        <span ref="message">
            <slot><span v-html="text">{{ text }}</span></slot>
        </span>
    </div>
</template>

<style scoped lang="scss">
.hidden {
    display: none;
}

.close {
    line-height: 18px;
}
</style>
