<script>
export default {
    props: {
        src: { type: String, default: '' },
        component: [Object, Function, String]
    }
};
</script>

<template>
<div>
    <h4><slot name="title">&nbsp;</slot></h4>
    <p><slot name="description">&nbsp;</slot></p>

    <card class="demo-viewer">
        <card-content>
            <slot name="content">
                <tabs>
                    <tab label="Demo">
                        <slot name="demo">
                            <div class="example">
                                <div class="inner">
                                    <component v-if="component" :is="component" />
                                    <example-viewer v-else :source="src"></example-viewer>
                                </div>
                            </div>
                        </slot>
                        <br />
                        <slot name="source-comment"></slot>
                    </tab>
                    <tab label="Source">
                        <slot name="source">
                            <code-viewer lang="html">{{ src|unindent }}</code-viewer>
                        </slot>
                    </tab>
                    <tab label="Props">
                        <slot name="props">This component defines no props.</slot>
                    </tab>
                    <tab label="Methods">
                        <slot name="methods">This component defines no methods.</slot>
                    </tab>
                    <tab label="Events">
                        <slot name="events">This component emits no events.</slot>
                    </tab>
                    <tab label="Slots">
                        <slot name="slots">This component provides a default slot.</slot>
                    </tab>
                </tabs>
            </slot>
        </card-content>
    </card>
</div>
</template>

<style lang="scss" scoped>
.example {
    margin-top: 8px;
    border: 1px solid #ddd;
    padding: 8px;
    background: #F8F8F8;

    .inner {
        background: white;
        padding: 24px;
    }
}
</style>
