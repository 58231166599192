<script>
export default {
    data() {
        return {
            isMenu: true,
            isSubmenu: false,
            isExpanded: true
        };
    },
    computed: {
        id() {
            return 'id-' + Math.floor(Math.random() * 10000);
        },
        cssClasses() {
            return {
                'menu-links': !this.isSubmenu,
                'collapse': this.isSubmenu,
                'in': (this.isExpanded && this.isSubmenu)
            };
        }
    }
};
</script>

<template>
    <transition name="fade">
        <div class="menu">
            <ul :class="cssClasses" :id="id" v-if="isExpanded">
                <slot></slot>
            </ul>
        </div>
    </transition>
</template>

<style lang="scss">
.menu {
    a.collapsible-header {
        font-weight: 600;
    }

    a.collapsible-header:after {
        content: "\f29e";
        display: inline-block;
        font-family: normal normal normal 14px/1 'Material Design Iconic Font', sans-serif;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;

        position: absolute;
        right: 16px;
        top: 8px;
        font-size: 22px;
        transition: transform 0.3s ease-in-out;
        color: #ef5350;
        // @extend .theme-text-lighten-1;
    }
    a.collapsible-header.active:after {
        transform: rotate(180deg);
    }

    a.collapsible-header.active {
        background: rgba(0, 0, 0, 0.05);
    }

    i {
        font-size: 20px;
        line-height: 23px;
        margin-right: 5px;
        color: #BEBEBE;
    }

    ul a {
        margin: 0;
        padding: 8px 0 8px 16px;

        color: #737373;

        display: block;
        position: relative;

        &:hover,
        &.active {
            background: rgba(202, 202, 202, 0.1);
        }
    }

    ul {
        list-style-type: none;
        margin: 0px;
        padding: 0px;
    }

    ul ul a {
        padding: 8px 15px 8px 22px;
    }
}
</style>
