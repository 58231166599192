import { defaultSorter } from './sorting';
import accounting from 'accounting';
import moment from 'moment';
import changeCase from 'change-case';
import EditCellToolbar from '../../components/table/CellToolbar.vue';
import StringCell from './columns/String.vue';
import DateTimeCell from './columns/DateTime.vue';
import MoneyCell from './columns/Money.vue';
import IntCell from './columns/Int.vue';
import TextCell from './columns/Text.vue';
import LinkCell from './columns/Link.vue';
import RowActions from './columns/RowActions.vue';
import DefaultRowActions from './columns/DefaultRowActions.vue';
import truncate from '../../utils/truncate';

/**
 * Column class.
 *
 * Represents column settings.
 */
export default class Column {
    constructor(spec) {
        if (typeof spec === 'string') {
            spec = { id: spec };
        }
        this.validate(spec);
        this._col = spec;

        this.id = spec.id;

        if (spec.label === false) {
            this.label = '';
        } else {
            this.label = spec.label || spec.id;
        }
        this.type = spec.type || 'string';
        this.typeOptions = spec.typeOptions || {};
        this.searchable = !!spec.searchable;
        this.realColumnName = spec.realColumnName || this.sanitizeKeyName();
        this.editable = !!spec.editable;
        this.editableConfig = spec.editableConfig || {};

        // merge editableConfig with defaults
        this.editableConfig = Object.assign({}, {
            component: EditCellToolbar,
            componentOptions: {
                saveLabel: 'save',
                resetLabel: 'reset'
            }
        }, this.editableConfig);

        this.css = Object.assign({ classes: '', inline: '' }, spec.css || {});

        this.sortable = !!spec.sortable;
        this._transformer = spec.transformer;
        this._formatter = spec.formatter;
        this._sorter = spec.sorter;
        this._component = spec.component;
        this._valueProvider = spec.valueProvider;
    }

    /**
     * Each col must define "id" field.
     * @param {object} col
     * @throws Error - when "id" is not present id col.
     */
    validate(col) {
        if (!col.id) {
            throw Error(`Column does not define "id" property: ${JSON.stringify(col)}`);
        }
    }

    /**
     * Get a value from row for this column.
     *
     * @param {object} _row - A model
     */
    getValue(_row) {
        const provider = this._valueProvider || (row => row[this.id]);
        return provider(_row);
    }

    /**
     * Column key. Used by Vue for list rendering performance.
     */
    get key() {
        return this.id;
    }

    /**
     * Shortcut to retrieve inline styles of column.
     */
    get inlineStyles() {
        return this.css.inline;
    }

    /**
     * Shortcut to retrieve CSS styles of column.
     */
    get cssClasses() {
        return this.css.classes;
    }

    /**
     * Test if that column is configured as sortable.
     */
    get isSortable() {
        return this.sortable;
    }

    /**
     * Get a Vue component to use with this column.
     */
    get component() {
        return this._component || StringCell;
    }

    /**
     * Returns a key sanitized name.
     */
    sanitizeKeyName() {
        return changeCase.snakeCase(this.id);
    }

    /**
     * Convert a model value into simpler type.
     * For example, convert date to unix timestamp.
     *
     * @param {string} val
     */
    transform(val) {
        let transformer = (this._transformer || (v => v));
        return transformer(val, this.typeOptions);
    }

    /**
     * Humanize value.
     * For example, convert timestamp to date.
     *
     * @param {mixed} val
     */
    format(val) {
        let formatter = (this._formatter || (v => v));
        return formatter(val, this.typeOptions);
    }

    /**
     * A sorting function.
     * This function will be called by ArrayAdapter to sort models.
     *
     * @param {array} models - array of models
     * @param {object} rule - a sorting rule
     */
    sort(models, rule) {
        let sorter = (this._sorter || defaultSorter);
        return sorter(models, rule);
    }
}

/**
 * Represents a date.
 *
 * Type options:
 *  inputFormat - specify the format of data which comes from the backend
 *  outputFormat - the format to render date in
 */
export class DateColumn extends Column {
    constructor(spec) {
        super(spec);
        this.typeOptions = Object.assign({
            inputFormat: 'D.M.Y',
            outputFormat: 'D.M.Y'
        }, this.typeOptions);

        this._transformer = this._transformer || this.__transformer.bind(this);
        this._formatter = this._formatter || this.__formatter.bind(this);
        this._component = this._component || DateTimeCell;
    }

    /**
     * The default transformer.
     */
    __transformer(val, config) {
        return moment(val, config.inputFormat).valueOf();
    }

    /**
     * The default formatter.
     */
    __formatter(val, config) {
        return moment(val).format(config.outputFormat);
    }
}

export class DateTimeColumn extends DateColumn {
    constructor(spec) {
        super(spec);
        delete this.typeOptions.inputFormat;
        delete this.typeOptions.outputFormat;

        this.typeOptions = Object.assign({
            inputFormat: 'DD.MM.YYYY HH:mm',
            outputFormat: 'DD.MM.YYYY HH:mm'
        }, this.typeOptions);
    }

    __transformer(val, config) {
        return moment(val).toString();
    }
}

/**
 * Integer column.
 *
 * Type options:
 *  min - minimal value
 *  max - maximum value
 *  step - scroll step
 *  thousand - a thousands separator
 */
export class IntColumn extends Column {
    constructor(spec) {
        super(spec);
        this.typeOptions = Object.assign({
            min: null,
            max: null,
            step: 1,
            thousand: "'"
        }, this.typeOptions);

        this._transformer = this._transformer || this.__transformer.bind(this);
        this._formatter = this._formatter || this.__formatter.bind(this);
        this._component = this._component || IntCell;
    }

    /**
     * The default transformer.
     */
    __transformer(val, config) {
        if (typeof val === 'string') {
            val = val.replace(/[^-^\d]/g, '');
        }
        return val;
    }

    /**
     * The default formatter.
     */
    __formatter(val, config) {
        let tc = this.typeOptions;
        return accounting.formatNumber(val, 0, tc.thousand, tc.decimal);
    }
}

/**
 * Money column.
 *
 * Type options:
 *  symbol - a currency symbol, default: CHF
 *  decimal - a decimal separator, default: . (point)
 *  thousand - a thousands separator, default: ' (quote)
 *  precision - a float precision, default: 2
 *  format - a display format where %s is symbol and %v is value, default: %s %v
 *  inputDecimal - a decimal separator in input data.
 *
 * NOTE: specify "inputDecimal" when the data from the backend has decimal separator
 * which does not equals to one in configured type options.
 * Example, the configured decimal is point, but separator in input date is comma,
 * the tranformer is not able to detect this which will result in errors.
 * So hint transformer to use comma for decimal in input data.
 *
 * Refer to this.__transformer documentation.
 */
export class MoneyColumn extends Column {
    constructor(spec) {
        super(spec);
        this.typeOptions = Object.assign({
            min: null,
            max: null,
            step: 1,
            symbol: 'CHF',
            decimal: '.',
            thousand: "'",
            precision: 2,
            inputDecimal: null,
            format: '%s %v'
        }, this.typeOptions);

        this._transformer = this._transformer || this.__transformer.bind(this);
        this._formatter = this._formatter || this.__formatter.bind(this);
        this._component = this._component || MoneyCell;
    }

    /**
     * The default transformer.
     */
    __transformer(val, config) {
        // If a non-standard decimal separator was used (eg. a comma)
        // unformat() will need it in order to work out
        // which part of the number is a decimal/float
        // see http://openexchangerates.github.io/accounting.js/#methods
        if (typeof val !== 'string') {
            // we transform to int or float only
            return val;
        }

        let decimal = config.decimal;
        if (val.indexOf(config.inputDecimal) !== -1) {
            decimal = config.inputDecimal;
        }
        return accounting.unformat(val, decimal);
    }

    /**
     * The default formatter.
     */
    __formatter(val, config) {
        let tc = this.typeOptions;
        return accounting.formatMoney(
            val, tc.symbol, tc.precision, tc.thousand, tc.decimal,
            tc.format
        );
    }
}

/**
 * String column.
 *
 * Type options:
 *  min - Minimum text length
 *  max - Maximum text length
 */
export class StringColumn extends Column {
    constructor(spec) {
        super(spec);
        this.typeOptions = Object.assign({
            min: null,
            max: null
        }, this.typeOptions);
        this._component = this._component || StringCell;
    }
}

/**
 * Link column.
 */
export class LinkColumn extends Column {
    constructor(spec) {
        super(spec);
        this.typeOptions = Object.assign({
            href: null
        }, this.typeOptions);
        this._component = this._component || LinkCell;
    }
}

/**
 * Text column.
 *
 * Type options:
 *  min - Minimum text length
 *  max - Maximum text length
 *  rows - Corresponds to attribute "rows" of textarea
 *  truncate - Truncate formatted with to this size
 */
export class TextColumn extends Column {
    constructor(spec) {
        super(spec);
        this.typeOptions = Object.assign({
            min: null,
            max: null,
            truncate: null,
            truncateEnding: '...',
            rows: 3
        }, this.typeOptions);
        this._component = this._component || TextCell;
        this._formatter = this._formatter || this.__formatter.bind(this);
    }

    __formatter(val, config) {
        if (config.truncate) {
            val = truncate(val, config.truncate, config.truncateEnding);
        }
        return val;
    }
}

export class DefaultActionsColumn extends Column {
    constructor(spec) {
        super(spec);
        this.editable = false;
        this.searchable = false;
        this.sortable = false;
        this.typeOptions = Object.assign({
            preComponent: null,
            postComponent: null,
            edit: {},
            delete: {}
        }, this.typeOptions);

        this.typeOptions.edit = Object.assign({}, {
            allow: true, href: null, label: null, icon: 'pencil', iconset: 'fa',
            cssClasses: null, onClicked: null
        }, this.typeOptions.edit);

        this.typeOptions.delete = Object.assign({}, {
            confimation: 'Are you sure you want to delete this item?',
            allow: true, href: null, label: null, icon: 'trash-o', iconset: 'fa',
            cssClasses: null, onClicked: null
        }, this.typeOptions.delete);

        this._component = DefaultRowActions;
    }
}

export class ActionsColumn extends Column {
    constructor(spec) {
        super(spec);
        this.editable = false;
        this.searchable = false;
        this.sortable = false;
        this.typeOptions = Object.assign({
            actions: []
        }, this.typeOptions);

        this._component = RowActions;
    }
}

/**
 * The column manager.
 * Knows all about them.
 */
class Manager {
    /**
     * Column type to class map.
     * @return {*{type: string; col: Object}[]}
     */
    getColMap() {
        return {
            date: DateColumn,
            datetime: DateTimeColumn,
            money: MoneyColumn,
            int: IntColumn,
            text: TextColumn,
            defaultActions: DefaultActionsColumn,
            actions: ActionsColumn,
            string: StringColumn,
            link: LinkColumn
        };
    }

    /**
     * Create a column from spec using spec.type.
     * @param {Column} spec
     * @returns {Column}
     */
    getColumn(spec) {
        let map = this.getColMap();
        let col = StringColumn;
        let type = changeCase.camelCase(spec.type) || 'string';

        if (type in map) {
            col = map[type];
        }
        // eslint-disable-next-line new-cap
        return new col(spec);
    }

    /**
     * Instantiate column objects basing on specs.
     * @param {Column[]} specs
     * @returns {Column[]}
     */
    map(specs) {
        return specs.map(s => this.getColumn(s));
    }
}

const columnManager = new Manager();
export { columnManager };
