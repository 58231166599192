export const loadObject = (admin, store) => {
    return async(to, from, next) => {
        if (to.params.id) {
            try {
                await store.dispatch(admin.getActionName('get'), to.params.id);
                next();
            } catch (e) {
                console.error('Could not load object "%s.%s" with id "%s"', admin.app.name, admin.name, to.params.id);
                next(e);
            }
        } else {
            // create page, remove existing object from store
            store.dispatch(admin.getActionName('set-object'), {});
            next();
        }
    };
};
