import { http } from 'metro-core';

const DEFAULT_PAGING_OPTS = {
    current: 0,
    pageSize: 50
};

const DEFAULT_ADAPTER_OPTS = {
    idField: 'id'
};

class CRUDResponse {
    constructor(success = false, data = {}, messages = [], response = null, error = null) {
        this.success = success;
        this.data = data;
        this.messages = messages;
        this.response = response;
        this.error = error;
    }

    getFlattenedMessages() {
        return this.messages.join('<br />');
    }
}

/**
 * Base class for all adapters.
 */
class AbstractAdapter {
    constructor(options, paging = {}, initial = []) {
        this.options = Object.assign({}, DEFAULT_ADAPTER_OPTS, options);
        this.models = initial;
        this.idField = options['idField'];
        this.setPaginationOptions(paging);
    }

    setPaginationOptions(opts) {
        this.pageOpts = Object.assign({}, DEFAULT_PAGING_OPTS, opts);
    }

    updatePaginationOptions(opts) {
        this.pageOpts = Object.assign({}, this.pageOpts, opts);
    }

    _getId(row) {
        return row[this.options.idField];
    }
}

/**
 * Use this adapter if you dont need a backend.
 * It works with an array of models.
 */
export class ArrayAdapter extends AbstractAdapter {
    /**
     * Load, filter and sort models.
     *
     * @param {number} page
     * @param {Array<{col: string, dir: string}>} sorting
     * @param {string} query
     * @param {Array<string>} searchCols
     */
    list(page, sorting = [], query = null, searchCols = []) {
        page = Math.max(0, page - 1);
        let size = this.pageOpts.pageSize;
        let from = page * size;
        let to = from + size;

        let models = this.models;
        models = this.filter(models, query, searchCols);
        const data = {
            total: models.length,
            items: this.sort(models, sorting).slice(from, to)
        };
        this.pageOpts.current = page;
        return new CRUDResponse(true, data);
    }

    /**
     * Filter models by query using against selected columns.
     *
     * @param {Array<Object>} models
     * @param {string} query
     * @param {Array<string>} cols
     */
    filter(models, query, cols) {
        if (!query || cols.length === 0) {
            return models;
        }
        return models.filter(m => {
            for (let col of cols) {
                const rx = new RegExp(query.replace('.', '\\.'), 'gi');
                const value = String(col.getValue(m));
                const transformedValue = col.transform(value);
                const formattedValue = col.format(transformedValue);

                // test plain value
                if (rx.test(value)) {
                    return true;
                }

                // test transformed value
                if (rx.test(String(transformedValue))) {
                    return true;
                }

                // test formatted value
                if (rx.test(String(formattedValue))) {
                    return true;
                }
            }
            return false;
        });
    }

    /**
     * Sort models using rules.
     *
     * Sorting is chained, means, you can sort by many columns at same time.
     * This function uses a sorted provided by column.
     *
     * @param {Array<Object>} models
     * @param {Array<{col: string, dir: string}>} sorting
     */
    sort(models, sorting = []) {
        for (let rule of sorting) {
            models = rule.col.sort(models, rule, rule.col.transform.bind(rule.col));
        }
        return models;
    }

    create(row) {
        this.models.push(row);
        return new CRUDResponse(true, row);
    }

    delete(row) {
        this.models = this.models.filter(_row => {
            return this._getId(row) !== this._getId(_row);
        });
        return new CRUDResponse(true);
    }

    update(row) {
        return new CRUDResponse(true, row);
    }

    partialUpdate(row, delta) {
        return this.update(row);
    }
}

export class ResourceAdapter extends AbstractAdapter {
    constructor(options, paging = {}, initial = []) {
        if (!options || !('resource' in options)) {
            throw Error('ResourceAdapter requires "resource" option to be set.');
        }
        super(options, paging, initial);
        this.resource = options.resource;
    }

    async list(page, sorting = [], query = null, searchCols = []) {
        page = Math.max(1, page);
        let size = this.pageOpts.pageSize;
        let sort = [];

        for (let rule of sorting) {
            let dir = rule.dir === 'asc' ? '' : '-';
            sort.push(dir + rule.col.realColumnName);
        }
        const params = {};
        params['page'] = page;
        params['size'] = size;
        params['search'] = query || '';
        params['ordering'] = sort.join(',');

        this.pageOpts.current = page;
        return this._handleRequest(this.resource.all({ params }));
    }

    async delete(row) {
        const id = this._getId(row);
        return this._handleRequest(
            this.resource.delete(id)
        );
    }

    async create(row) {
        return this._handleRequest(
            this.resource.create(row)
        );
    }

    async update(row) {
        const id = this._getId(row);
        return this._handleRequest(
            this.resource.update(id, row)
        );
    }

    async partialUpdate(row, delta) {
        const id = this._getId(row);
        return this._handleRequest(
            this.resource.partialUpdate(id, delta)
        );
    }

    async _handleRequest(request) {
        try {
            const response = await request;
            return new CRUDResponse(
                true, response.data, this._messagesFromResponse(response), response
            );
        } catch (e) {
            console.error(
                `ResourceAdapter has failed to make HTTP request to ${e.config.url}`
            );
            console.error(e);
            const status = e.response.status;
            let messages = ['Internal server error.'];
            if (status >= 400 && status < 500) {
                messages = this._messagesFromResponse(e.response);
            }
            return new CRUDResponse(false, null, messages, e.response, e);
        }
    }

    _messagesFromResponse(response) {
        if (response.data.hasOwnProperty('messages')) {
            return response.data.messages;
        }
        return [];
    }
}

/**
 * Map of available adapters.
 */
export const ADAPTER_MAP = {
    array: ArrayAdapter,
    resource: ResourceAdapter
};

/**
 * Find and return a model adapter.
 *
 * @param {string} name
 * @throws Error - when no adapter found in map.
 */
export function getModelAdapter(name) {
    if (typeof name === 'function') {
        return name;
    }

    if (name in ADAPTER_MAP) {
        return ADAPTER_MAP[name];
    }
    throw Error(`[data-table] Unknown model adapter "${name}"`);
}

/**
 * Constructs a new model adapter.
 *
 * @constructor
 * @param {object} options - Options to pass to the adapter.
 * @param {array} initial - Initial array of models.
 */
export function createAdapter(options, paging = {}, initial = []) {
    if (options.type === 'ajax') {
        const adapter = new ResourceAdapter({
            resource: new http.Resource(options.options.url)
        }, paging, initial);
        return adapter;
    }

    let klass = getModelAdapter(options.type);
    /* eslint-disable new-cap */
    return new klass(options.options, paging, initial);
}
