import { fieldMapping, FieldType } from './constants';
import { DeletePage, EditPage, ListPage } from './pages';
import { createStoreModule } from './store';
import { makeTitle } from './utils';

export class BaseAdmin {
    constructor(name, app, resource) {
        this.app = app;
        this.name = name;
        this.resource = resource;

        this.title = makeTitle(name);
        this.listPage = new ListPage(this, 'list');
        this.editPage = new EditPage(this, 'edit');
        this.deletePage = new DeletePage(this, 'delete');

        this.objectName = name;
        this.pluralObjectName = name;

        this.allowCreate = true;
        this.allowEdit = true;
        this.allowDelete = true;

        this.store = null;

        this.mapping = this.configureMapping();

        this.configure();
    }

    configure() {
        // pass
    }

    setStore(store) {
        this.store = store;
    }

    /**
     *
     * @param app
     * @returns {BaseAdmin}
     */
    setApp(app) {
        this.app = app;
        return this;
    }

    /**
     *
     * @param title
     * @returns {BaseAdmin}
     */
    setTitle(title) {
        this.title = title;
        return this;
    }

    /**
     *
     * @returns {Admin.ListPage}
     */
    getListPage() {
        return this.listPage;
    }

    /**
     *
     * @returns {EditPage}
     */
    getEditPage() {
        return this.editPage;
    }

    /**
     *
     * @returns {DeletePage}
     */
    getDeletePage() {
        return this.deletePage;
    }

    /**
     *
     * @param store
     * @returns {Array}
     */
    getRoutes(context) {
        const routes = [];

        const children = [
            this.getListPage().getRoutes(context),
        ];

        if (this.allowEdit || this.allowCreate) {
            children.push(this.getEditPage().getRoutes(context));
        }

        const indexRoute = {
            path: '/' + this.generateRoutePath(this.app.name, this.name),
            name: this.generateRouteName(this.app.name, this.name, 'index'),
            redirect: this.getListPage().getRouteName(),
            children: children,
            components: {
                default: () => import('./components/AdminLayoutView'),
                sidebar: () => import('./components/Sidebar'),
            },
            props: {
                default: {
                    app: this.app, admin: this
                },
                sidebar: {
                    app: this.app, admin: this
                },
            }
        };
        routes.push(indexRoute);
        return routes;
    }

    configureMapping() {
        return {};
    }

    /**
     * Change or add mapping field.
     * @param key
     * @param newMapping
     */
    alterMapping(key, newMapping) {
        if (!this.mapping[key]) {
            this.mapping[key] = {};
        }
        this.mapping[key] = Object.assign(this.mapping[key], newMapping);
    }

    setAllowCreate(flag) {
        this.allowCreate = flag;
        return this;
    }

    setAllowEdit(flag) {
        this.allowEdit = flag;
        return this;
    }

    setAllowDelete(flag) {
        this.allowDelete = flag;
        return this;
    }

    getMutationName(action, admin, app) {
        app = app || this.app.name;
        admin = admin || this.name;

        return [app, admin, action].join('.');
    }

    getActionName(action, admin, app) {
        return this.getMutationName(action, admin, app);
    }

    getGetterName(action, admin = null, app = null) {
        app = app || this.app.name;
        admin = admin || this.name;
        admin = admin.charAt(0).toUpperCase() + admin.slice(1);
        action = action.charAt(0).toUpperCase() + action.slice(1);
        return app + admin + action;
    }

    createStoreModule({ store }) {
        return createStoreModule(store, this);
    }

    getIndexRouteName() {
        return this.getListPage().getRouteName();
    }

    generateRoutePath(...args) {
        return [...args].filter(v => !!v).join('/');
    }

    generateRouteName(...args) {
        return [...args].filter(v => !!v).join('-');
    }

    setObjectNames(name, plural = null) {
        this.objectName = name;
        this.pluralObjectName = plural || name;
    }

    /* object manipulation methods */
    get object() {
        return this.store.getters[this.getGetterName('Current')];
    }

    getObjectCopy() {
        return JSON.parse(JSON.stringify(this.object));
    }

    async saveObject(data) {
        return this.store.dispatch(this.getActionName('save'), data);
    }

    async updateObject(data) {
        return this.store.dispatch(this.getActionName('update'), data);
    }

    async partialUpdateObject(data) {
        return this.store.dispatch(this.getActionName('partial-update'), data);
    }

    async fetchObject(id) {
        return this.store.dispatch(this.getActionName('get'), id);
    }

    async setObject(obj) {
        return this.store.dispatch(this.getActionName('set-object'), JSON.parse(JSON.stringify(obj)));
    }

    prepare() {
        this._processMapping(this.mapping);
    }

    _processMapping(mapping) {
        function merge(name, def) {
            return Object.assign({}, fieldMapping, def, { name: name });
        }

        for (const field in mapping) {
            mapping[field] = merge(field, mapping[field]);
            if (mapping[field].type === FieldType.TRANSLATIONS) {
                for (const childField in mapping[field].children) {
                    mapping[field].children[childField] = merge(childField, mapping[field].children[childField]);
                }
            }
        }
        return mapping;
    }
}
