<script>
    import 'bootstrap';
    import Icon from './Icon';

    export default {
        name: 'AppBarButton',
        components: {Icon},
        mounted() {
            this.$nextTick(() => {
                $(this.$el.querySelector('.dropdown-toggle')).dropdown();
            });
        },
    };
</script>

<template>
    <div class="nav-link dropdown">
        <button class="dropdown-toggle inline-flex font-semibold" data-toggle="dropdown">
            <slot></slot>
            <icon name="arrow" size="sm" v-if="$slots.content"></icon>
        </button>
        <slot name="content"></slot>
    </div>
</template>
