/**
 * Rounds a number to a precision.
 *
 * @param value
 * @param precision
 * @returns {*}
 */
export function round(value, precision = 2) {
    if (typeof value !== 'number') {
        console.warn('[filters: round] value `%s` is not a number. typeof: %s', value, typeof value);
        value = 0;
    }
    return value.toFixed(precision);
}

/**
 * Semantic filter for rating values.
 * Works identically to `round`.
 * @param value
 * @returns {*}
 */
export function rating(value, precision = 2) {
    return round(value, precision);
}

/**
 * Formats number as a percent string.
 * @param value
 * @returns {string}
 */
export function percent(value, precision = 0) {
    if (typeof value !== 'number') {
        console.warn('[filters: percent] value is not a number.');
        value = 0;
    }
    return value.toFixed(precision) + '%';
}

export default { round, rating, percent };
