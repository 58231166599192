<script>
    export default {
        props: {
            route: Object
        }
    };
</script>
<template>
    <router-link v-if="route" :to="route" class="btn btn-default btn-edit">{{ 'Edit'|trans }}</router-link>
    <btn v-else icon="pencil" class="btn-edit" iconset="fa" v-bind="$attrs" v-on="$listeners">{{ 'Edit'|trans }}</btn>
</template>
