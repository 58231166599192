<script>
import BaseMixin from './mixins/base';
import Chip from './chips/Chip';

export default {
    mixins: [BaseMixin],
    data() {
        return {
            localValue: this.value || [],
            inputValue: ''
        };
    },
    provide() {
        return {
            tagsInput: this
        };
    },
    components: {
        Chip
    },
    computed: {
        cssClasses() {
            return {
                'chips-field': true,
                'form-control': true,
                focus: this.isFocused,
                disabled: this.disabled
            };
        }
    },
    methods: {
        /**
         * Set a local value.
         * Overrides same from mixin, this sets an empty array as default value.
         */
        setValue(value) {
            this.$set(this, 'localValue', value || []);
            this.$emit('input', this.localValue);
        },

        /**
         *  Add current input as a chip.
         */
        onEnter() {
            if (this.inputValue === '') {
                return;
            }
            this.localValue.push(this.inputValue);
            this.inputValue = '';
        },

        /**
         * Emulate focus event.
         */
        focus() {
            if (this.disabled) {
                return false;
            }
            this.onFocus();

            if (!this.readonly) {
                this.$refs.input.focus();
            }
        },

        /**
         * Emulate blur event.
         */
        blur() {
            this.onBlur();
        },

        /**
         * If user pressed backspace, remove the last chip.
         */
        onRemoveRequest() {
            if (this.inputValue.trim() === '') {
                this.localValue.pop();
            }
        },

        /**
         * Remove chip by index (when "cross" button clicked)
         */
        onChipRemoved(index) {
            this.localValue.splice(index, 1);
        }
    }
};
</script>

<template>
    <div :class="cssClasses" @focusin="focus" @focusout="blur" tabindex="-1">
        <chip v-for="(chip, index) in localValue" :key="chip" @removed="onChipRemoved(index)">{{ chip }}</chip>
        <input
            type="text"
            ref="input"
            v-if="!disabled && !readonly"
            @keydown.enter.prevent="onEnter"
            @keydown.comma.prevent="onEnter"
            @keydown.backspace="onRemoveRequest"
            v-model="inputValue"
            />
    </div>
</template>

<style lang="scss" scoped>
.chips-field {
    width: 100%;
    padding: 6px 0 2px 0;
    border: 0;
    height: auto;
    cursor: text;
    min-height: 36px;
    box-shadow: inset 0 -1px 0 #ddd;
    display: inline-flex;
    flex-wrap: wrap;

    &.focus {
        box-shadow: inset 0 -2px 0 #f44336;
    }

    &.disabled {
        cursor: not-allowed;
        box-shadow: none;
        border-bottom: 1px dotted #ddd;
    }

    > .chips, > input {
        display: inline-block;
    }

    > input {
        width: 120px;
        box-shadow: none;
        margin-left: 4px;
        &:focus {
            box-shadow: none;
        }
    }
}
</style>
