export default {
    state: {
        demo: {
            pages: []
        },
        currentPage: {}
    },
    mutations: {
        /**
         * A mutation for "frontend.current-page.set-title".
         *
         * @param state
         * @param title
         */
        'frontend.current-page.set-title'(state, title) {
            state.currentPage.title = title;
            document.title = title;
        }
    },
    actions: {
        /**
         * Set the title of current page.
         * Sets to both page and document.
         *
         * @param state
         * @param title
         */
        'frontend.current-page.set-title'({commit}, title) {
            commit('frontend.current-page.set-title', title);
        }
    }
};
