<script>
    export default {
        props: {
            cell: Object
        },
        inject: ['table'],
        data() {
            return {
                sort: null
            };
        },
        computed: {
            sortDirection() {
                switch (this.sort) {
                    case 'asc':
                        return 'fa-sort-asc';
                    case 'desc':
                        return 'fa-sort-desc';
                    case null:
                        return 'fa-sort';
                }
            },
            cellCssClasses() {
                const list = [];
                if (this.cell.sortable) {
                    list.push('sortable');
                }
                return list;
            },
            sortable() {
                return this.cell.sortable;
            }
        },
        methods: {
            onSortClicked() {
                if (!this.sortable) {
                    return;
                }

                if (this.sort === null) {
                    this.sort = 'asc';
                } else if (this.sort === 'asc') {
                    this.sort = 'desc';
                } else if (this.sort === 'desc') {
                    this.sort = null;
                }

                this.table.sort(this.cell.field, this.sort, this.cell.sortFn);
            }
        }
    };
</script>
<template>
    <th class="header-cell" :class="cellCssClasses" @click="onSortClicked" scope="col">
        <slot></slot>
        <span v-if="sortable">
            <i class="fa" :class="sortDirection"></i>
        </span>
    </th>
</template>

<style lang="scss" scoped>
    .header-cell {
        user-select: none;
    }

    .sortable {
        cursor: pointer;
    }
</style>
