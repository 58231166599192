import merge from 'lodash/merge';

/**
 * Project settings.
 * This object is merged with global window property: window.__metro__.
 *
 * httpClient is AxiosRequestConfig object, later passed into axios contructor.
 */
const defaultSettings = {
    debug: false,
    httpClient: {
        baseURL: '',
    },
    router: {
        baseURL: '/admin'
    }
};

export default merge(defaultSettings, window.__metro__ || {});
