import axios from 'axios';
import merge from 'lodash/merge';

import settings from '../settings';
import {camelCase, convertObjectKeys, snakeCase} from './utils';

export {default as axios} from 'axios';

if (settings.httpClient.baseURL[settings.httpClient.baseURL.length] == '/') {
    console.error('settings.httpClient.baseURL must end with a trailing slash.');
}

/**
 * Standard settings for metro project.
 */
const defaultSettings = merge({
    baseURL: '',
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFToken'
}, settings.httpClient);

/**
 * A preconfigured axios instance.
 * @type {AxiosInstance}
 */
export const defaultHttpClient = axios.create(defaultSettings);

/**
 * Same as defaultHttpClient, but with applied response/request key transformers.
 * @type {AxiosInstance}
 */
export const httpClient = axios.create({
    ...defaultSettings,
    transformRequest: [
        data => convertObjectKeys(data, snakeCase),
        axios.defaults.transformRequest[0]
    ],
    transformResponse: [
        data => {
            try {
                if (data.length > 0) {
                    data = JSON.parse(data);
                    const c = convertObjectKeys(data, camelCase);
                    data = JSON.stringify(c);
                }
            } catch (e) {
                console.error('[default http client] failed to transform data from API:');
                console.error(e);
            }
            return data;
        },
        axios.defaults.transformResponse[0]
    ],
});
