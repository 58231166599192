<script>
export default {
    props: {
        value: [String, Number],
        size: { type: String, default: 'md' },
        color: { type: String, default: 'default' },
        labels: { type: Array, default: () => ([]) },
        reverse: Boolean,
    },
    computed: {
        classes() {
            return {
                'progress-bar-reverse': this.reverse,
            };
        },
        innerClasses() {
            return [
                `progress-bar-${this.color}`,
                `progress-bar-${this.size}`,
            ];
        },
        innerStyles() {
            return {
                width: `${this.value}%`,
            };
        }
    }
};
</script>

<template>
    <div>
        <div class="progress-bar" :class="classes">
            <div
                class="inner"
                :class="innerClasses"
                :style="innerStyles">

            </div>
        </div>
        <div class="progress-bar-labels" v-if="labels.length">
            <span v-for="label in labels" :key="label">
                {{ label }}
            </span>
        </div>
    </div>
</template>
