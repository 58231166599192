<script>
    import filters from '../../filters';
    import { FieldType } from '../constants';

    export default {
        props: ['row', 'col', 'admin'],
        computed: {
            lang() {
                return this.$store.getters['trans/current'];
            },
            value() {
                // if column name has "__", it is a group
                let value = this.row;
                if (this.col.name.indexOf('__') === -1) {
                    value = this.row[this.col.name];
                } else {
                    // resolve value from group
                    const parts = this.col.name.split('__');
                    parts.forEach(p => {
                        if (this.admin.mapping[p] && this.admin.mapping[p].type === FieldType.TRANSLATIONS) {
                            value = value[p][this.lang];
                        } else {
                            value = value[p];
                        }
                    });
                }

                if (this.col.translatable) {
                    value = this.$t(value);
                }

                return value;
            },
            formattedValue() {
                let value = this.value;

                // apply standard formatter
                if (this.col.displayFormat) {
                    if (this.col.displayFormat in filters) {
                        value = filters[this.col.displayFormat](value);
                    } else {
                        console.error('[admin] displayFormat is unsupported: %s', this.col.displayFormat);
                    }
                }

                // apply user defined formatters
                this.col.formatters.forEach(f => {
                    value = f(value, { vm: this, column: this.col, row: this.row });
                });

                return value;
            }
        }
    };
</script>

<template>
    <router-link :to="{name: admin.getEditPage().getRouteName(), params: {id: row.id}}" v-if="col.primaryKey">
        {{formattedValue}}
    </router-link>
    <span v-else>{{formattedValue}}</span>
</template>
