export default {
    data() {
        return {
            sorting: []
        };
    },
    created() {
        this.eventBus.$on('sort-set-rule', this.onSetSortRule);
        this.eventBus.$on('sort-deselect', this.onSortDeselect);
    },
    methods: {
        async onSetSortRule(col, dir, vm) {
            try {
                this.sorting = [];
                this.sorting.push({ col, dir });
                await this.load(this.paginator.current);
            } catch (e) {
                console.error(e);
            }
        },
        onSortDeselect(col) {
            this.sorting = this.sorting.filter(rule => {
                return col.id !== rule.col.id;
            });
        }
    }
};
