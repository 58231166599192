<script>
    export default {};
</script>

<template>
    <div class="snackbar-content">
        <slot></slot>
    </div>
</template>

<style lang="scss" scoped>
    .snackbar-content {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: opacity .38s cubic-bezier(.55, 0, .55, .2);
        color: #fff;

        .btn {
            min-width: 0;
            margin: -8px -8px -8px 36px;

            background: transparent;
            color: #f44336;
        }
    }
</style>
