<script>
    export default {
        props: {
            href: String,
            icon: String,
            title: String,
            active: Boolean
        },
        computed: {
            linkClass() {
                const list = [];
                if (this.active) {
                    list.push('active');
                }
                return list;
            },
            iconClass() {
                return 'md md-' + this.icon;
            }
        }
    };
</script>
<template>
    <div v-tooltip.right="title">
        <a :href="href" :class="linkClass">
            <i :class="iconClass"></i>
        </a>
    </div>
</template>

<style lang="scss" scoped>
    a {
        display: block;
        padding: 20px 0;
        text-align: center;
        line-height: 24px;
        color: #646464;

        &:hover {
            background: rgba(100, 100, 100, .1);
        }

        &.active {
            background: rgba(100, 100, 100, .2);
        }

        i {
            font-size: 30px;
        }
    }
</style>
