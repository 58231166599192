import DynamicRouteView from './DynamicRouteView.vue';

export { DynamicRouteView };

/**
 * @deprecated use admin generator instead
 */
export class ExtraRoutes {
    constructor(routes = []) {
        this.routes = routes;
    }

    add(route) {
        this.routes.push(route);
    }

    all() {
        return this.routes;
    }
}

export const router = {
    DynamicRouteView
};
