<script>
    /**
     * A list menu.
     *
     * Renders menu in style of bootstrap's dropdown-menu
     * but adopted to work standalone
     *
     * Usage:
     *  <list-menu>
     *      <list-menu-item>Item 1</list-menu-item>
     *      <list-menu-item>Item 2</list-menu-item>
     *      <list-menu-item>Item 3</list-menu-item>
     *  </list-menu>
     */
    export default {

    };
</script>

<template>
    <ul class="dropdown-menu list-menu">
        <slot></slot>
    </ul>
</template>

<style lang="scss" scoped>
    .dropdown-menu {
        display: block;
        margin-top: 0 !important;
        position: relative;
        width: 100%;
        z-index: 0;
    }
</style>
