<script>
    import PageNavigation from './PageNavigation';

    export default {
        props: {
            id: [String, Number],
            app: {required: true, type: Object},
            admin: {required: true, type: Object},
        },
        components: {PageNavigation},
        computed: {
            object() {
                return this.admin.object;
            },
            pages() {
                const children = this.admin.getEditPage().getCustomPages().map(page => {
                    page.setDisabled(this.id === undefined);
                    return page;
                });
                return [this.admin.getEditPage(), ...children];
            }
        }
    };
</script>
<template>
    <page-layout>
        <page-navigation :pages="pages" :object="object"></page-navigation>
        <router-view :object="object"></router-view>
    </page-layout>
</template>
