import {AbstractDataSource} from './AbstractDataSource';

const defaultSortFn = (field, order) => {
    return (a, b) => {
        if (!order) {
            return 0;
        }

        let mod = order === 'desc' ? -1 : 1;
        let first = a[field];
        let second = b[field];

        if (first < second) {
            return (-1 * mod);
        }

        if (first > second) {
            return (1 * mod);
        }

        return 0;
    };
};

/**
 * An array datasource.
 *
 * Works with plain array.
 */
export class ArrayDataSource extends AbstractDataSource {
    edit(row, delta) {
        this.sourceRows.forEach(r => {
            if (row === r) {
                Object.assign(r, delta);
            }
        });
    }

    insert(row) {
        this.sourceRows.push(row);
    }

    delete(row) {
        this.sourceRows = this.sourceRows.filter(r => r !== row);
    }

    doFetch() {
        let page = Math.max(this.page - 1, 0);
        const from = page * this.pageSize;
        const to = from + this.pageSize;
        const sortFn = this.sortFn || defaultSortFn;
        const filteredRows = this.sourceRows
            .filter(row => {
                if (!this.searchQuery) {
                    return true;
                }

                for (const value of Object.values(row)) {
                    if (value.toString().toLowerCase().indexOf(this.searchQuery.toLowerCase()) !== -1) {
                        return true;
                    }
                }
                return false;
            })
            .sort(sortFn(this.sortField, this.sortOrder))
        ;

        const pagedRows = filteredRows.slice(from, to);

        this.rows = pagedRows;
        this.totalRows = filteredRows.length;
        this.notify();
    }
}
