const PAGINATION_DEFAULT_SIZE = 15;

export default {
    props: {
        pagination: {
            type: Object,
            default() {
                return {
                    current: 1,
                    pageSize: 15,
                    visible: 15
                };
            }
        }
    },
    data() {
        return {
            paginator: {
                total: 0,
                current: this.pagination.current || 1,
                visible: this.pagination.visible || 15,
                pageSize: this.pagination.pageSize || PAGINATION_DEFAULT_SIZE
            }
        };
    },
    methods: {
        async onPageSelected(page) {
            await this.load(page);
        }
    }
};
