<script>
    export default {
        inject: ['table'],
        mounted() {
            const cells = Array.from(this.$el.childNodes).filter(({tagName, classList}) => {
                const isTd = (tagName && tagName.toLowerCase() === 'td');
                return isTd;
            });

            cells.forEach((cell, index) => {
                const vm = cell.__vue__;
                vm.index = index;
                this.$set(this.table.cells, index, vm);
            });
        }
    };
</script>

<template>
    <tr class="table-row">
        <slot />
    </tr>
</template>


