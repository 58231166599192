<script>
/**
 * A table plugin.
 * Provides page size selector.
 */

const DEFAULT_SIZES = [25, 50, 100];

export default {
    props: {
        sizes: {
            type: Array,
            default() {
                return DEFAULT_SIZES;
            }
        },
        value: {
            required: true,
            type: [Number, String]
        }
    },
    data() {
        return {
            selected: this.value
        };
    },
    watch: {
        selected(newval) {
            this.$emit('input', newval);
        }
    }
};
</script>

<template>
<div>
    <span>{{ 'Per page'|trans }}</span>
    <select class="form-control" v-model="selected">
        <option v-for="size in sizes" :value="size">{{ size }}</option>
    </select>
</div>
</template>

<style lang="scss" scoped>
div {
    padding-top: 20px;
    padding-bottom: 10px;
    width: 120px;
    display: inline-block;

    span {
        margin-right: 8px;
    }

    select {
        width: auto;
        display: inline-block;
    }
}
</style>
