<script>
    export default {
        props: {
            value: String
        },
        data() {
            return {
                model: this.value
            };
        },
        methods: {
            onSubmit() {
                this.$emit('submit', this.model);
            }
        }
    };
</script>

<template>
    <form class="form-inline search-form" @submit.prevent="onSubmit">
        <text-field v-model="model" :placeholder="'Search...'|trans"/>
        <button class="btn btn-sm btn-link" type="submit">
            <i class="md md-search f20"></i>
        </button>
    </form>
</template>

<style scoped lang="scss">
    form {
        padding: 20px;
        padding-bottom: 10px;
        display: flex;
        max-width: 400px;

        .form-control {
            flex: 1;
        }

        button {
            flex: 0;
        }
    }
</style>
