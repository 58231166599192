import cookies from 'js-cookie';

/**
 * Cookies for Vue.
 */
export default {
    install: (Vue) => {
        Vue.prototype.$cookies = cookies;
        Vue.cookies = cookies;
    }
};
