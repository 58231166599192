<script>
/**
 * A card component.
 *
 * Usage:
 *  <card>
 *      <card-header>
 *          <card-title>
 *              A card title
 *          </card-title>
 *      </card-header>
 *      <card-content>
 *          Content goes here.
 *      </card-content>
 *  </card>
 */
export default {
    props: {
        bordered: Boolean,
        small: Boolean,
        large: Boolean
    },
    computed: {
        cssClasses() {
            return {
                bordered: this.bordered,
                small: this.small,
                large: this.large
            };
        }
    }
};
</script>

<template>
    <div class="card" :class="cssClasses">
        <slot></slot>
    </div>
</template>
