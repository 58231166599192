export default {
    data() {
        return {
            searchable: this.options.searchable || false,
            searchQuery: null,

            // used only by 'array' adapter.
            searchableCols: []
        };
    },
    created() {
        this.detectSearchables();
    },
    methods: {
        async onSearchSubmit(q) {
            this.searchQuery = q;
            return this.load(1);
        },
        /**
         * Find searchable columns
         * and mark table as searchable if found.
         */
        detectSearchables() {
            this.cols.map(c => {
                if (c.searchable) {
                    this.searchableCols.push(c);
                }
            });
        }
    }
};
