<script>
/**
 * A header row.
 *
 * The reason of having this component is to improve table
 * outline in chrome devtools.
 */

import HeaderCell from './HeaderCell';

export default {
    props: {
        cols: {
            type: Array,
            required: true
        }
    },
    components: {
        HeaderCell
    }
};
</script>

<template>
    <tr>
        <header-cell
            v-for="col in cols"
            v-bind:key="col.key"
            v-bind:col="col"
        ></header-cell>
    </tr>
</template>
