/**
 * Exports of this module are shared globally and accessable from "window" object.
 *
 * Eg.
 *      window.apps.BaseApp
 */
import { appRegistry, BaseApp } from './apps';
import { BaseAdmin } from './admin';

export * from './apps';
export { App } from './main';
export { bootstrap } from './boot';

export { default as filters } from './filters';
export { default as http } from './http';
export { init } from './init';
export { menu, services, headerActions } from './services/';

export const apps = {
    BaseApp, appRegistry,
    register: appRegistry.register.bind(appRegistry)
};

export const admin = {
    BaseAdmin
};
