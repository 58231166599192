<script>
    export default {
        methods: {
            onAccepted() {
                this.$emit('accept');
            },
            onRejected() {
                this.$emit('reject');
            }
        }
    };
</script>

<template>
    <div class="editor">
        <slot></slot>
        <div>
            <a href="#" @click.prevent="onAccepted">{{ 'Save'|trans }}</a>
            <a href="#" @click.prevent="onRejected">{{ 'Reset'|trans }}</a>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .editor {
        input, textarea {
            font-size: 13px;
        }
        a {
            margin-right: 10px;
        }
    }
</style>
