/**
 * Truncate string.
 * @param {String} str
 * @param {Number} length
 * @param {String} ending
 */
export function truncate(str, length, ending) {
    if (length == null) {
        length = 100;
    }
    if (ending == null) {
        ending = '...';
    }

    if (str == null) {
        return str;
    }
    if (str.length > length) {
        return str.substring(0, length - ending.length) + ending;
    } else {
        return str;
    }
}

/**
 * Detects and removes extra spaces in lines.
 * @param {String} src
 */
export function unindent(src) {
    let lines = src.split(/\n/);
    let spaces = null;
    for (let _index in lines) {
        let index = parseInt(_index);
        const line = lines[index];
        if (line.trim() === '') {
            continue;
        }

        if (!spaces) {
            const matches = line.match(/^([\s]+)/);
            if (matches) {
                spaces = matches[1];
            }
        }
        lines[index] = line.replace(spaces, '');
    }
    return lines.join('\n').trim();
}

export default { truncate, unindent };
