import changeCase from 'change-case';
import Sidebar from './Sidebar';

export function createComponent(component, props) {
    return Vue.extend({
        functional: true,
        render(h) {
            return h(component, {
                props: props
            });
        }
    });
}

export function createSidebar(config) {
    return createComponent(Sidebar, { config });
}

export function createRouteName(app, page, action, ...args) {
    return [app, page, action, ...args].filter(v => !!v).join('-');
}

export function createRoutePath(app, page, ...args) {
    return '/' + [app, page, ...args].filter(v => !!v).join('/');
}

export function createMutationName(app, page, action) {
    return [app, page, action].join('.');
}

export function createActionName(app, page, action) {
    return createMutationName(app, page, action);
}

export function makeTitle(name) {
    return name.charAt(0).toUpperCase() + name.slice(1).replace(/-_/g, ' ');
}

export function includesFiles(formData) {
    for (const key of Object.keys(formData)) {
        if (formData[key] instanceof Blob) {
            return true;
        }
    }
    return false;
}

/**
 * Encodes JavaScript object into FormData.
 * Supports nested values and uploads.
 * @param obj
 * @returns {FormData}
 */
export function objToFormData(obj, data = null, parent = '') {
    data = data || new FormData();
    if (obj.constructor === Array) {
        obj.forEach((value, index) => {
            const key = parent + '[' + index + ']';
            data.append(key, value);
        });
        return data;
    }

    Object.keys(obj).forEach(k => {
        let key = changeCase.snakeCase(k);
        if (parent) {
            key = parent + '[' + k + ']';
        }

        const value = obj[k];
        switch (true) {
            case value && value.constructor === Array:
            case value && (typeof value === 'object' && !(value instanceof Blob)):
                objToFormData(value, data, key);
                break;
            default:
                data.append(key, obj[k]);
        }
    });
    return data;
}
