export function makeTitle(name) {
    return name.charAt(0).toUpperCase() + name.slice(1).replace(/-_/g, ' ');
}

export function getObjectId(obj) {
    if (obj instanceof FormData) {
        let val = obj.get('id');
        if (val === 'null') {
            val = null;
        }
        return val;
    }
    return obj.id;
}
