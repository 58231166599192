<script>
import FieldMixin from './mixins/base';

/**
 * Radio button.
 *
 * Can be a part of <radio-group />.
 */
export default {
    name: 'RadioField',
    mixins: [FieldMixin],
    props: {
        name: { required: false },
        inline: Boolean,
        label: String,
        value: { required: true }
    },
    data() {
        return {
            isInline: this.inline
        };
    },
    created() {
        this.useFixedLabel = true;
    },
    computed: {
        /**
         * CSS classes.
         */
        cssClasses() {
            return {
                'radio': !this.isInline,
                'radio-field': true,
                'radio-inline': this.isInline
            };
        },
        /**
         * Detects text value from "value" attribute.
         */
        attrValue() {
            if ('value' in this.$attrs) {
                return this.$attrs['value'];
            }
        },

        /**
         * Determines checked state of input.
         */
        isChecked() {
            return this.localValue === this.attrValue;
        }
    },
    methods: {
        /**
         * Flag that input must be rendered in inline or vertial layout.
         */
        setInline(flag) {
            this.isInline = !!flag;
        },

        /**
         * "change" event listener.
         */
        onChanged(e) {
            if (e.target.checked) {
                this.localValue = this.attrValue;
            } else {
                this.localValue = null;
            }
        },
        /**
         * Set local value.
         *
         */
        setValue(value) {
            this.localValue = value;
        }
    },
    watch: {
        inline(flag) {
            this.setInline(flag);
        }
    }
};
</script>

<template>
    <div :class="cssClasses" tabindex="-1">
        <label>
            <input
                type="radio"
                ref="field"
                :checked="isChecked"
                :disabled="disabled"
                :name="name"
                :placeholder="placeholder"
                :readonly="readonly"
                :required="required"
                :value="value"
                @change="onChanged"
                v-bind="$attrs"
                />
                {{ label }}
        </label>
    </div>
</template>
