<script>
    import Icon from '../Icon';

    export default {
        name: 'DropdownMenu',
        components: {Icon},
        props: {
            searchable: Boolean,
            placeholder: String,
            searchFields: {type: [Array, String], default: null},
            items: {type: Array, default: () => ([])},
        },
        data() {
            return {
                query: ''
            };
        },
        created() {
            if(this.searchable && !this.searchFields) {
                throw Error('"searchable" property is true but no "searchFields" defined.');
            }
        },
        computed: {
            filtered() {
                if(!this.searchable) {
                    return this.items;
                }

                let searchFields = this.searchFields || '';
                if(searchFields.constructor !== Array) {
                    searchFields = [searchFields];
                }

                return this.items
                    .filter(item => {
                        return searchFields.some(field => {
                            return item[field].toLowerCase().includes(this.query.toLowerCase());
                        });
                    });
            }
        },
        methods: {
            filter(query) {
                this.filter = query;
            }
        }
    };
</script>

<template>
    <div class="shadow-dropdown rounded bg-white py-1 flex flex-col dropdown-menu">
        <div class="flex-grow-0 p-2 relative" v-if="searchable">
            <text-field
                ref="searchInput"
                name="query"
                autofocus
                :placeholder="placeholder"
                v-model="query"
                >

            </text-field>
        </div>
        <div class="flex-auto overflow-y-auto scrollbar">
            <slot
                :items="filtered"
                :all-items="items"
                :query="query"
                :filter="filter"></slot>
        </div>
        <slot name="afterList"></slot>
    </div>
</template>

<style lang="scss" scoped>
    .dropdown-menu {
        z-index: 5000;
        max-height: 500px;
        overflow-y: auto;
    }

    .input-sm {
        @apply text-gray-800 leading-none;
    }

    .input-icon {
        transform: translateX(-50%);
    }

    .input-sm::placeholder {
        @apply text-gray-800;
    }

</style>
