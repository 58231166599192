import headerActions from './header-actions';
import styling from './styling';
import pagination from './pagination';
import inlineEditable from './inline-editable';
import searchable from './searchable';
import sortable from './sortable';
import defaultRowActionsSupport from './default-row-actions-support';

export default [
    headerActions, styling, pagination, inlineEditable,
    searchable, sortable, defaultRowActionsSupport
];
