<script>
    import SnackbarContent from './SnackbarContent';
    import {createSnackbar, destroySnackbar} from './queue';

    export default {
        props: {
            timeout: Number,
            active: Boolean,
            type: String
        },
        components: {SnackbarContent},
        computed: {
            cssClasses() {
                const list = ['snackbar', this.type];
                if (this.active) {
                    list.push('open');
                }
                return list;
            }
        },
        methods: {
            dismiss() {
                this.active = false;
            }
        },
        watch: {
            async active(flag) {
                if (flag) {
                    createSnackbar(this.timeout, this);
                } else {
                    destroySnackbar();
                }
            }
        }
    };
</script>

<template>
    <div :class="cssClasses">
        <snackbar-content>
            <slot></slot>
        </snackbar-content>
    </div>
</template>

<style lang="scss" scoped>
    .snackbar {
        background-color: #323232;
        bottom: -100px;
        left: 24px;
        box-shadow: 0 1px 0px -1px rgba(0, 0, 0, 0.2), 0 3px 20px 0 rgba(0, 0, 0, 0.14), 0 1px 13px 0 rgba(0, 0, 0, 0.12);
        min-width: 288px;
        max-width: 568px;
        min-height: 48px;
        max-height: 80px;
        padding: 8px 24px;
        display: flex;
        align-items: center;
        position: fixed;
        z-index: 15;
        border-radius: 2px;

        transition-property: opacity, bottom, left, right, width, margin, border-radius;
        transition-duration: 0.3s;
        transition-timing-function: ease;

        &.open {
            bottom: 24px;
        }

        &.error {
            background: #E53935;
        }
    }
</style>
