/**
 * Default column sorting function.
 * Used if there is not sort function provided by user in column.
 */
export function defaultSorter(items, rule) {
    items.sort((a, b) => {
        let mod = rule.dir === 'desc' ? -1 : 1;
        let first = rule.col.transform(a[rule.col.id]);
        let second = rule.col.transform(b[rule.col.id]);

        if (first < second) {
            return (-1 * mod);
        }

        if (first > second) {
            return (1 * mod);
        }

        return 0;
    });
    return items;
}

export default defaultSorter;
