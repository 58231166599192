<script>
    export default {
        name: 'TableHeaderActions'
    };
</script>

<template>
    <div class="header-actions">
        <slot></slot>
    </div>
</template>

<style lang="scss" scoped>
    .header-actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        > * {
            margin-left: 8px;
        }

        &:empty {
            display: none;
        }
    }
</style>
