<script>
export default {
    props: {
        src: String
    }
};
</script>

<template>
    <div class="card-profile pull-right">
        <slot>
            <img :src="src" alt="">
        </slot>
    </div>
</template>
