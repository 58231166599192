<script>
    import changeCase from 'change-case';
    import {http} from 'metro-core';
    import OverlayLoader from '../loaders/OverlayLoader';
    import datasources from './datasource';
    import HeaderActions from './HeaderActions';
    import PageSizeSelect from './PageSizeSelect';
    import TableRowGhost from './RowGhost';
    import TableBody from './TableBody';
    import TableFooter from './TableFooter';
    import TableHeader from './TableHeader';
    import TableRow from './TableRow';

    const QP_PAGE = 'page';
    const QP_SORT = 'sort';
    const QP_SIZE = 'size';
    const QP_SEARCH = 'search';

    const Resource = http.Resource;

    /**
     * The datatable component.
     *
     * Events:
     *
     *
     * Usage:
     *
     * <m-table [:searchable] [:track-by] :source [@sort] [@delete] [@edit] [@search] [@page-size] [@page]>
     *
     *      <m-table-header-actions>
     *          <btn primary>Create new item</btn>
     *      </m-table-header-actions>
     *
     *      <m-table-row-template slot="row" slot-scope="{ row }">
     *          <m-table-cell label="ID">{{ row.id }}</m-table-cell>
     *          <m-table-cell-string sortable editable label="Name">{{ row.name }}</m-table-cell-string>
     *          <m-table-cell-actions>
     *              <btn label="My Custom Action" />
     *              <m-table-btn-edit />
     *              <m-table-btn-delete :row="row" />
     *          </m-table-cell-actions>
     *      </m-table-row-template>
     *
     *      <m-table-empty-state>
     *              Show this content when table has no data.
     *          </m-table-empty-state>
     *
     * </m-table>
     */
    export default {
        props: {
            /**
             * Disable loading effect.
             */
            noLoadingEffect: Boolean,

            /**
             * Change row key to use for v-for :key
             */
            trackBy: {type: String, default: 'id'},

            /**
             * Enables search form.
             */
            searchable: {type: Boolean, default: false},

            /**
             * A datasource instance, resource or array.
             */
            source: {type: [Resource, Array, String, datasources.AbstractDataSource]},

            /**
             * Default number of items per page.
             */
            pageSize: {type: [Number, String], default: 50},

            /**
             * Available page sizes.
             */
            pageSizes: {type: Array},

            /**
             * A query parameter name used for search form value.
             */
            queryParamPrefix: {type: String, default: ''}
        },
        data() {
            return {
                dataSource: null,
                isLoading: false,
                loadError: false,
                cells: [],
                rows: []
            };
        },
        provide() {
            return {
                table: this
            };
        },
        components: {
            OverlayLoader,
            HeaderActions, TableHeader, TableRow, TableBody, TableFooter, PageSizeSelect, TableRowGhost
        },
        created() {
            this.dataSource = this.createDataSource(this.source);
            this.dataSource.searchQuery = this.searchQuery;
            this.dataSource.pageSize = this.queryPageSize;
            this.dataSource.page = this.queryPage;
            this.dataSource.setSorting(...this.sorting);

            if (this.noLoadingEffect === false) {
                this.dataSource.on('load-start', () => {
                    this.isLoading = true;
                    this.$emit('load-start');
                });
                this.dataSource.on('load-finish', response => {
                    this.isLoading = false;
                    this.$emit('load-finish', response);
                });
            }
            this.dataSource.on('load-error', (e) => {
                console.error('Datatable source failed to fetch data: \n', e);
                this.loadError = e;
                this.isLoading = false;
                this.$emit('load-error', e);
            });
            this.dataSource.subscribe(rows => {
                this.$emit('change', rows);
                this.rows = rows;
            });
        },
        computed: {
            searchQuery() {
                return this.$route.query[this.getQueryParamName(QP_SEARCH)] || '';
            },
            sorting() {
                const sortString = this.$route.query[this.getQueryParamName(QP_SORT)] || '';
                return sortString.split(':');
            },
            queryPage() {
                return this.$route.query[this.getQueryParamName(QP_PAGE)] || 1;
            },
            queryPageSize() {
                return this.$route.query[this.getQueryParamName(QP_SIZE)] || this.pageSize;
            }
        },
        methods: {
            async sort(field, order) {
                // hack this until we decide what to do with case conversion
                // Allow sorting by django foreign keys without conversion of initial field name
                field = field.indexOf('__') === -1 ? changeCase.snakeCase(field) : field;
                this.updateQueryParams({
                    [this.getQueryParamName(QP_SORT)]: field + ':' + order
                });
                this.$emit('sort', field, order);
                this.dataSource.setSort(field, order);
                await this.dataSource.update();
            },
            async delete(row) {
                this.$emit('delete', row);
                try {
                    await this.dataSource.delete(row);
                    await this.dataSource.update();
                } catch (e) {
                    this.$emit('delete-error', e);
                }
            },
            async onCellEdited(value, field, row) {
                const delta = {
                    [field]: value
                };
                this.$emit('edit', row, delta);
                await this.dataSource.edit(row, delta);
                await this.dataSource.update();
            },
            async onSearchSubmit(search) {
                this.updateQueryParams({
                    [this.getQueryParamName(QP_SEARCH)]: search,
                    [this.getQueryParamName(QP_PAGE)]: 1
                });
                this.$emit('search', search);
                this.dataSource.setFilter(search);
                this.dataSource.setPage(1);
                await this.dataSource.update();
            },
            async onPageSizeChanged(size) {
                this.updateQueryParams({
                    [this.getQueryParamName(QP_SIZE)]: size,
                    [this.getQueryParamName(QP_PAGE)]: 1
                });
                this.$emit('page-size', size);
                this.dataSource.setPageSize(size);
                await this.dataSource.update();
            },
            async onPageChanged(page) {
                this.updateQueryParams({
                    [this.getQueryParamName(QP_PAGE)]: page
                });
                this.$emit('page', page);
                this.dataSource.setPage(page);
                await this.dataSource.update();
            },
            createDataSource(source) {
                if (typeof source === 'string') {
                    if (source.indexOf('store:') !== -1) {
                        return new datasources.StoreDataSource(this.$store, source.replace('store:', ''));
                    }
                }

                if (source instanceof Array) {
                    return new datasources.ArrayDataSource(source);
                }
                if (source instanceof Resource) {
                    return new datasources.ResourceDataSource(source);
                }

                if (source instanceof datasources.AbstractDataSource) {
                    return source;
                }

                throw new Error('Invalid datasource provided for table.');
            },
            updateQueryParams(params) {
                const route = Object.assign({}, this.$route);
                delete route.matched;
                route.query = Object.assign({}, route.query, params);
                this.$router.push(route);
            },
            getQueryParamName(param) {
                return this.queryParamPrefix + param;
            },
        }
    };
</script>

<template>
    <div class="data-table">

        <div class="toolbar" v-if="searchable || $scopedSlots['m-table-header-actions']">
            <div class="header-left">
                <search-form @submit="onSearchSubmit" v-if="searchable" :value="searchQuery"></search-form>
            </div>
            <div>
                <slot name="m-table-header-actions"></slot>
            </div>
        </div>

        <overlay-loader :active="isLoading">
            <table class="table table-full table-full-small">
                <table-header v-if="cells.length"></table-header>
                <table-body :class="{hide: !cells.length}">
                    <table-row-ghost v-for="row in rows" :key="row[trackBy]" :row="row">
                        <slot name="row" :row="row"/>
                    </table-row-ghost>
                </table-body>
                <tbody v-if="rows.length === 0 && !isLoading && !loadError" :class="{'no-border': !cells.length}">
                <slot name="m-table-empty-state">
                    <m-table-empty-state>
                        {{ 'No data available'|trans }}
                    </m-table-empty-state>
                </slot>
                </tbody>
                <tbody v-if="loadError" class="loading-error">
                <tr>
                    <td class="loading-error">
                        <div>
                            <alert type="danger">{{ 'Error loading data.'|trans }}</alert>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </overlay-loader>

        <div class="clearfix">
            <slot name="bottom">
                <div class="col-md-6">
                    <page-size-select
                            :value="dataSource.pageSize"
                            :sizes="pageSizes"
                            @input="onPageSizeChanged"
                    ></page-size-select>
                </div>
                <div class="col-md-6">
                    <pagination
                            :total="dataSource.totalRows"
                            :size="dataSource.pageSize"
                            :visible="10"
                            :value="dataSource.page"
                            @input="onPageChanged"
                    ></pagination>
                </div>
            </slot>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .data-table {
        cursor: default;

        .no-border {
            border: 0 !important;
        }

        .header-left {
            &:empty {
                display: none;
            }
        }

        .loading-error {
            border-top: 0;

            > div {
                min-height: 100px;
                display: flex;
                align-items: center;

                > .alert {
                    flex: 1;
                }
            }
        }

        .table {
            th, td {
                cursor: default;
            }
        }

    }

    .toolbar {
        padding-bottom: 16px;
        display: flex;
        align-items: center;

        > div {
            flex: 1;
        }
    }
</style>
