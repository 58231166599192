<script>
    import Btn from '../../../components/buttons/Button';

    export default {
        props: {
            // dynamic component cannot inject event bus from the table
            // need to pass it as a prop
            eventBus: {type: Object, required: true},
            options: {type: Object, required: true},
            row: {type: Object, required: true}
        },
        components: {
            Btn
        },
        computed: {
            editUrl() {
                if (typeof this.options.edit.href === 'function') {
                    return this.options.edit.href.bind(this)(this.row);
                }

                let url = this.options.edit.href || '';
                return url.replace(':id', this.row.id);
            },
            deleteUrl() {
                if (typeof this.options.delete.href === 'function') {
                    return this.options.delete.href.bind(this)(this.row);
                }

                let url = this.options.delete.href || '';
                return url.replace(':id', this.row.id);
            },
            editHasRedirect() {
                return this.options.edit.href !== null;
            },
            deleteHasRedirect() {
                return this.options.delete.href !== null;
            }
        },
        methods: {
            onEdit(e) {
                if (this.options.edit.onClicked) {
                    this.options.edit.onClicked.bind(this).call(this, e, this.row, this.options.edit);
                }
            },
            onDelete(e) {
                if (this.options.delete.onClicked) {
                    this.options.delete.onClicked.bind(this).call(this, e, this.row, this.options.delete);
                } else {
                    this.$alertify.confirm(this.options.delete.confimation, () => {
                        this.eventBus.$emit('row.delete', this.row);
                    });
                }
            },
            isEditAllowed(options) {
                if (typeof options.edit.allow === 'function') {
                    return options.edit.allow({row: this.row, options: options});
                }
                return options.edit.allow;
            },
            isDeleteAllowed(options) {
                if (typeof options.delete.allow === 'function') {
                    return options.delete.allow({row: this.row, options: options});
                }
                return options.delete.allow;
            }
        }
    };
</script>

<template>
    <div class="row-actions">
        <component
                v-if="options.preComponent"
                v-bind:is="options.preComponent"
                v-bind:row="row"
                v-bind:options="options"
                v-bind="options.preComponentProps"
        ></component>

        <btn
                class="btn-default"
                v-if="isEditAllowed(options)"
                v-bind:href="editUrl"
                v-bind:label="options.edit.label"
                v-bind:icon="options.edit.icon"
                v-bind:iconset="options.edit.iconset"
                v-bind:class="options.edit.cssClasses"
                v-on:click="onEdit($event)"
        >
        </btn>

        <btn
                class="btn-danger"
                v-if="isDeleteAllowed(options)"
                v-bind:href="deleteUrl"
                v-bind:label="options.delete.label"
                v-bind:icon="options.delete.icon"
                v-bind:iconset="options.delete.iconset"
                v-bind:class="options.delete.cssClasses"
                v-on:click="onDelete($event)"
        >
        </btn>

        <component
                v-if="options.postComponent"
                v-bind:is="options.postComponent"
                v-bind:row="row"
                v-bind:options="options"
                v-bind="options.postComponentProps"
        ></component>
    </div>
</template>

<style lang="scss" scoped>
    .button-holder {
        display: inline-block;
    }

    .row-actions {
        text-align: right;

        > .btn {
            margin-left: 8px;
        }
    }
</style>
