import Vue from 'vue';
import './externals';
import filters from './filters';
import plugins from './plugins';
import directives from './directives';
import settings from './settings';


export function init() {
    // register filters
    Object.keys(filters).forEach(k => {
        Vue.filter(k, filters[k]);
    });

    // register directives
    Object.keys(directives).forEach(k => {
        Vue.directive(k, directives[k]);
    });

    // register plugins
    plugins.forEach(plugin => Vue.use(plugin));
}
