<script>
import PropertyResolver from '../../../mixins/resolvable-property';
import Btn from '../../../components/buttons/Button';

export default {
    mixins: [PropertyResolver],
    props: {
        // dynamic component cannot inject event bus from the table
        // need to pass it as a prop
        eventBus: { type: Object, required: true },
        options: { type: Object, required: true },
        row: { type: Object, required: true }
    },
    components: {
        Btn
    },
    computed: {
        buttonContext() {
            return {
                row: this.row,
                options: this.options
            };
        }
    },
    methods: {
        isActionAllowed(action) {
            if (action.allow === undefined) {
                action.allow = true;
            }
            if (typeof action.allow === 'function') {
                return action.allow(this.buttonContext);
            }
            return action.allow;
        },
        onClicked(e, action) {
            action.onClicked.bind(this).call(this, e, this.row, this.options);
        },
        getComponent(action) {
            return action.component || 'btn';
        }
    }
};
</script>

<template>
    <div class="row-actions">
        <component :is="getComponent(action)"
            class="btn-default"
            v-for="action in options.actions"
            v-if="isActionAllowed(action)"
            v-bind:key="action.label"
            v-bind:href="action.href"
            v-bind:label="action.label"
            v-bind:icon="action.icon"
            v-bind:iconset="action.iconset"
            v-bind:class="action.cssClasses"
            v-bind:context="buttonContext"
            v-bind="action.componentProps"
            v-on:click="onClicked($event, action)"
            >
        </component>
    </div>
</template>

<style lang="scss" scoped>
    .button-holder {
        display: inline-block;
    }

    .row-actions {
        text-align: right;

        > .btn {
            margin-left: 8px;
        }
    }
</style>
