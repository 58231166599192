<script>
    const MESSAGE = 'Are you sure you want to delete this item?';
    export default {
        props: {
            message: { type: String, default: MESSAGE },
            row: { type: Object, required: true }
        },
        inject: ['table'],
        methods: {
            async onClicked() {
                await this.$alertify.confirm(this.$t(this.message), () => {
                    this.table.delete(this.row);
                });
            }
        }
    };
</script>

<template>
    <btn danger @click="onClicked" v-bind="$attrs" v-on="$listeners">{{ 'Delete'|trans }}</btn>
</template>


