/**
 * Provides base functionality to cell types.
 * All cell types must mix it in.
 *
 * Props:
 *
 *  value  - The visible cell value.
 *  column - The column instance.
 *  editMode - Defines if component is in edit mode.
 *  row    - The row instance.
 *
 * Emits:
 *  cell-changed - when cell editted
 *      with params: column.id, value, row
 *
 *  cell-reset - when cell edit rejected
 *      with params: column, row
 *
 */
export default {
    props: {
        // dynamic component cannot inject event bus from the table
        // need to pass it as a prop
        eventBus: { type: Object, required: true },
        value: { required: true },
        column: { type: Object, required: true },
        options: { type: Object, required: false },
        editMode: { type: Boolean, required: false, default: false },
        row: { type: Object, required: true }
    },
    data() {
        return {
            val: null
        };
    },
    created() {
        this.val = this.transformed;
    },
    computed: {
        transformed() {
            return this.column.transform(this.value);
        },
        formatted() {
            return this.column.format(this.transformed);
        },
        editing() {
            return this.editMode;
        },
        editableToolbar() {
            return this.column.editableConfig['component'];
        },
        editableToolbarConfig() {
            return this.column.editableConfig['componentOptions'];
        },
        min() {
            return this.column.typeOptions.min;
        },
        max() {
            return this.column.typeOptions.max;
        }
    },
    methods: {
        submit() {
            this.$emit('cell-edit-save', this.column.id, this.val, this.row);
        },
        reset() {
            this.$emit('cell-edit-cancel', this.column, this.row);
        },
        onKeyUp(e) {
            if (e.keyCode === 13) {
                this.submit();
            }

            if (e.keyCode === 27) {
                this.reset();
            }
        }
    },
    watch: {
        editMode(newval) {
            if (newval) {
                this.$nextTick(() => {
                    this.$refs.input.focus();
                });
            }
        }
    }
};
