function getDefaultStyleOptions() {
    return {
        stripped: false,
        hover: false,
        css: {
            classes: 'table table-full table-full-small',
            inline: null
        }
    };
}

function mergeOptions(options) {
    return Object.assign({}, getDefaultStyleOptions(), options);
}

export default {
    props: {
        styling: { type: Object, default: () => {return {};} }
    },
    data() {
        return {
            appearance: mergeOptions(this.styling)
        };
    },
    computed: {
        cssClasses() {
            let classes = this.appearance.css.classes;
            if (this.appearance.hover) {
                classes += ' hover';
            }
            if (this.appearance.stripped) {
                classes += ' stripped';
            }
            return classes;
        },
        inlineStyles() {
            return this.appearance.css.inline;
        }
    }
};
