/* eslint-disable no-useless-escape */

export function camelCase(string) {
    let find = /(\_\w)/g;
    let convert = function(matches) {
        return matches[1].toUpperCase();
    };
    return string.replace(find, convert);
}

export function snakeCase(string) {
    let find = /([A-Z])/g;
    let convert = function(matches) {
        return '_' + matches.toLowerCase();
    };
    return string.replace(find, convert);
}

export function getClass(obj) {
    // Here we get a string like '[object XYZ]'
    const typeWithBrackets = Object.prototype.toString.call(obj);
    // and we extract 'XYZ' from it
    return typeWithBrackets.match(/\[object (.+)\]/)[1];
}

export function convertObjectKeys(obj, keyConversionFun) {
    if (getClass(obj) !== 'Object' && getClass(obj) !== 'Array') {
        return obj; // Primitives are returned unchanged.
    }
    return Object.keys(obj).reduce((newObj, key) => {
        newObj[keyConversionFun(key)] = convertObjectKeys(obj[key], keyConversionFun);
        return newObj;
    }, Array.isArray(obj) ? [] : {}); // preserve "arrayness"
}

export function objectKeysToCamel(obj) {
    return convertObjectKeys(obj, camelCase);
}

export function objectKeysToSnake(obj) {
    return convertObjectKeys(obj, snakeCase);
}
