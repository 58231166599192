<script>
import BaseMixin from './mixins/base';

export default {
    mixins: [BaseMixin],
    props: {
        type: String
    }
};
</script>

<template>
    <input
        :type="type"
        ref="field"
        @blur="onBlur"
        @focus="onFocus"
        @input="onInput"
        :name="name"
        :value="value"
    />
</template>
