<script>
    import CellValue from './CellValue';
    import CellMixin from './mixins';

    export default {
        components: {CellValue},
        name: 'IntegerCell',
        mixins: [CellMixin]
    };
</script>
<template>
    <td>
        <cell-value
                edit-component="number-field"
                :row="row"
                :field="field"
                :editable="editable"
                @edit="onEdited"
        >
            <slot></slot>
        </cell-value>
    </td>
</template>
