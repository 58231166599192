/**
 * A service manager.
 *
 * Contains registered services.
 * Shares services across the apps.
 */
export class ServiceManager {
    constructor() {
        this.services = {};
    }

    add(name, service) {
        this.services[name] = service;
    }

    get(name) {
        if (!this.has(name)) {
            throw new Error(`[services] Service "${name}" is not registered.`);
        }
        return this.services[name];
    }

    has(name) {
        return (name in this.services);
    }
}

export default new ServiceManager();
