import alertify from 'alertify.js';

/**
 * Alertify.js intergation with Vue.
 */
export default {
    install: function(Vue) {
        Vue.prototype.$alertify = alertify;
        Vue.alertify = alertify;
    }
};
