/**
 * Apps manager.
 *
 * Manages app's registration.
 */
export class Registry {
    constructor() {
        this.apps = {};
    }

    /**
     * Register a new app.
     * @param {*BaseApp}
     */
    register(app) {
        if (!app.name) {
            throw new Error('App name is required for registration.');
        }

        if (this.isRegistered(app.name)) {
            throw new Error(`App with name "${app.name}" is already registered.`);
        }

        this.apps[app.name] = app;
    }

    /**
     * Retruns all registered apps sorted by order.
     * @returns {Array}
     */
    all() {
        return Object.values(this.apps).sort((a, b) => {
            if (a.order === b.order) {
                return 0;
            }

            if (a.order > b.order) {
                return 1;
            }

            if (a.order < b.order) {
                return -1;
            }
        });
    }

    /**
     * Tests if app is registered.
     * @param name
     * @returns {boolean}
     */
    isRegistered(name) {
        return (name in this.apps);
    }

    /**
     * Triggers event handles.
     * @param hook
     * @param context
     */
    async callHook(hook, context) {
        const promises = [];
        this.all().forEach(async app => {
            promises.push(app[hook](context));
        });

        return Promise.all(promises);
    }

    /**
     * Get app instance by name.
     * @param name
     * @returns {*}
     */
    get(name) {
        return this.apps[name];
    }
}
