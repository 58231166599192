/**
 * Provides utilities for property value resolution.
 */
export default {
    props: {
        context: {
            type: Object, default: () => {
                return {};
            }
        }
    },

    methods: {
        /**
         * Returns a property by name.
         *
         * @param {String} field
         * @return {String|Function}
         */
        getPropValue(field) {
            return this.$options.propsData[field];
        },

        /**
         * Resolves property's value.
         * If property is a function, then it will be executed
         * and receive a `context` property as the first argument.
         *
         * @param {String} field
         * @return any
         */
        resolvePropValue(field) {
            const value = this.getPropValue(field);
            if (typeof value === 'function') {
                return value(this.context);
            }
            return value;
        }
    }
};
