<script>
    import BaseMixin from './mixins/base';

    export default {
        name: 'PasswordField',
        mixins: [BaseMixin],
        props: {
            autocomplete: { type: String, default: 'new-password' }
        },
        data() {
            return {
                type: 'password'
            };
        },
        methods: {
            show() {
                this.type = 'text';
            },

            hide() {
                this.type = 'password';
            }
        }
    };
</script>

<template>
    <div class="password-field">
        <input
                class="form-control"
                ref="field"
                :type="type"
                :disabled="disabled"
                :name="name"
                :placeholder="placeholder"
                :autocomplete="autocomplete"
                :readonly="readonly"
                :required="required"
                :value="value"
                v-bind="$attrs"
                @blur="onBlur"
                @focus="onFocus"
                @change="onChange"
                @input="onInput"
        />

        <div class="icon" @mousedown="show" @mouseup="hide" :title="'Reveal password'|trans">
            <i class="fa fa-eye"></i>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .password-field {
        position: relative;

        .icon {
            display: none;
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 32px;
            cursor: pointer;
            align-items: center;
            justify-content: center;
        }

        &[reveal] {
            .icon {
                display: flex;

                &:active {
                    color: #999;
                }
            }
        }
    }
</style>
