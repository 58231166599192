<script>
/**
 * Text cell.
 *
 * Renders a plain string.
 * Renders a <input type="text" /> when in edit mode.
 *
 * See ColumnMixin for more docs.
 */

import ColumnMixin from './ColumnMixin';

export default {
    mixins: [ColumnMixin],
    name: 'TextCell',
    props: {
        rows: {
            type: Number,
            default: 2
        }
    },
    computed: {
        titleIfTruncated() {
            if (this.formatted.length !== this.value.length) {
                return this.value;
            }
        }
    },
    methods: {
        onKeyUp(e) {
            if (e.keyCode === 13 && e.ctrlKey) {
                this.submit();
            }

            if (e.keyCode === 27) {
                this.reset();
            }
        }
    }
};
</script>

<template>
    <div v-if="editing">
        <div>
            <textarea
                class="form-control"
                ref="input"
                v-autosize
                v-bind:maxlength="max"
                v-bind:minlength="min"
                v-bind:placeholder="column.label"
                v-bind:rows="rows"
                v-model="val"
                v-on:keyup="onKeyUp"
                ></textarea>
        </div>

        <component
            v-bind:is="editableToolbar"
            v-bind:config="editableToolbarConfig"
            v-on:accepted="submit"
            v-on:rejected="reset"
        >
        </component>

    </div>
    <span v-else v-bind:title="titleIfTruncated">{{ formatted }}</span>
</template>

<style scoped>
textarea {
    font-size: 13px !important;
    min-height: auto;
}
</style>
