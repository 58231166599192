import AsyncComputed from 'vue-async-computed';
import Shortkey from 'vue-shortkey';
import VueMeta from 'vue-meta';

import ServiceManagerPlugin from './services';
import HttpPlugin from './http';
import CookiesPlugin from './cookies';
import CachePlugin from './cache';

export default [HttpPlugin, ServiceManagerPlugin, CookiesPlugin, CachePlugin, AsyncComputed, Shortkey, VueMeta];


