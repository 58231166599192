<script>
    import AppMenu from './Menu';
    import AppSidebar from './Sidebar';

    export default {
        components: { AppMenu, AppSidebar }
    };
</script>
<template>
    <div class="app-main-area">
        <slot name="menu">
            <app-menu></app-menu>
        </slot>

        <slot name="sidebar">
            <app-sidebar></app-sidebar>
        </slot>

        <slot></slot>
    </div>
</template>

<style lang="scss" scoped>
    .app-main-area {
        padding-top: 64px;
        display: flex;
        flex: 1;
    }
</style>
