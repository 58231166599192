import Vue from 'vue';
import HeaderCell from './HeaderCell';
import Cell from './Cell';
import StringCell from './StringCell';
import IntegerCell from './IntegerCell';
import ActionsCell from './ActionsCell';

Vue.component('mHeaderCell', HeaderCell);
Vue.component('mTableCell', Cell);
Vue.component('mTableCellString', StringCell);
Vue.component('mTableCellInteger', IntegerCell);
Vue.component('mTableCellActions', ActionsCell);
