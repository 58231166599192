<script>
    import EditToolbar from './EditToolbar';

    export default {
        props: {
            editable: Boolean,
            editComponent: { type: String, default: 'text-field' },
            row: Object,
            field: String
        },
        components: { EditToolbar },
        data() {
            return {
                originalValue: this.value,
                localValue: null,
                editMode: false
            };
        },
        created() {
            if (this.editable) {
                this.localValue = this.row[this.field];
                this.originalValue = this.row[this.field];
            }
        },
        methods: {
            toggleEdit() {
                if (this.editable) {
                    this.editMode = !this.editMode;
                }

                if (this.editable) {
                    this.$nextTick(_ => {
                        const input = this.$el.querySelector('input,select,textarea');
                        if (!input) {
                            console.warn('[table] [edit cell] not edit inputs in cell.');
                            return;
                        }
                        input.focus();

                        input.addEventListener('keyup', this.onInputKeyUp.bind(this));
                    });
                }
            },
            onEdited() {
                this.editMode = false;
                this.$emit('edit', this.localValue, this.field, this.row);
            },
            onEditCancelled() {
                this.editMode = false;
                this.localValue = this.originalValue;
            },
            onInputKeyUp(e) {
                if (e.keyCode === 13 && e.ctrlKey) { // enter
                    this.onEdited();
                }

                if (e.keyCode === 27) { // esc
                    this.onEditCancelled();
                }
            }
        },
        watch: {
            row(row) {
                this.localValue = row[this.field];
                this.originalValue = row[this.field];
            }
        }
    };
</script>

<template>
    <edit-toolbar v-if="editMode" @accept="onEdited" @reject="onEditCancelled">
        <component :is="editComponent" v-model="localValue" v-bind="$attrs"></component>
    </edit-toolbar>
    <span v-else @click="toggleEdit" class="editable-cell" :class="{hoverable: editable}" tabindex="-1">
        <slot>{{ localValue }}</slot>
    </span>
</template>

<style lang="scss" scoped>
    .editable-cell {
        display: block;
        min-height: 24px;

        &.hoverable:hover {
            background: seashell;
        }
    }
</style>
