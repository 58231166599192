<script>
    import range from 'lodash/range';

    export default {
        props: {
            // total items
            total: {type: [Number, String], default: 0},
            // page size
            size: {type: [Number, String], default: 20},
            // amount of rendered pages
            visible: {type: [Number, String], default: 15},
            firstLabel: {type: String, default: 'First'},
            lastLabel: {type: String, default: 'Last'},
            prevLabel: {type: String, default: 'Previous'},
            nextLabel: {type: String, default: 'Next'},
            value: [Number, String]
        },
        computed: {
            activePage() {
                return parseInt(this.value);
            },
            totalPages() {
                return this.allPages.length;
            },
            allPages() {
                return range(1, Math.ceil(this.total / this.size) + 1);
            },
            pages() {
                // all pages are in visible range
                if (this.totalPages <= this.visible) {
                    return range(1, this.totalPages + 1);
                }

                // compute median
                let median = Math.ceil(this.visible / 2);
                var lower = null;
                var upper = null;
                if (this.activePage - median > this.totalPages - this.visible) {
                    lower = this.totalPages - this.visible + 1;
                    upper = this.totalPages;
                } else {
                    if (this.activePage - median < 0) {
                        median = this.activePage;
                    }
                    let offset = this.activePage - median;
                    lower = offset + 1;
                    upper = offset + this.visible;
                }
                return range(lower, upper + 1);
            },
            nextPage() {
                return Math.min(this.activePage + 1, this.totalPages);
            },
            prevPage() {
                return Math.max(1, this.activePage - 1);
            },
            hasNext() {
                return (this.activePage + 1) <= this.totalPages;
            },
            hasPrev() {
                return (this.activePage - 1) > 0;
            }
        },
        methods: {
            isValid(page) {
                return (page !== this.activePage && this.allPages.indexOf(page) !== -1);
            },
            onPageSelected(page) {
                if (this.isValid(page)) {
                    this.$emit('page-selected', page);
                    this.$emit('input', page);
                }
            }
        }
    };
</script>

<template>
    <div class="pager" v-if="totalPages > 1">
        <nav>
            <ul class="pagination">
                <li :class="{disabled: activePage == 1}">
                    <a href="#" @click.prevent="onPageSelected(1)">
                        <span>{{ firstLabel | trans}}</span>
                    </a>
                </li>
                <li v-bind:class="{disabled: !hasPrev}">
                    <a href="#" @click.prevent="onPageSelected(prevPage)">
                        <span>{{ prevLabel | trans}}</span>
                    </a>
                </li>
                <li v-for="page in pages" v-bind:key="'page' + page" :class="{active: page == activePage}">
                    <a href="#" @click.prevent="onPageSelected(page)">{{ page }}</a>
                </li>
                <li :class="{disabled: !hasNext}">
                    <a href="#" @click.prevent="onPageSelected(nextPage)">
                        <span>{{ nextLabel | trans }}</span>
                    </a>
                </li>
                <li :class="{disabled: activePage == totalPages}">
                    <a href="#" @click.prevent="onPageSelected(totalPages)">
                        <span>{{ lastLabel | trans }}</span>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<style lang="scss" scoped>
    .pager {
        padding: 20px;
        padding-bottom: 10px;
        margin: 0;
        white-space: nowrap;
        text-align: right;

        ul.pagination {
            margin: 2px 0;
            white-space: nowrap;

            li {
                display: inline;

                &.disabled {
                    a {
                        color: #bbb;
                        background-color: #fff;
                    }
                }

                a {
                    border-radius: 0;

                    &:first-child {
                        margin-left: 0;
                        border-bottom-left-radius: 3px;
                        border-top-left-radius: 3px;
                        border: 1px solid #F9F9F9;
                    }
                }

                &.active {
                    a {
                        z-index: 3;
                        color: #fff;
                        background-color: #e91e63;
                        border-color: #e91e63;
                        cursor: default;
                    }
                }
            }
        }
    }
</style>
