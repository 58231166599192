<script>
/**
 * Edit cell toolbar.
 *
 * This component is shown when cell is in editable mode.
 * It renders "save" and "reset" buttons.
 */

export default {
    props: {
        config: {
            type: Object,
            default() {
                return {
                    saveLabel: 'save',
                    resetLabel: 'reset'
                };
            }
        }
    },
    methods: {
        onAccepted(e) {
            e.stopPropagation();
            this.$emit('accepted');
        },
        onRejected(e) {
            e.stopPropagation();
            this.$emit('rejected');
        }
    }
};
</script>

<template>
    <div class="cell-toolbar">
        <a href="#" v-on:click.prevent="onAccepted">{{ config.saveLabel }}</a>
        <a v-if="config.resetLabel" href="#" v-on:click.prevent="onRejected">{{ config.resetLabel }}</a>
    </div>
</template>

<style scoped>
div {
    text-align: right;
}
</style>
