<script>
/**
 * Renders table header.
 *
 * The reason of having this component is to improve table
 * outline in chrome devtools.
 */
import HeaderRow from './HeaderRow';

export default {
    props: {
        cols: {
            type: Array,
            required: true
        }
    },
    components: {
        HeaderRow
    }
};
</script>

<template>
    <thead>
        <header-row v-bind:cols="cols"></header-row>
    </thead>
</template>
