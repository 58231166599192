export { default as changeCase } from 'change-case';
import changeCase from 'change-case';
import merge from 'lodash/merge';
import { http } from 'metro-core';
import { Validator } from 'vee-validate';
import datatable from './components/datatable';
import { dereactivate } from './utils';

import BaseFieldMixin from './components/forms/fields/mixins/base';
import { DynamicRouteView, ExtraRoutes } from './components/router';
import URLSearchParams from 'url-search-params';

export { http };
export { default as moment } from 'moment';
export { default as URLSearchParams } from 'url-search-params';
export { App as FrontendApp } from './app';
export * from './utils';
export { installComponents } from './bootstrap';

export const App = {
    Components: {
        DynamicRouteView
    }
};

export const Form = {
    Field: {
        BaseFieldMixin
    },
    Validator
};

export const REST = {
    Resource: http.Resource
};

export const Components = {
    DataTable: datatable
};
export const Router = {
    ExtraRoutes
};

export const Utils = {
    merge, URLSearchParams, changeCase, dereactivate
};

export const axios = http.axios;
export const httpClient = http.httpClient;
export const defaultHttpClient = http.defaultHttpClient;
