import ListView from '../components/ListView';
import ListLayoutView from '../components/ListLayoutView';
import { FieldType } from '../constants';
import { Page } from './page';

export class ListPage extends Page {
    constructor(...args) {
        super(...args);
        this.component = ListView;
        this.columns = [];
        this.rowActions = [];
        this.headerActions = [];
        this.createButtonLabel = 'Create an item';
        this.isSearchable = true;
        this.title = 'All items';
        this._datasource = null;

        this.createButtonComponent = 'm-table-btn-create';
        this.editButtonComponent = 'm-table-btn-edit';
        this.deleteButtonComponent = 'm-table-btn-delete';
    }

    setColumns(columns) {
        this.columns = columns;
        return this;
    }

    insertColumns(index, columns) {
        this.columns.splice(index, 0, ...columns);
    }

    /**
     * Returns a list of component to be rendered per each row.
     * @returns {Array|*}
     */
    getRowActions() {
        return this.rowActions;
    }

    /**
     * Replace row actions with a new ones.
     * @param actions
     * @returns {ListPage}
     */
    setRowActions(actions) {
        this.rowActions = actions;
        return this;
    }

    /**
     * Add a new row action.
     * @param action
     * @returns {ListPage}
     */
    addRowAction(action) {
        this.rowActions.push(action);
        return this;
    }

    /**
     * Returns a list of components to be rendered in the top section of table.
     * @returns {Component[]}
     */
    getHeaderActions() {
        return this.headerActions;
    }

    /**
     * Replace header actions components with a new set.
     * @param {Component[]} actions
     * @returns {ListPage}
     */
    setHeaderActions(actions) {
        this.headerActions = actions;
        return this;
    }

    /**
     * Add another header action.
     * @param {Component} action
     * @returns {ListPage}
     */
    addHeaderAction(action) {
        this.headerActions.push(action);
        return this;
    }

    getCreateButtonLabel() {
        return this.createButtonLabel;
    }

    setCreateButtonLabel(label) {
        this.createButtonLabel = label;
        return this;
    }

    setSearchable(flag) {
        this.isSearchable = !!flag;
        return this;
    }

    getTableMapping() {
        const flatColumns = this.getFlatMapping();
        return Object.keys(flatColumns).filter(f => this.columns.includes(f)).map(field => {
            return flatColumns[field];
        });
    }

    getFlatMapping() {
        const flatColumns = {};
        Object.keys(this.admin.mapping).forEach(key => {
            const definition = this.admin.mapping[key];
            if (definition.type === FieldType.TRANSLATIONS) {
                Object.keys(definition.children).forEach(childKey => {
                    const child = definition.children[childKey];
                    child.name = key + '__' + childKey;
                    flatColumns[key + '__' + childKey] = child;
                });
            } else {
                flatColumns[key] = this.admin.mapping[key];
            }
        });
        return flatColumns;
    }

    setCreateButtonComponent(component) {
        this.createButtonComponent = component;
        return this;
    }

    setEditButtonComponent(component) {
        this.editButtonComponent = component;
        return this;
    }

    setDeleteButtonComponent(component) {
        this.deleteButtonComponent = component;
        return this;
    }

    getTitle() {
        if (!this.title) {
            this.title = 'All ' + this.admin.pluralObjectName;
        }
        return this.title;
    }

    getRouteName() {
        return this.admin.generateRouteName(this.app.name, this.admin.name, 'list');
    }

    getRoutePath() {
        return '';
    }

    getRoutes() {
        return {
            path: '',
            component: ListLayoutView,
            props: {
                admin: this.admin,
                app: this.admin.app,
                page: this
            },
            children: [
                {
                    name: this.getRouteName(),
                    path: '',
                    component: ListView,
                    props: {
                        admin: this.admin,
                        app: this.admin.app,
                        page: this
                    }
                },
                ...this.getCustomPages().map(page => {
                    return page.getRoutes();
                })
            ]
        };
    }

    setDataSource(ds) {
        this._datasource = ds;
        return this;
    }

    getDataSource() {
        if (this._datasource) {
            return this._datasource;
        }
        return 'store:' + this.admin.app.name + '.' + this.admin.name;
    }
}
