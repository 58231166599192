<script>
    import DefaultActionButton from './DefaultActionButton';

    const DEFAULT_ACTION_BUTTON_OPTIONS = {
        label: '<Table Action>',
        icon: 'plus',
        iconset: 'fa',
        href: null,
        route: null,
        cssClasses: '',
        onTriggered: null,
        component: DefaultActionButton
    };

    /**
     * HeaderActions component.
     *
     * Adds action buttons to the table header.
     */
    export default {
        props: {
            actions: {
                type: [Array, String],
                required: false,
                default: () => []
            }
        },
        data() {
            return {
                actionList: []
            };
        },
        created() {
            this.map(this.actions);
        },
        computed: {
            allActions() {
                return this.actionList;
            }
        },
        methods: {
            addAction(action) {
                this.actionList.push(
                    Object.assign({}, DEFAULT_ACTION_BUTTON_OPTIONS, action)
                );
            },
            map(actions) {
                actions.map(action => this.addAction(action));
            }
        },
        watch: {
            actions(newval) {
                this.actionList = [];
                this.map(newval);
            }
        }
    };
</script>

<template>
    <div class="header-actions">
        <slot>
            <component
                    v-for="action in allActions"
                    v-bind:key="action.label"
                    v-bind:config="action"
                    v-bind:is="action.component"
            >
            </component>
        </slot>
    </div>
</template>

<style lang="scss" scoped>
    .header-actions {
        text-align: right;
        padding-top: 16px;
        padding-bottom: 16px;

        > .btn {
            margin-left: 8px;
        }
    }
</style>
