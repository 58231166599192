<script>
export default {
    props: {
        href: String,
        icon: String,
        active: Boolean,
        iconset: 'md'
    },
    data() {
        return {
            submenu: null,
            isExpanded: this.active
        };
    },
    mounted() {
        const submenu = this.detectSubMenu();
        if (submenu) {
            this.submenu = submenu;
            this.submenu.isSubmenu = true;
            this.submenu.isExpanded = this.isExpanded;
        }
    },
    computed: {
        linkHref() {
            if (this.href) {
                return this.href;
            }
            return 'javascript:void(0);';
        },
        linkClasses() {
            return {
                'active': this.isExpanded,
                'collapsed': !this.isExpanded
            };
        }
    },
    methods: {
        detectSubMenu() {
            const menus = this.$children.filter(c => c.hasOwnProperty('isMenu'));
            if (menus.length > 0) {
                return menus[0];
            }
            return false;
        },
        handleCollapsible() {
            this.isExpanded = !this.isExpanded;
            this.submenu.isExpanded = this.isExpanded;
        },
        onItemClick(e) {
            if (!this.href) {
                e.preventDefault();
                this.$emit('click', e);
            }
        }
    }
};
</script>

<template>
    <li>
        <a
            class="collapsible-header waves-effect"
            :class="{active: this.isExpanded}"
            ref="item"
            v-if="submenu"
            :href="linkHref"
            @click="handleCollapsible"
            >
            <icon :name="icon" :set="iconset"></icon>&nbsp;
            <slot></slot>
        </a>

        <a
            ref="item"
            v-else
            :class="{active: this.active}"
            :href="linkHref"
            @click="onItemClick"
            >
            <icon :name="icon" :set="iconset"></icon>&nbsp;
            <slot></slot>
        </a>

        <slot name="submenu" class="collapsed"></slot>
    </li>
</template>


