<script>
export default {
    props: {
        onText: String,
        offText: String,
        value: { required: false },
        disabled: Boolean,
        readonly: Boolean
    },
    computed: {
        cssClasses() {
            return {
                switch: true,
                disabled: this.disabled,
                readonly: this.readonly
            };
        }
    },
    methods: {
        onChange() {
            this.$emit('input', !this.value);
        }
    }
};
</script>

<template>
    <div :class="cssClasses">
        <label>
            <span>{{ offText }}</span>
            <input
                type="checkbox"
                v-bind:checked="value"
                v-on:change="onChange"
                v-bind:disabled="disabled"
                v-bind:readonly="readonly"
                />
            <span class="lever"></span>
            <span>{{ onText }}</span>
        </label>
    </div>
</template>

<style scoped lang="scss">
.switch {
    &.disabled {
        cursor: not-allowed;
        .lever {
            background-color: #e0e0e0!important;
            cursor: not-allowed!important;
            &::after {
                background-color: #e0e0e0!important;
            }
        }
    }
}
</style>
