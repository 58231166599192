<script>
    export default {
        name: 'SelectFieldOption',
        inject: ['selectField'],
        computed: {
            checked() {
                if (this.selectField.value.constructor === Array) {
                    console.log(this.$attrs['value'], this.selectField.value, this.selectField.value.includes(this.$attrs['value']));
                    return this.selectField.value.includes(this.$attrs['value']);
                }

                return this.selectField.value === this.$attrs['value'];
            }
        }
    };
</script>

<template>
    <option v-bind="$attrs" :checked="checked">
        <slot></slot>
    </option>
</template>
