<script>
/**
 * A form component.
 *
 * Renders form elements, validates and show messages to user.
 *
 * Exported as "m-form" component.
 * Signature: <m-form [@submit] [@reset]></m-form>
 *
 * Usage (with all required subcomponents):
 *  <m-form @submit="onSubmit">
 *      <form-group label="Field label" help="Field help">
 *          <text-field name="fieldName" value="a" v-model="a" required />
 *      </form-group>
 *      <form-actions>
 *          <btn primary type="submit" label="Submit" />
 *      </form-actions>
 *  </m-form>
 *
 */
import { Validator } from 'vee-validate';

export default {
    name: 'Form',
    props: {
        floating: { type: Boolean, default: true },
        legend: String,
        help: String
    },
    data() {
        return {
            formGroups: [],
            snackbarVisible: false,
            messages: '',
            messagesStyle: 'success',
            useHTMLValidation: true,
            validator: new Validator()
        };
    },
    provide() {
        return {
            form: this,
            validator: this.validator
        };
    },
    created() {
        Object.defineProperty(this, '$validator', {
            get() {
                return this.validator;
            }
        });
    },
    computed: {
        isValid() {
            return this.validator.errors.count() === 0;
        },
        cssClasses() {
            const list = [];
            if (this.floating) {
                list.push('form-floating');
            }
            return list;
        }
    },
    mounted() {
        // report initial validity state
        this.$emit('input', this.isValid);

        // special case here, if TranslatableGroup is in children, disable native form validation
        this.useHTMLValidation = this.$el.querySelectorAll('.translatable-group').length === 0;
    },
    methods: {
        /**
         * Set errors per each field.
         *
         * NOTE: this is a stub for future validator.
         */
        setFieldErrors(errors) {
            for (const group of this.formGroups) {
                if (group.field.name in errors) {
                    group.setFieldErrors(errors[group.field.name]);
                }
            }
        },

        /**
         * Handle axios HTTP response and show message\field errors.
         */
        handleResponse(response) {
            if (!('data' in response)) {
                return false;
            }

            let messages = response.data.messages || [];

            if (response.status < 400) { // 2xx, 3xx
                if ('detail' in response.data) {
                    messages.push(response.data.detail);
                }

                if (messages.length) {
                    this.setPositiveMessage(messages.join(' '));
                }
            } else { // 4xx, 5xx
                this.setFieldErrors(response.data);

                // non field errors
                if ('nonFieldErrors' in response.data) {
                    response.data['nonFieldErrors'].forEach(msg => messages.push(msg));
                }

                if (messages.length) {
                    this.setNegativeMessage(messages.join(' '));
                } else {
                    this.setNegativeMessage(this.$t('Error saving data.'));
                }
            }
        },

        /**
         * Register a form group.
         */
        addFormGroup(group) {
            this.formGroups.push(group);
            if (group.field && group.field.rules) {
                group.bindValidator(this.validator);
            }
        },

        /**
         * Unregister a form group.
         */
        removeFormGroup(group) {
            this.formGroups = this.formGroups.filter(g => g !== group);
        },

        /**
         * Show positive statue message.
         */
        setPositiveMessage(messages) {
            return this._showMessage('success', messages);
        },

        /**
         * Show negative statue message.
         */
        setNegativeMessage(messages) {
            return this._showMessage('error', messages);
        },

        _showMessage(type, messages) {
            if (messages.constructor === Array) {
                messages = messages.join('. ');
            }

            if (type === 'success') {
                this.$alertify.success(messages);
            }

            if (type === 'error') {
                this.$alertify.error(messages);
            }
        },

        /**
         * Remove messages.
         */
        clearMessages() {
            this.snackbarVisible = false;
            setTimeout(_ => {
                this.messages = null;
                this.messagesStyle = null;
            }, 400);
        },

        /**
         * Returns underlying alert component.
         */
        getAlert() {
            return this.$refs.alert;
        },

        /**
         * Submit form.
         * Triggers "submit" event.
         *
         * Note, "submit" event is not triggered here by design
         * @link https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/submit
         */
        submit() {
            this.$refs.form.submit();
        },

        /**
         * Reset form.
         * Triggers "reset" event.
         */
        reset() {
            this.$refs.form.reset();
        },

        /**
         * Submit handler.
         * Emits Vue-bound "submit" event.
         */
        onSubmit(e) {
            this.$emit('submit', e);
        },

        /**
         * Reset handler.
         * Emits Vue-bound "reset" event.
         */
        onReset(e) {
            for (const group of this.formGroups) {
                group.setInputValue(null);
            }
            this.$emit('reset', e);
        },

        async validate() {
            const isValid = await this.validator.validateAll();
            if (!isValid) {
                this.setFieldErrors(this.validator.errors.collect());
            }
            this.$emit('input', isValid);
            return isValid;
        }
    },
    watch: {
        'validator.errors': {
            handler(errors) {
                this.$emit('input', errors.count() === 0);
            },
            deep: true
        }
    }
};
</script>

<template>
    <form ref="form" :class="cssClasses" @submit.prevent="onSubmit" @reset.prevent="onReset" :novalidate="!useHTMLValidation">
        <fieldset>
            <legend v-if="legend">{{ legend }}</legend>
            <span class="help-block" v-if="help">{{ help }}</span>

            <alert ref="alert" class="messages" autofocus closeable autohide :timeout="3000"></alert>
            <slot></slot>
        </fieldset>

        <snackbar :type="messagesStyle" :active.sync="snackbarVisible">
            {{messages}}
            <slot name="snackbar-action"></slot>
        </snackbar>
    </form>
</template>


<style lang="scss" scoped>
.messages {
    margin: 24px 0;
}
</style>
