<script>
export default {
    props: {
        source: String
    },
    data() {
        return {
            component: null
        };
    },
    created() {
        this.component = Vue.extend({
            template: this.source
        });
    }
};
</script>

<template>
    <component :is="component" />
</template>

