<script>
    /**
     *  A dynamic router view.
     *  Used in routes where the actual component instance depends on URL part.
     *  @deprecated
     */
    export default {
        name: 'DynamicRouteView',
        data() {
            return {
                component: null
            };
        },
        render(h) {
            return h(this.component, {
                attrs: this.$attrs,
                on: this.$listeners,
            });
        },
        created() {
            console.warn('[DynamicRouteView] is deprecated. Refactor your code with admin generator', this.$route.meta);
            if (!this.$route.meta.hasOwnProperty('routes')) {
                throw new Error('DynamicRouteView requies "routes" meta option to be set.');
            }
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                to.meta.routes.all().map(p => {
                    if (p.path === to.params.path) {
                        vm.component = p.component;
                        vm.$store.dispatch('frontend.current-page.set-title', p.name);
                    }
                });
            });
        },
        beforeRouteUpdate(to, from, next) {
            to.meta.routes.all().map(p => {
                if (p.path === to.params.path) {
                    this.component = p.component;
                    this.$store.dispatch('frontend.current-page.set-title', p.name);
                }
            });
            next();
        }
    };
</script>
