<script>
import ColumnMixin from './ColumnMixin';

export default {
    mixins: [ColumnMixin],
    name: 'MoneyCell',
    computed: {
        step() {
            return this.column.typeOptions.step;
        }
    }
};
</script>

<template>
    <div v-if="editing">
        <div>
            <input
                v-on:keyup="onKeyUp"
                type="text"
                class="form-control"
                v-bind:min="min"
                v-bind:max="max"
                v-bind:step="step"
                v-model="val"
                v-bind:placeholder="column.label"
                ref="input"
                />
        </div>

        <component
            v-bind:is="editableToolbar"
            v-bind:config="editableToolbarConfig"
            v-on:accepted="submit"
            v-on:rejected="reset"
            >
        </component>

    </div>
    <span v-else>{{ formatted }}</span>
</template>

<style scoped>
input {
    font-size: 13px !important;
    height: 23px;
}
</style>
