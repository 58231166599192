<script>
    export default {
        props: {
            app: {required: true, type: Object},
            admin: {required: true, type: Object}
        },
        computed: {
            items() {
                return this.app.getAdminsArray().map(admin => {
                    return {
                        name: admin.name,
                        permissions: admin.getListPage().getPermissions(),
                        route: admin.getIndexRouteName(),
                        title: admin.title
                    };
                });
            }
        }
    };
</script>

<template>
    <nav-menu>
        <router-link
                :to="{ name: item.route }"
                :key="item.name"
                v-for="item in items"
                v-if="$canAll(item.permissions)">
            {{ item.title|trans }}
        </router-link>
    </nav-menu>
</template>


