<script>
import debounce from 'lodash/debounce';

export default {
    provide() {
        return {
            tabs: this
        };
    },
    data() {
        return {
            activeTab: null,
            tabs: []
        };
    },
    methods: {
        selectDefaultTab() {
            if (this.activeTab) {
                return;
            }

            this.activeTab = this.selectTabByRouteName();

            // if no tab found by route, activate the first one
            if (!this.activeTab) {
                this.activeTab = this.tabs[0];
            }
            this.activeTab.activate();
        },

        /**
         * Search a tab which has "route" property
         * and route matches the current route.
         */
        selectTabByRouteName() {
            let tab = null;
            this.tabs.map(t => {
                if (t.route && t.route.name === this.$route.name) {
                    tab = t;
                }
            });
            return tab;
        },
        activateTab(tab) {
            if (tab.disabled) {
                return;
            }

            if (this.activeTab) {
                this.activeTab.deactivate();
            }
            tab.activate();
            this.activeTab = tab;
            this.$emit('activated', tab);
        },
        addTab(tab) {
            this.tabs.push(tab);
            if (tab.isActive) {
                this.activateTab(tab);
            }
        },
        removeTab(tab) {
            this.tabs = this.tabs.filter(t => tab !== t);
        },
        cssClasses(tab) {
            return {
                disabled: tab.disabled,
                active: (tab === this.activeTab)
            };
        }
    },
    watch: {
        'tabs.length'() {
            debounce(() => {
                this.selectDefaultTab();
            }, 50)();
        },
        '$route'(route) {
            let tab = this.selectTabByRouteName();
            if (tab) {
                this.activateTab(tab);
            }
        }
    }
};
</script>

<template>
<div>
    <slot name="tab-bar">
        <ul class="nav nav-tabs">
            <slot name="tab-bar-inner">
                <li v-for="tab in tabs" :class="cssClasses(tab)" :key="tab.uid">
                    <slot name="tab" scope="tab">
                        <a href="#" v-on:click.prevent="activateTab(tab)">
                            <icon v-if="tab.icon" :name="tab.icon" :iconset="tab.iconset" />
                            {{ tab.label }}
                        </a>
                    </slot>
                </li>
            </slot>
        </ul>
    </slot>
    <div class="tab-content">
        <slot></slot>
    </div>
</div>
</template>

<style lang="scss" scoped>
.nav-tabs {
    li {
        &.disabled {
            a {
                box-shadow: none;
                &:hover {
                    color: #bbb;
                    text-decoration: none;
                    cursor: not-allowed;
                }
            }
        }
    }
}
</style>

