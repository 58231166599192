<script>
    /**
     * A form group.
     * Corresponds to Bootstrap 3 .form-group element.
     * Renders label, help, required asterisk and validation messages.
     *
     * Refer to form component for usage.
     *
     * Signature: <form-group [:label] [:help] ></form-group>
     */
    export default {
        name: 'FormGroup',
        props: {
            label: String,
            help: String
        },
        data() {
            return {
                field: null, // a field instance
                form: null, // a closest form instance
                isFocused: false, // is field is focused by user
                isValid: true,
                isActive: true, // if true, label is in active state
                labelCssClasses: ['control-label'], // css class list of label,
                errorMessages: null,
                isInitialized: false
            };
        },
        provide() {
            return {
                formGroup: this
            };
        },
        inject: {
            transPane: {default: null},
            validator: {default: null}
        },
        mounted() {
            for (const c of this.$children) {
                // radio buttons require a special class added to group
                if (c.useFixedLabel) {
                    this.labelCssClasses.push('normal');
                    this.labelCssClasses.push('top-margin');
                }

                if (c.isInput) {
                    this.bindVueField(c);
                    break;
                }
            }

            // if no field found, search for html inputs
            if (!this.field) {
                const elems = this.$el.querySelectorAll('select, input');
                for (const el of elems) {
                    this.bindHTMLField(el);
                }
            }

            this.form = this.findForm();
            if (this.form && this.field) {
                this.form.addFormGroup(this);
            }

            if (this.transPane) {
                this.transPane.attach(this);
            }

            this.isInitialized = true;
        },
        beforeDestroy() {
            // when form has fields which are conditionally shown (eg. translatable)
            // an error occurs while detaching the field.
            try {
                if (this.form && this.field) {
                    this.form.removeFormGroup(this);
                }
                if (this.validator && this.field) {
                    this.validator.detach(this.field.name);
                }
            } catch (skip) {

            }
        },
        computed: {
            cssClasses() {
                return {
                    active: this.isActive,
                    'has-error': this.errorMessages !== null
                };
            },

            /**
             * Test, if field has a value.
             * Used to determine the floating label position.
             * Empty array is considered as false.
             */
            fieldHasValue() {
                if (!this.field) {
                    return false;
                }

                // if field has a placeholder, always activate label
                if (this.field.placeholder) {
                    return true;
                }

                // look for "value" property in field
                if (this.field instanceof HTMLSelectElement && this.field.value) {
                    return true;
                }

                // shouldBeActive() must be a function which returns boolean value.
                if (typeof this.field.shouldBeActive === 'function') {
                    return this.field.shouldBeActive();
                }

                if (!this.field.localValue) {
                    return false;
                }

                if (this.field.localValue.constructor === Array) {
                    if (this.field.localValue.length > 0) {
                        return true;
                    } else {
                        return false;
                    }
                }

                return !!this.field.localValue;
            }
        },
        methods: {
            bindVueField(field) {
                this.field = field;
                this.field.$on('focus', this.onFocus);
                this.field.$on('blur', this.onBlur);
                this.field.$on('input', this.onInput);
                this.field.$on('value-change', this.onInput);

                // if child already has a value, set this form group as active.
                this.isActive = this.fieldHasValue;
            },
            bindHTMLField(field) {
                this.field = field;
                this.field.addEventListener('focus', this.onFocus);
                this.field.addEventListener('blur', this.onBlur);
                this.field.addEventListener('input', this.onInput);
                this.isActive = this.fieldHasValue;
            },

            /**
             * "focus" event handler.
             * Used for corrent positioning of the floating label.
             */
            onFocus() {
                this.isActive = true;
                this.isFocused = true;
            },

            /**
             * "blur" event handler.
             * Used for corrent positioning of the floating label.
             */
            onBlur() {
                this.isFocused = false;
                this.isActive = this.fieldHasValue;
            },

            /**
             * "input" event handler.
             * If field value is empty, then label is considered as inactive.
             */
            onInput(value) {
                this.setFieldErrors(null);

                if (this.isFocused) {
                    return false;
                }

                if (!value || !this.field.localValue) {
                    this.isActive = false;
                    return;
                }

                if (this.field.localValue.constructor === Array) {
                    this.isActive = (this.field.localValue.length > 0);
                } else {
                    this.isActive = true;
                }
            },

            /**
             * Set a field value.
             */
            setInputValue(value) {
                this.field.setValue(value);
            },

            /**
             * Find the closest parent form.
             */
            findForm() {
                let component = this;
                while (true) {
                    if (component.$options.name === 'Form') {
                        return component;
                    }
                    component = component.$parent;
                    if (!component) {
                        break;
                    }
                }
            },

            /**
             * Show field errors.
             * @param {string[]|string|null} errors
             */
            setFieldErrors(errors) {
                if (errors) {
                    if (errors.constructor === String) {
                        errors = [errors];
                    }
                }
                this.errorMessages = errors;
            },

            /**
             * Bind local field to validator and subscribe for field events.
             * This clears field error when user types in field.
             * This validates field when field lose focus.
             */
            bindValidator(validator) {
                validator.attach({
                    name: this.field.name, rules: this.field.rules,
                    el: this.field.$el, alias: this.field.alias || this.field.name,
                    vm: this.form, listen: true, component: this.field.$vnode.child,
                    getter: f => {
                        return this.field.localValue;
                    }
                });

                // clear field errors when user starts typing
                this.field.$on('input', f => {
                    this.setFieldErrors([]);
                });

                // validate when field model value changed
                this.field.$on('change', async f => {
                    if (!await validator.validate(this.field.name, this.field.localValue)) {
                        this.setFieldErrors(validator.errors.collect(this.field.name));
                    }
                });
            },
            reportValidity(flag) {
                if (this.transPane) {
                    this.transPane.setValidity(flag);
                }
            }
        },
        watch: {
            errorMessages(errors) {
                this.reportValidity(!!errors);
            }
        }
    };
</script>

<template>
    <div class="form-group" :class="cssClasses" v-show="isInitialized">
        <slot name="label">
            <label :class="labelCssClasses">
                {{ label }}
                <span class="asterisk text-danger" v-if="field && field.required">*</span>
            </label>
        </slot>

        <slot></slot>

        <slot name="errors">
            <div class="text-danger" v-if="errorMessages">
                <span v-for="error in errorMessages" :key="error">{{ error }}</span>
            </div>
        </slot>
        <slot name="help">
            <span class="help-block" v-if="help">{{ help }}</span>
        </slot>
    </div>
</template>
