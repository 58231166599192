import Vue from 'vue';

export class AbstractDataSource {
    constructor(rows = [], pageSize = 50) {
        this.sourceRows = rows;

        this.rows = [];
        this.pageSize = pageSize;
        this.sortField = null;
        this.sortOrder = null;
        this.searchQuery = null;
        this.page = 1;
        this.totalRows = 0;

        this.callback = null;
        this._vm = new Vue();
    }

    subscribe(cb) {
        this.callback = cb;
        this.fetch();
    }

    notify() {
        this.callback(this.rows);
    }

    update() {
        this.fetch();
        this.notify();
    }

    setSort(field, order, sortFn = null) {
        this.sortField = field;
        this.sortOrder = order;
        this.sortFn = sortFn;
    }

    setPageSize(pageSize) {
        this.pageSize = pageSize;
        this.page = 1;
    }

    setPage(page) {
        this.page = page;
    }

    setSorting(field, order) {
        if (field) {
            this.sortField = field;
            this.sortOrder = order;
        }
    }

    setFilter(search) {
        this.searchQuery = search;
    }

    async fetch() {
        try {
            this._vm.$emit('load-start');
            const response = await this.doFetch();
            this._vm.$emit('load-finish', response);
            return response;
        } catch (e) {
            this._vm.$emit('load-error', e);
        }
    }

    on(event, listener) {
        this._vm.$on(event, listener);
    }
}
