import merge from 'lodash/merge';

export class AdminGenerator {
    build(app, context) {
        context.appRegistry.all().forEach(_app => {
            _app.getAdminModifiers().forEach(m => m(context));
        });
        app.getAdminsArray().forEach(admin => {
            admin.setApp(app);
            admin.setStore(context.store);
            admin.prepare();
        });
        const routes = this.buildRoutes(app, context);
        const stores = this.buildStore(app, context);
        return {routes, stores};
    }

    buildRoutes(app, context) {
        let routes = [];
        app.getAdminsArray().forEach((admin, index) => {
            if (index === 0) {
                routes.push({
                    path: '/' +  admin.generateRoutePath(admin.app.name),
                    name: admin.generateRouteName(admin.app.name),
                    redirect: {
                        name: admin.getIndexRouteName()
                    }
                });
            }
            routes = routes.concat(admin.getRoutes(context));
        });
        return routes;
    }

    buildStore(app, context) {
        let store = {};
        app.getAdminsArray().map(admin => {
            store = merge(store, admin.createStoreModule(context));
        });
        return store;
    }
}
